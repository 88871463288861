import { Button, Select } from '@qasa/qds-ui'
import { useState } from 'react'

import type { PlatformEnum } from '../../graphql/__generated__/graphql'
import { MarketNameEnum } from '../../graphql/__generated__/graphql'
import { useSyncUserToBillecta } from '../../queries'
import { Dialog } from '../../components/_core/dialog'

const AVAILABLE_BILLECTA_MARKETS = [MarketNameEnum.Sweden, MarketNameEnum.Finland]

type Props = {
  uid: string
  mainPlatform: PlatformEnum
}
export function UserBillectaSync({ uid, mainPlatform }: Props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [billectaMarketSelection, setBillectaMarketSelection] = useState<MarketNameEnum | null>(null)
  const { mutate: syncUserToBillecta, isLoading: isSyncUserToBillectaLoading } = useSyncUserToBillecta({
    uid,
    ...(billectaMarketSelection && { market: billectaMarketSelection }),
  })

  const shouldShowMarketSelector = mainPlatform === 'dotcom'

  const handleTriggerButtonClick = () => {
    if (shouldShowMarketSelector && !isDialogOpen) {
      setIsDialogOpen(true)
    } else {
      syncUserToBillecta()
    }
  }

  return (
    <>
      <Dialog isOpen={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <Dialog.Content>
          <Dialog.Header>
            <Dialog.Title>Select which Billecta market to sync</Dialog.Title>
          </Dialog.Header>
          <Dialog.Body>
            {shouldShowMarketSelector && (
              <Select
                label="Billecta market"
                value={billectaMarketSelection ?? ''}
                placeholder="-"
                onChange={(e) => setBillectaMarketSelection(e.target.value as MarketNameEnum)}
              >
                {AVAILABLE_BILLECTA_MARKETS.map((value) => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              variant="tertiary"
              isFullWidth
              disabled={!billectaMarketSelection}
              onClick={() => syncUserToBillecta()}
              isLoading={isSyncUserToBillectaLoading}
            >
              Sync user to Billecta
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>

      {/* Triggers the dialog, or syncs the user to Billecta if no market needs to be selected */}
      <Button variant="tertiary" onClick={handleTriggerButtonClick} isLoading={isSyncUserToBillectaLoading}>
        Sync user to Billecta
      </Button>
    </>
  )
}

import type { ChangeEvent } from 'react'
import { useState } from 'react'
import { Button, Stack, TextField } from '@qasa/qds-ui'

import { useSendTerminationPDF } from '../../../queries/contract-queries'

type SendTerminationPDFProps = {
  contractId: string
  possibleTerminationDate: () => string
}

export function SendTerminationPDF({ contractId, possibleTerminationDate }: SendTerminationPDFProps) {
  const [terminationPDFDate, setTerminationPDFDate] = useState('')
  const { mutate: sendTerminationPDF, isLoading: isSendTerminationPDFLoading } = useSendTerminationPDF()

  const handleTerminationPDFDateChange = (e: ChangeEvent<HTMLInputElement>) =>
    setTerminationPDFDate(e.target.value)

  const handleSendTerminationPDFSubmit = () => {
    if (Boolean(terminationPDFDate)) {
      sendTerminationPDF({ id: contractId, endDate: terminationPDFDate })
    }
    setTerminationPDFDate('')
  }
  return (
    <Stack gap="3x">
      <TextField
        helperText={possibleTerminationDate()}
        label="Manual Termination"
        type="date"
        value={terminationPDFDate}
        onChange={handleTerminationPDFDateChange}
      />
      <Button
        variant="tertiary"
        disabled={!terminationPDFDate}
        onClick={handleSendTerminationPDFSubmit}
        isLoading={isSendTerminationPDFLoading}
      >
        Send
      </Button>
    </Stack>
  )
}

import { useMutation } from '@tanstack/react-query'

import {
  userPermanentDelete,
  sendResetLinkToUserEmail,
  resetCreditCheck,
  resetBankIDVerification,
  updateEmail,
  verifyAccount,
  creditCheck,
  disableSafeRentalUser,
  enableSafeRentalUser,
  clearSchibstedAccountId,
  updateUserMainPlatform,
  createSubUser,
  reinviteSubUser,
  removeSubUser,
  syncUserToBillecta,
  addInvoicingForUser,
  removeInvoicingForUser,
} from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'
import type { MarketNameEnum } from '../graphql/__generated__/graphql'

export const useUserPermanentDelete = (uid: string) => {
  return useMutation(() => userPermanentDelete(uid), {
    onSuccess: () => {
      notifySuccess('User deleted successfully')
    },
    onError: displayApiErrors,
  })
}

export const useSendResetLinkToEmail = () => {
  return useMutation(sendResetLinkToUserEmail, {
    onSuccess: () => {
      notifySuccess('Reset password mail sent!')
    },
    onError: displayApiErrors,
  })
}

export const useResetCreditCheck = (uid: string) => {
  return useMutation(() => resetCreditCheck(uid), {
    onSuccess: () => {
      notifySuccess('Reset of users credit check was successful')
    },
    onError: displayApiErrors,
  })
}

export const useResetBankIDVerification = (uid: string) => {
  return useMutation(() => resetBankIDVerification(uid), {
    onSuccess: () => {
      notifySuccess('Identity verification reset successfully')
    },
    onError: displayApiErrors,
  })
}

export const useUpdateEmail = () => {
  return useMutation(updateEmail, {
    onSuccess: () => {
      notifySuccess('Email updated successfully')
    },
    onError: displayApiErrors,
  })
}

export const useVerifyAccount = (isUsersAccountVerified: string) => {
  return useMutation(() => verifyAccount({ isUsersAccountVerified }), {
    onSuccess: () => {
      notifySuccess('Account verified!')
    },
    onError: displayApiErrors,
  })
}

export const useCreditCheck = (uid: string) => {
  return useMutation(() => creditCheck(uid), {
    onSuccess: () => {
      notifySuccess('Credit check was conducted successfully!')
    },
    onError: displayApiErrors,
  })
}
export const useSafeRentalToggleMutation = (isSafeRentalActive: boolean, uid: string) => {
  const performUserUpdateAction = isSafeRentalActive ? disableSafeRentalUser : enableSafeRentalUser
  const successMessage = isSafeRentalActive
    ? 'Removed safe rental successfully!'
    : 'Enabled safe rental successfully!'

  const handleUserUpdate = async () => {
    await performUserUpdateAction({ uid })
  }

  return useMutation(handleUserUpdate, {
    onSuccess: () => {
      notifySuccess(successMessage)
    },
    onError: displayApiErrors,
  })
}

export const useClearSchibstedAccountId = (uid: string) => {
  return useMutation(() => clearSchibstedAccountId({ uid }), {
    onSuccess: () => {
      notifySuccess('Schibsted Account ID cleared successfully!')
    },
    onError: displayApiErrors,
  })
}

export const useUpdateMainPlatform = () => {
  return useMutation(updateUserMainPlatform, {
    onSuccess: () => {
      notifySuccess('Main Platform Updated Successfully!')
    },
    onError: displayApiErrors,
  })
}

export const useCreateSubUser = () => {
  return useMutation(createSubUser, {
    onSuccess: () => {
      notifySuccess('Sub user successfully created')
    },
    onError: displayApiErrors,
  })
}

export const useReinviteSubUser = () => {
  return useMutation(reinviteSubUser, {
    onSuccess: () => {
      notifySuccess('Sub user successfully reinvited')
    },
    onError: displayApiErrors,
  })
}

export const useRemoveSubUser = () => {
  return useMutation(removeSubUser, {
    onSuccess: () => {
      notifySuccess('User successfully removed as sub user')
    },
    onError: displayApiErrors,
  })
}

export const useSyncUserToBillecta = ({ uid, market }: { uid: string; market?: MarketNameEnum }) => {
  return useMutation(() => syncUserToBillecta({ uid, market }), {
    onSuccess: () => {
      notifySuccess('User successfully synced to Billecta')
    },
    onError: displayApiErrors,
  })
}
export const useAddInvoicingForUser = ({ uid }: { uid: string }) => {
  return useMutation(() => addInvoicingForUser({ uid }), {
    onSuccess: () => {
      notifySuccess(`Invoicing added for user with UID: ${uid}`)
    },
    onError: displayApiErrors,
  })
}
export const useRemoveInvoicingForUser = ({ uid }: { uid: string }) => {
  return useMutation(() => removeInvoicingForUser({ uid }), {
    onSuccess: () => {
      notifySuccess(`Invoicing removed for user with UID: ${uid}`)
    },
    onError: displayApiErrors,
  })
}

import { Button, Stack } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'
import { useState } from 'react'

import { notifyFailure, notifySuccess } from '../../../../utils'
import { FranceInsuranceRequestStateEnum } from '../../../../graphql/__generated__/graphql'
import { type KanyeUserContractType } from '../../contracts/contracts.gql'

import { TRANSITION_INSURANCE_REQUEST_MUTATION } from './transition-mila-insurance-request.gql'

const POSSIBLE_TRANSITION_STATES = [FranceInsuranceRequestStateEnum.PendingRejection]

type RentalInsuranceMilaRequest = {
  rentalInsuranceMilaRequest: KanyeUserContractType['rentalInsuranceMilaRequest']
}

export function TransitionMilaInsuranceRequest({ rentalInsuranceMilaRequest }: RentalInsuranceMilaRequest) {
  const [btnPressed, setBtnPressed] = useState<'' | 'refresh' | 'cancel'>('')
  const [transitionInsuranceRequest, { loading: isTransitionInsuranceRequestLoading }] = useMutation(
    TRANSITION_INSURANCE_REQUEST_MUTATION,
    {
      onCompleted: (data) => {
        const newCurrentState = data?.franceTransitionInsuranceRequest?.insuranceRequest?.currentState
        if (newCurrentState) {
          notifySuccess(`Insurance request state transitioned to ${newCurrentState}`)
        }
        setBtnPressed('')
      },
      onError: () => {
        setBtnPressed('')
        notifyFailure(`Failed to transition insurance request`)
      },
      refetchQueries: ['Contracts'],
    },
  )

  const currentMilaRequestState = rentalInsuranceMilaRequest?.currentState

  const isTransitionMilaRequestPossible = currentMilaRequestState
    ? POSSIBLE_TRANSITION_STATES.includes(currentMilaRequestState)
    : false

  // note: after manually altering a mila request the agent can transition the request back to fetching_required_documents. This kick starts the process again
  const refreshMilaRequest = () => {
    if (rentalInsuranceMilaRequest?.id) {
      setBtnPressed('refresh')
      transitionInsuranceRequest({
        variables: {
          franceTransitionInsuranceRequestId: rentalInsuranceMilaRequest.id,
          toState: FranceInsuranceRequestStateEnum.FetchingRequiredDocuments,
        },
      })
    } else {
      notifyFailure('Mila request id not found, cannot fulfill your request')
    }
  }

  const cancelMilaRequest = () => {
    if (rentalInsuranceMilaRequest?.id) {
      setBtnPressed('cancel')
      transitionInsuranceRequest({
        variables: {
          franceTransitionInsuranceRequestId: rentalInsuranceMilaRequest.id,
          toState: FranceInsuranceRequestStateEnum.Rejected,
        },
      })
    } else {
      notifyFailure('Mila request id not found, cannot fulfill your request')
    }
  }

  return (
    <Stack gap="3x">
      <Button
        variant="tertiary"
        onClick={refreshMilaRequest}
        isLoading={btnPressed === 'refresh' && isTransitionInsuranceRequestLoading}
        isDisabled={!isTransitionMilaRequestPossible}
      >
        {'Refresh Mila Request'}
      </Button>
      <Button
        variant="tertiary"
        onClick={cancelMilaRequest}
        isLoading={btnPressed === 'cancel' && isTransitionInsuranceRequestLoading}
        isDisabled={!isTransitionMilaRequestPossible}
      >
        {'Cancel Mila request'}
      </Button>
    </Stack>
  )
}

import { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Label, Paragraph, Stack } from '@qasa/qds-ui'

import { isUserFinnish } from '../../utils'
import type { PlatformEnum } from '../../graphql/__generated__/graphql'

export const hideFinnishIdNumber = ({ idNumber }: { idNumber: string }) => {
  if (!idNumber) {
    return
  }

  return idNumber.substring(0, 7) + '****'
}

const RowTitle = Label
const IdNumberContainer = styled(Paragraph)<{ isIdConfirmed: boolean }>(({ isIdConfirmed, theme }) => ({
  color: isIdConfirmed ? theme.colors.text.positive : theme.colors.text.negative,
}))
const ToggleButton = styled(Button)(({ isDisabled }) => ({
  display: isDisabled ? 'none' : 'block',
  pointerEvents: isDisabled ? 'none' : 'auto',
}))

type ToggleableIdNumberType = {
  mainPlatform: PlatformEnum
  idNumber: string
  isIdConfirmed: boolean
  shouldShowRowTitle?: boolean
}

export function ToggleableIdNumber({
  mainPlatform,
  idNumber,
  isIdConfirmed,
  shouldShowRowTitle,
}: ToggleableIdNumberType) {
  const [shouldCensorIdNumber, setShouldCensorIdNumber] = useState(true)

  const toggleCensorIdNumber = () => setShouldCensorIdNumber(!shouldCensorIdNumber)

  const isFinnish = isUserFinnish({ mainPlatform })

  const userIdNumberToRender =
    shouldCensorIdNumber && isFinnish ? hideFinnishIdNumber({ idNumber }) : idNumber

  return (
    <Stack gap="3x" wrap="wrap">
      {shouldShowRowTitle && <RowTitle>{'ID Number: '}</RowTitle>}
      <Stack direction="row" gap="3x" alignItems="center">
        <IdNumberContainer isIdConfirmed={isIdConfirmed}>{userIdNumberToRender}</IdNumberContainer>
        {isFinnish && (
          <ToggleButton variant="tertiary" size="xs" onClick={toggleCensorIdNumber}>
            {shouldCensorIdNumber ? 'show' : 'hide'}
          </ToggleButton>
        )}
      </Stack>
    </Stack>
  )
}

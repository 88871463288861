import { Button, Stack } from '@qasa/qds-ui'
import { useState } from 'react'
import { format } from 'date-fns'

import { useEditContractEndDate } from '../../../queries'
import { Dialog } from '../../../components/_core/dialog'
import { Calendar } from '../../../components/calendar/calendar'
import type { KanyeUserContractsType } from '../contracts/contracts.gql'

type ChangeContractEndDateProps = {
  contract: KanyeUserContractsType[number]
}

export function EditContractEndDate({ contract }: ChangeContractEndDateProps) {
  const contractId = contract.id
  const [endDate, setEndDate] = useState<Date>()
  const { mutate, isLoading } = useEditContractEndDate()
  const [isOpen, setIsOpen] = useState(false)

  const handleEndDateSubmit = () => {
    if (!endDate) return

    mutate(
      { id: contractId, endDate: format(endDate, 'yyyy-MM-dd') },
      {
        onSuccess: () => {
          setEndDate(undefined)
          setIsOpen(false)
        },
      },
    )
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <Button variant="tertiary">Edit end date</Button>
      </Dialog.Trigger>

      <Dialog.Content size="auto">
        <Dialog.Header>
          <Dialog.Title>Edit end date</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Calendar mode="single" selected={endDate} defaultMonth={endDate} onSelect={setEndDate} />
        </Dialog.Body>
        <Dialog.Footer>
          {/* Row reverse to have better tab order */}
          <Stack direction="row-reverse" justifyContent="space-between">
            <Button variant="primary" onClick={handleEndDateSubmit} isLoading={isLoading} disabled={!endDate}>
              Save changes
            </Button>
            <Dialog.Close asChild>
              <Button variant="tertiary">Cancel</Button>
            </Dialog.Close>
          </Stack>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { notifyFailure, notifySuccess } from '../../../utils'

import { CREATE_REVIEW } from './create-review.gql'

type UseCreateReviewProps = {
  refetchHomes: () => void
  resetSelections: () => void
}
export const useCreateReview = ({ refetchHomes, resetSelections }: UseCreateReviewProps) => {
  const [actionTaken, setActionTaken] = useState('')

  const [createReview, { loading: isLoading }] = useMutation(CREATE_REVIEW, {
    onCompleted: (data) => {
      if (data.createReview.__typename === 'Home') {
        notifySuccess(`Operation successful home id: ${data.createReview.id}`)
      } else {
        notifyFailure(data.createReview.message)
      }
      setActionTaken('')
      resetSelections()
      refetchHomes()
    },
    onError: (error) => notifyFailure(error.message),
  })

  return {
    actionTaken,
    createReview,
    isLoading,
    setActionTaken,
  }
}

import { graphql } from '../../graphql/__generated__'

export const CREATE_BLOB = graphql(`
  mutation CreateBlob($input: CreateBlobInput!) {
    createBlob(input: $input) {
      id
      headers
      signedUrl
    }
  }
`)

export const LIST_PUBLIC_DOCUMENT_UPLOADS = graphql(`
  query PublicDocuments {
    publicDocuments {
      nodes {
        createdAt
        fileUrl
        id
        name
      }
    }
  }
`)

export const CREATE_PUBLIC_DOCUMENT = graphql(`
  mutation CreatePublicDocument($input: KanyeInputObjectsCreatePublicDocumentInput!) {
    createPublicDocument(input: $input) {
      ... on AuthorizationError {
        message
        permissionAction
        permissionObjectType
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
        message
      }
      ... on PublicDocument {
        createdAt
        fileUrl
        id
        name
      }
    }
  }
`)

export const DESTROY_PUBLIC_DOCUMENT = graphql(`
  mutation DestroyPublicDocument($destroyPublicDocumentId: ID!) {
    destroyPublicDocument(id: $destroyPublicDocumentId) {
      ... on AuthorizationError {
        message
        permissionAction
        permissionObjectType
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
        message
      }
    }
  }
`)

import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

import { TabsEnum, useAuthorisationContext } from '../../../context/authorisation-context'
import { Tab } from '../../../components/tabs/tabs'

const TabList = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing['2x'],
}))
const FullWidthTab = styled(Tab)({
  flex: 1,
})

export function Tabs() {
  const { onChangeTab, selectedTab } = useAuthorisationContext()
  return (
    <TabList direction="row" gap="1x">
      <FullWidthTab
        isActive={selectedTab === TabsEnum.Employees}
        onClick={() => onChangeTab({ tab: TabsEnum.Employees })}
      >
        Employees
      </FullWidthTab>
      <FullWidthTab
        isActive={selectedTab === TabsEnum.Groups}
        onClick={() => onChangeTab({ tab: TabsEnum.Groups })}
      >
        Groups
      </FullWidthTab>
    </TabList>
  )
}

import styled from '@emotion/styled'
import { Button, Heading, Spacer, Paragraph } from '@qasa/qds-ui'

import { reconfirmDeniedBooking } from '../../../actions/contracts-actions'
import { ScheduleInvoices, CancelContractAfterSigningButton } from '../user-contract'
import { BookingStatusEnum } from '../../../graphql/__generated__/graphql'
import type { KanyeUser } from '../user-context'
import { type KanyeUserContractsType } from '../contracts/contracts.gql'

import { SendConfirmationEmail } from './booking-actions/send-confirmation-email'

const Wrapper = styled.div(({ theme }) => ({
  padding: theme.spacing['4x'],
  display: 'flex',
  gap: theme.spacing['6x'],
  flexDirection: 'column',
  [theme.mediaQueries.lgUp]: {
    flexDirection: 'row',
  },
}))
const ActionWrapper = styled.div({ maxWidth: '33%', height: '100%' })
const ActionDescription = Paragraph

type BookingActionsSectionProps = {
  contract: KanyeUserContractsType[number]
  user: KanyeUser
}
export function BookingActionsSection({ contract, user }: BookingActionsSectionProps) {
  if (!contract) return null

  const contractId = contract.id
  const userEmail = user.private.email

  const confirmAction = () => window.confirm('Are you sure?')

  const handleReconfirmDeniedBooking = () => {
    if (confirmAction()) {
      reconfirmDeniedBooking({ id: contract.id })
    }
  }

  const getBookingActions = () => {
    switch (contract.booking?.status) {
      case BookingStatusEnum.Confirmed:
        return (
          <>
            <ActionWrapper>
              <Heading size="2xs">Cancel booking</Heading>
              <Spacer size="2x" />
              <ActionDescription>
                Canceling a booking from here will result in a full refund
              </ActionDescription>
              <Spacer size="4x" />
              <CancelContractAfterSigningButton id={contractId}>Cancel</CancelContractAfterSigningButton>
            </ActionWrapper>
            <SendConfirmationEmail userEmail={userEmail} contract={contract} />
          </>
        )
      case BookingStatusEnum.Ongoing:
      case BookingStatusEnum.Completed:
        return <SendConfirmationEmail userEmail={userEmail} contract={contract} />
      case BookingStatusEnum.AwaitingPayment:
      case BookingStatusEnum.DeclinedDueToLatePayment:
        return (
          <ActionWrapper>
            <Heading size="2xs">Reconfirm booking</Heading>
            <Spacer size="2x" />
            <ActionDescription>
              Reconfirming a booking will trigger a confirmation email to the customers. The dates will be set
              to unavailable and other possible booking-request on these dates will be automatically declined.
            </ActionDescription>
            <Spacer size="4x" />
            <Button isFullWidth variant="primary" onClick={handleReconfirmDeniedBooking}>
              Reconfirm
            </Button>
          </ActionWrapper>
        )
      default:
        return null
    }
  }

  return (
    <Wrapper>
      {getBookingActions()}
      <ScheduleInvoices contractId={contractId} />
    </Wrapper>
  )
}

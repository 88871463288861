import styled from '@emotion/styled'
import { TextField } from '@qasa/qds-ui'

import { onEnter } from './search-bar.utils'

const Wrapper = styled.div(({ theme }) => ({
  gap: theme.spacing['2x'],
  background: theme.colors.core.white,
  position: 'relative',
  maxWidth: 350,
  '@media (max-width: 750px)': {
    maxWidth: '100%',
  },
  marginLeft: 1,
}))
const StyledInput = styled(TextField)(({ theme }) => ({
  paddingLeft: theme.spacing['12x'],
}))
const IconWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 18,
  left: theme.sizes['2x'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 30,
  fontSize: 20,
}))

type SearchBarProps = {
  autoFocus?: boolean
  errorMessage?: string
  onChange: (input: string) => void
  placeholder: string
  submit: () => void
  value: string
}
export function SearchBar({
  onChange,
  submit,
  value,
  placeholder,
  autoFocus = false,
  errorMessage,
}: SearchBarProps) {
  return (
    <Wrapper>
      <StyledInput
        label=""
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => onEnter({ key: e.key, submit })}
        tabIndex={0}
        value={value}
        type="text"
        placeholder={placeholder}
        autoFocus={autoFocus}
        autoComplete="on"
        name="email"
        isInvalid={Boolean(errorMessage)}
        errorMessage={errorMessage}
      />
      <IconWrapper>🕵🏻‍♂️</IconWrapper>
    </Wrapper>
  )
}

import { useCallback } from 'react'

import { useRadioGroupContext } from './radio-group'

export function useRadio({ value: valueProp }: { value: string }) {
  const { value: groupValue, onChange, name } = useRadioGroupContext()

  const isChecked = groupValue === valueProp
  const getInputProps = useCallback(() => {
    return {
      type: 'radio',
      onChange,
      checked: isChecked,
      value: valueProp,
      name,
    }
  }, [onChange, valueProp, name, isChecked])

  return { isChecked, getInputProps }
}

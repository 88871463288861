import { graphql } from '../../graphql/__generated__'

export const KANYE_CURRENT_USER = graphql(/* GraphQL */ `
  query KanyeCurrentUser {
    kanyeCurrentUser {
      ... on LoginUrl {
        path
      }
      ... on Employee {
        id
        amplitudeId
        profilePictureUrl
        name
        email
        roles {
          nodes {
            description
            id
            name
            permissions {
              nodes {
                actions
                objectType
              }
            }
          }
        }
        groups {
          nodes {
            id
            name
          }
        }
        compiledPermissions {
          objectType
          actions
        }
      }
      ... on Error {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
      }
    }
  }
`)

import { InputBase } from '@qasa/qds-ui'

const onKeyDown = (key: string, onEnter: () => void) => {
  if (onEnter && key === 'Enter') {
    onEnter()
  }
}

type InputProps = {
  inputRef?: any
  onChange: (input: string) => void
  type?: string
  value?: any
  name?: string
  placeholder?: string
  onEnter: () => void
}

export function Input({ inputRef, onChange, type, value, name, placeholder, onEnter }: InputProps) {
  return (
    <InputBase
      onChange={(e) => onChange(e.target.value)}
      type={type || 'text'}
      value={value}
      name={name}
      onKeyDown={(e) => onKeyDown(e.key, onEnter)}
      placeholder={placeholder}
      ref={inputRef}
    />
  )
}

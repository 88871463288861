import type { ReactNode } from 'react'
import { Link } from '@qasa/qds-ui'

import { config } from '../../config'

type QasaLinkProps = {
  children: ReactNode
  to: string
}

export function QasaLink({ children, to }: QasaLinkProps) {
  // const baseUrl = isUserFinnish({ mainPlatform }) ? config.qasa_finland : config.host
  // employee todo: get the markets an employee has access to from their roles and use that to determine the baseUrl
  const baseUrl = config.host

  return (
    <Link isExternal href={baseUrl + to}>
      {children}
    </Link>
  )
}

import { useCallback } from 'react'

export function useRadioGroup({
  name,
  onChange,
  value,
}: {
  name: string
  onChange: (input: string) => void
  value: string
}) {
  const handleRadioChange = useCallback(
    (event: any) => {
      if (event.target.checked) {
        onChange(event.target.value)
      }
    },
    [onChange],
  )

  return { value, onChange: handleRadioChange, name }
}

import { useState } from 'react'
import { Button, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { Batches } from '../batches'
import { UserContractCTAs } from '../user-contract-ctas'
import { AdminNoteRecordTypeEnum } from '../../../graphql/__generated__/graphql'
import { Table } from '../../../components/table/table'
import { TableHeader } from '../../../components/table/table-header'
import { useUserContext } from '../user-context'
import { Notes } from '../../../components/notes/notes'

import { UserContractMeta } from './user-contract-meta'
import type { KanyeUserContractType } from './contracts.gql'

const Wrapper = Stack
const HeaderContainer = Stack
const LeftColumn = 'div'
const RightColumn = styled(Stack)({ flex: 1 })
const BatchesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})
const ToggleBatchesButtonContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: theme.spacing['4x'],
}))

const headerTitles = ({ externalIdName }: { externalIdName: string }) => [
  'ID',
  'Invoice',
  externalIdName,
  'Type',
  'Amount',
  'Send at',
  'Sent at',
  'Paid at',
  'Due',
  'Credit',
]

type ExpandContractProps = {
  contract: KanyeUserContractType
  showBatchesDefault?: boolean
}

export function ExpandedContract({ contract, showBatchesDefault = false }: ExpandContractProps) {
  const [showBatches, setShowBatches] = useState(showBatchesDefault)
  const { user } = useUserContext()
  const externalIdName = user.mainPlatform === 'finn' ? 'Aprila ID' : 'Billecta ID'
  const contractId = contract.id

  const handleSetShowBatches = () => setShowBatches(!showBatches)

  return (
    <Wrapper gap="8x">
      <HeaderContainer gap="8x" direction="row" wrap="wrap">
        <LeftColumn>
          <UserContractMeta contract={contract} />
        </LeftColumn>
        <RightColumn gap="8x">
          <Notes
            notes={contract.adminNotes}
            type={AdminNoteRecordTypeEnum.Contract}
            contractId={contract.id}
          />
          <UserContractCTAs contract={contract} />
        </RightColumn>
      </HeaderContainer>
      {Boolean(contract.invoices.length) && (
        <ToggleBatchesButtonContainer>
          <Button variant="tertiary" onClick={handleSetShowBatches}>
            {showBatches ? 'Hide batches' : 'Show batches'}
          </Button>
        </ToggleBatchesButtonContainer>
      )}
      {showBatches && (
        <BatchesWrapper>
          <Table>
            <TableHeader headerTitles={headerTitles({ externalIdName })} />
            <Batches contractId={contractId} />
          </Table>
        </BatchesWrapper>
      )}
    </Wrapper>
  )
}

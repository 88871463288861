import styled from '@emotion/styled'
import { Stack, Paragraph, Button, Heading, LoadingDots } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'
import { useState } from 'react'

import { Dialog } from '../../components/_core/dialog'
import { notifyFailure, notifySuccess } from '../../utils'
import { TabsEnum, useAuthorisationContext } from '../../context/authorisation-context'

import { UPDATE_EMPLOYEE, UPSERT_EMPLOYEE_GROUP, GROUPS, EMPLOYEES } from './authorisation.gql'

export const RoleContainer = styled(Stack)<{ hasRole: boolean }>(({ theme, hasRole }) => ({
  backgroundColor: hasRole ? theme.colors.core.black : theme.colors.core.offWhiteDark,
  color: hasRole ? theme.colors.core.white : theme.colors.core.black,
  paddingInline: theme.spacing['6x'],
  paddingBlock: theme.spacing['3x'],
  borderRadius: theme.radii.xl,
  ':hover': {
    cursor: 'pointer',
    backgroundColor: theme.colors.core.yellow60,
  },
}))

export function AssignRolesDialog() {
  const {
    dialogMethods: {
      rolesDialog: { status: isOpen, close: onClose },
    },
    group,
    employee,
    selectedTab,
    isLoadingEmployeeRoles,
    roles,
  } = useAuthorisationContext()

  const isGroupTabSelected = selectedTab === TabsEnum.Groups

  const selectedEntity = isGroupTabSelected ? group : employee

  const currentEmployeeRoles = selectedEntity?.roles?.nodes.map((role) => role.id) || []
  const currentEmployeeGroups = employee?.groups?.edges.map((group) => group.node.id) || []

  const [roleIdArray, setRoleIdArray] = useState<string[]>(currentEmployeeRoles)

  const [updateEmployeeRoles, { loading: isUpdatingEmployeeRoles }] = useMutation(UPDATE_EMPLOYEE, {
    variables: {
      id: employee?.id || '',
      groupIds: currentEmployeeGroups,
      roleIds: roleIdArray,
    },
    onCompleted: () => {
      // TODO : error handling
      notifySuccess(`Roles updated for employee: ${employee?.name}`)
      onClose()
    },
    refetchQueries: [EMPLOYEES],
    onError: () => notifyFailure(`Error updating roles for ${employee?.email}`),
  })

  const [updateGroupRoles, { loading: isUpdatingGroupRoles }] = useMutation(UPSERT_EMPLOYEE_GROUP, {
    variables: {
      employeeIds: group?.id || '',
      name: group?.name || '',
      roleIds: roleIdArray,
    },
    onCompleted: () => {
      // TODO : error handling
      notifySuccess(`Roles updated for group: ${group?.name}`)
      onClose()
    },
    refetchQueries: [{ query: GROUPS }],
    onError: () => notifyFailure(`Error updating roles for ${group?.name}`),
  })

  const handleRoleChange = (roleId: string) => {
    if (roleIdArray.includes(roleId)) {
      setRoleIdArray((prev) => prev.filter((role) => role !== roleId))
    } else {
      setRoleIdArray((prev) => [...prev, roleId])
    }
  }

  const clickHandler = isGroupTabSelected ? updateGroupRoles : updateEmployeeRoles

  const dialogHeading = `Update ${isGroupTabSelected ? 'group' : 'employee'} roles`

  // const employeeRoles =
  //   data?.employeeRoles.__typename === 'EmployeeRoleConnection' ? data?.employeeRoles.nodes : []

  return (
    <Dialog isOpen={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header>
          <Heading size="sm">{dialogHeading}</Heading>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="3x" wrap="wrap" direction="row" alignItems="center">
            {isLoadingEmployeeRoles ? (
              <LoadingDots />
            ) : (
              <>
                {roles?.map((role) => {
                  const hasRole = roleIdArray?.includes(role.id)
                  return (
                    <RoleContainer
                      key={role.id}
                      gap="3x"
                      hasRole={hasRole}
                      onClick={() => handleRoleChange(role.id)}
                    >
                      <Paragraph color={hasRole ? 'onBrandSecondary' : 'onBrandTertiary'}>
                        {role.name}
                      </Paragraph>
                    </RoleContainer>
                  )
                })}
              </>
            )}
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="tertiary"
            isFullWidth
            isLoading={isUpdatingEmployeeRoles || isUpdatingGroupRoles}
            disabled={isUpdatingEmployeeRoles || isUpdatingGroupRoles || roleIdArray === currentEmployeeRoles}
            onClick={() => clickHandler()}
          >
            Save
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

import { useMutation } from '@tanstack/react-query'

import { creditInvoice } from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'

export const useCreditInvoice = () => {
  return {
    ...useMutation(creditInvoice, {
      onSuccess: () => {
        notifySuccess('Invoice credited!')
      },
      onError: displayApiErrors,
    }),
  }
}

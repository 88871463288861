import { Stack } from '@qasa/qds-ui'

import {
  getPricingModelInfo,
  getVacationPricingModelInfo,
  getBaseInfo,
  getOverviewInfo,
} from '../user-contract-meta.utils'
import { ContractRentalTypeEnum } from '../../../graphql/__generated__/graphql'
import { useAuthContext } from '../../../context/auth-context'

import { ContractMetaSection } from './components/contract-meta-section'
import type { KanyeUserContractType } from './contracts.gql'

type UserContractType = {
  contract: KanyeUserContractType
}
export function UserContractMeta({ contract }: UserContractType) {
  const { currentEmployee } = useAuthContext()
  const overviewInfo = getOverviewInfo({ contract })
  const baseInfo = getBaseInfo({ contract, isEmployee: Boolean(currentEmployee) })
  const pricingModelInfoGetter =
    contract.rentalType === ContractRentalTypeEnum.Vacation
      ? getVacationPricingModelInfo
      : getPricingModelInfo
  const pricingModelInfo = pricingModelInfoGetter({ contract })

  return (
    <Stack gap="4x">
      <ContractMetaSection header="Overview" sectionData={overviewInfo} />
      <ContractMetaSection header="Base info" sectionData={baseInfo} />
      <ContractMetaSection header="Pricing model & fees" sectionData={pricingModelInfo} />
    </Stack>
  )
}

import styled from '@emotion/styled'
import { Link, Paragraph } from '@qasa/qds-ui'

import { GenerateSocPdfButton } from '../../../../../components/pdf-generators/generate-Soc-pdf-button'
import { GenerateKfmPdfButton } from '../../../../../components/pdf-generators/generate-KfmPdf-button'

import { STATES } from './eviction-process'

const ParagraphSmall = styled(Paragraph)({})
ParagraphSmall.defaultProps = { size: 'sm' }

type TaskProps = {
  process: any
  evictionProcess: any
}
function Task({ process, evictionProcess }: TaskProps) {
  const currentState = process.currentState
  switch (currentState) {
    case STATES.not_started:
      return <ParagraphSmall>This process has not yet been started.</ParagraphSmall>

    case STATES.report_to_socialtjansten:
      return (
        <>
          <ParagraphSmall>
            Time to contact Socialtjänsten! Fill out the PDF and send the report to them. You can find the
            addresses to the different socialtjänsten offices in{' '}
            <Link
              href="https://docs.google.com/spreadsheets/d/1duA8ZAD_qCQHbV70LgNBn7C1G9TlrCVDLptY3k0HXNs/edit#gid=1860833417"
              target="_blank"
            >
              this google sheet
            </Link>
            .
          </ParagraphSmall>
          <GenerateSocPdfButton evictionProcess={evictionProcess} />
        </>
      )

    case STATES.awaiting_reply_from_socialtjansten:
      return (
        <ParagraphSmall>
          Now we wait for Socialtjänsten to reply by physical mail. They should have put their stamp of
          approval on the report you sent. If they didn't stamp it, or if you think the report has been lost
          somehow, you can try sending it again.
        </ParagraphSmall>
      )

    case STATES.report_to_kronofogden:
      return (
        <>
          <ParagraphSmall>
            Now it's time to forward the stamped Socialtjänsten report to Kronofogden, along with a
            Kronofogden report. You can generate a report with this button, and then print it out.
          </ParagraphSmall>
          <GenerateKfmPdfButton evictionProcess={evictionProcess} />
          <ParagraphSmall>
            When you're done, put both the stamped reply from Socialtjänsten and the Kronofogden report in the
            same envelope, and send it to Kronofogden.
          </ParagraphSmall>
        </>
      )

    case STATES.awaiting_reply_from_kronofogden:
      return (
        <ParagraphSmall>
          Kronofogden will reply by physical mail. Once they've replied, you can select whether or not the
          user was "delgiven".
        </ParagraphSmall>
      )

    case STATES.delgivning_approved_awaiting_eviction_date:
      return (
        <ParagraphSmall>
          We now need to receive an eviction date from Kronofogden. If the tenant has already moved out, you
          can revoke the Kronofogden report and complete the process.
        </ParagraphSmall>
      )

    case STATES.eviction_date_received:
      return (
        <>
          <ParagraphSmall>Last step! The process is completed if either:</ParagraphSmall>
          <ParagraphSmall>The tenant has been successfully evicted,</ParagraphSmall>
          <ParagraphSmall>
            or the tenant has moved out on their own. If they've moved out, you can revoke the Kronofogden
            report and complete the process.
          </ParagraphSmall>
        </>
      )

    case STATES.delgivning_denied:
      return (
        <ParagraphSmall>
          It would be great if the landlord could take over the delgivning. Talk them and offer them to do
          that.
        </ParagraphSmall>
      )

    case STATES.awaiting_reply_from_landlord_for_delgivning:
      return (
        <ParagraphSmall>
          If they accepted to take over the case, officially register the case to the landlord. The process is
          then completed for us.
        </ParagraphSmall>
      )

    case STATES.done:
      return (
        <ParagraphSmall>
          Good job! I understand this process must have taken a long time and involved a lot of emotions, but
          it's done now. Why don't you take a break and do something nice for yourself.
        </ParagraphSmall>
      )

    default:
      return <ParagraphSmall>Unknown state</ParagraphSmall>
  }
}

export { Task }

import styled from '@emotion/styled'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Heading, IconButton, XIcon } from '@qasa/qds-ui'
import type { ReactNode } from 'react'

export function DialogTrigger({ children }: { children?: ReactNode }) {
  return <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>
}

export const DialogOverlay = styled(DialogPrimitive.Overlay)(({ theme }) => ({
  backgroundColor: theme.colors.bg.backdrop,
  position: 'fixed',
  inset: 0,
  zIndex: theme.zIndices.overlay,
}))

export const DialogHeader = styled.header(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  minHeight: 56,
  borderBottom: '1px solid',
  borderColor: theme.colors.border.default,
}))

export function DialogTitle({ children }: { children?: ReactNode }) {
  return (
    <DialogPrimitive.Title asChild>
      <Heading size="2xs">{children}</Heading>
    </DialogPrimitive.Title>
  )
}

export const DialogBody = styled.div(({ theme }) => ({
  flex: 1,
  padding: theme.spacing['6x'],
  overflowY: 'auto',
}))

export const DialogFooter = styled.footer<{ hasBorder?: boolean }>(({ theme, hasBorder = true }) => ({
  minHeight: 72,
  flexShrink: 0,
  padding: '16px 24px',
  ...(hasBorder && {
    borderTop: '1px solid',
    borderColor: theme.colors.border.default,
  }),
}))

export function DialogCloseButton() {
  return (
    <DialogPrimitive.Close asChild style={{ position: 'absolute', right: 12, top: 8 }}>
      <IconButton label="close" icon={XIcon} size="sm" />
    </DialogPrimitive.Close>
  )
}

import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Heading,
  Link,
  LoadingDots,
  Paragraph,
  Stack,
  IconButton,
  TrashIcon,
  TextField,
} from '@qasa/qds-ui'

import { formatDate } from '../../utils/date'
import { notifyFailure } from '../../utils'

import { LIST_PUBLIC_DOCUMENT_UPLOADS, DESTROY_PUBLIC_DOCUMENT } from './public-documents-upload.gql'
import { useDocumentUpload } from './use-upload'

const Column = styled(Stack)(({ theme }) => ({
  borderRadius: theme.radii.lg,
  padding: theme.spacing['6x'],
  backgroundColor: theme.colors.core.gray30,
}))
const PageWrapper = styled(Stack)(({ theme }) => ({
  height: `calc(100% - ${theme.spacing['6x']})`,
}))
const LeftColumn = styled(Column)({
  flex: 1,
})
const RightColumn = styled(Column)({
  flex: 1.5,
  overflowY: 'scroll',
})
const DocumentCard = styled(Stack)(({ theme }) => ({
  borderRadius: theme.radii.lg,
  padding: theme.spacing['4x'],
  backgroundColor: theme.colors.core.white,
}))
const DateWrapper = styled('div')(({ theme }) => ({
  borderRadius: theme.radii.sm,
  paddingBlock: theme.spacing['1x'],
  paddingInline: theme.spacing['2x'],
  backgroundColor: theme.colors.core.gray20,
}))

const LeftColumnContent = Stack
const ButtonContainer = Stack

export function PublicDocumentUpload() {
  const [alteredFileName, setAlterFileName] = useState('')
  const { isCreateBlobLoading, isUploadingPublicDoc, chosenFile, removeChosenFile, uploadFile, browseFiles } =
    useDocumentUpload()

  const [destroyDocument, { loading: isDestroyingDoc }] = useMutation(DESTROY_PUBLIC_DOCUMENT, {
    refetchQueries: ['PublicDocuments'],
    onError: (error) => notifyFailure(error.message),
  })

  const fileInputRef = useRef<HTMLInputElement>(null)

  const { data, loading: isLoadingUploadedPublicDocs } = useQuery(LIST_PUBLIC_DOCUMENT_UPLOADS)

  const uploadedDocs = data?.publicDocuments.nodes

  const sortedArray = uploadedDocs?.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)) ?? []

  const resetFileUpload = () => {
    setAlterFileName('')
    if (fileInputRef.current) {
      // reset the ref value so that the user can upload another after if they wish, cleans the file selection
      fileInputRef.current.value = ''
    }
  }

  const previewUploadDocName = alteredFileName || chosenFile?.name

  return (
    <PageWrapper direction="row" gap="6x">
      <LeftColumn gap="6x">
        <Heading>{'Manage public documents'}</Heading>
        <LeftColumnContent gap="3x">
          <TextField
            label="Choose new file name"
            value={alteredFileName}
            placeholder={chosenFile?.name}
            isDisabled={!chosenFile?.name}
            onChange={(e) => setAlterFileName(e.target.value)}
          />
          <ButtonContainer gap="2x" direction="row" wrap="wrap">
            <Button variant="secondary" onClick={() => fileInputRef?.current?.click()}>
              {'Choose a file'}
            </Button>
            <input
              onChange={(e) => browseFiles(e).then(resetFileUpload)}
              multiple={false}
              ref={fileInputRef}
              type="file"
              hidden
            />
            <Button
              variant="secondary"
              isLoading={isCreateBlobLoading || isUploadingPublicDoc}
              onClick={() => uploadFile({ alteredFileName }).then(resetFileUpload)}
              isDisabled={!chosenFile}
            >
              {'Upload file'}
            </Button>
          </ButtonContainer>
        </LeftColumnContent>
        <div>
          {chosenFile && (
            <DocumentCard gap="2x">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Heading size="2xs">{'Selected file'}</Heading>
                <IconButton
                  as="button"
                  size="xs"
                  icon={TrashIcon}
                  label=""
                  onClick={() => {
                    removeChosenFile()
                    resetFileUpload()
                  }}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Paragraph>{'Name:'}</Paragraph>
                <Heading size="2xs">{previewUploadDocName}</Heading>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Paragraph>{'Last modified:'}</Paragraph>
                <DateWrapper>
                  <Paragraph size="xs">{`${formatDate(new Date(chosenFile.lastModified), 'yyyy-MM-dd p')}`}</Paragraph>
                </DateWrapper>
              </Stack>
            </DocumentCard>
          )}
        </div>
      </LeftColumn>
      <RightColumn gap="4x">
        {isLoadingUploadedPublicDocs ? (
          <Stack justifyContent="center" alignItems="center" style={{ flex: 1 }}>
            <LoadingDots />
          </Stack>
        ) : sortedArray.length === 0 ? (
          <DocumentCard alignItems="center">
            <Heading size="xs">{'Click the "Choose a file" button to begin your upload'}</Heading>
          </DocumentCard>
        ) : (
          <>
            {sortedArray.map(({ name, fileUrl, createdAt, id }, index) => (
              <DocumentCard gap="4x" key={index}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Heading size="sm">{name}</Heading>
                  <DateWrapper>
                    <Paragraph>{`${formatDate(new Date(createdAt), 'yyyy-MM-dd p')}`}</Paragraph>
                  </DateWrapper>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Link isExternal href={fileUrl}>
                    {'Link to document'}
                  </Link>
                  <IconButton
                    as="button"
                    size="sm"
                    icon={TrashIcon}
                    isDisabled={isDestroyingDoc}
                    label=""
                    onClick={() => destroyDocument({ variables: { destroyPublicDocumentId: id } })}
                  />
                </Stack>
              </DocumentCard>
            ))}
          </>
        )}
      </RightColumn>
    </PageWrapper>
  )
}

import styled from '@emotion/styled'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Heading,
  IconButton,
  Label,
  Paragraph,
  Stack,
  Divider,
} from '@qasa/qds-ui'
import { useEffect, useState } from 'react'

import { LoginAsUserButton } from '../user/login-as-user-button'

import { getFormattedHomeAddress, outputDurationStart } from './utils'
import type { KanyeHome } from './homes-in-review'

const HomePreviewListItem = Stack
const CollapseButtonWrapper = styled.div({
  position: 'absolute',
  left: -16,
  top: '50%',
})
const CollapseButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.colors.border.default}`,
}))
const PaneWrapper = styled(Stack)<{ isCollapsed: boolean }>(({ theme, isCollapsed }) => ({
  paddingBlock: theme.spacing['8x'],
  paddingInline: theme.spacing['6x'],
  position: 'fixed',
  backgroundColor: theme.colors.core.white,
  height: '100%',
  zIndex: theme.zIndices.docked,
  boxShadow: theme.shadows.md,
  width: theme.sizes[384],
  minWidth: theme.sizes[384],
  transition: 'right 0.3s',
  top: 0,
  right: isCollapsed ? -369 : 0,
}))
const ContentContainer = styled(Stack)({
  flex: 1,
})
const ListContainer = styled(Stack)({
  flex: 1,
})
const HeadingContainer = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing['8x'],
}))
const IconBtnContainer = Stack

function RiskExplanation({ items }: { items?: string[] }) {
  if (!items || items?.length === 0) {
    return null
  }
  return items.map((item, index) => <li key={index}>{item}</li>)
}

type HomePreviewProps = {
  homes: KanyeHome[]
}

export function HomePreviewPane({ homes }: HomePreviewProps) {
  const [currentHomeIndex, setCurrentHomeIndex] = useState(0)
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true)

  const isHomesListPopulated = homes.length > 0

  useEffect(() => {
    setIsNavbarCollapsed(isHomesListPopulated ? false : true)
  }, [homes])

  const handleToggleNavbarCollapsed = () => setIsNavbarCollapsed((prevCollapsed) => !prevCollapsed)

  const home = homes[currentHomeIndex]

  const mainPlatform = home?.user.mainPlatform
  const uid = home?.user.uid
  const idNumber = home?.user.idNumberPretty
  const riskExplanation = home?.homeReviewData.adReviewExplanation
  const isShortcut = Boolean(home?.shortcut)

  const hasRiskExplanation = Boolean(riskExplanation)

  const explanationArray = riskExplanation ? riskExplanation.split('. ') : []

  const selectedHomeData = home
    ? [
        {
          title: 'ID Number ',
          content: idNumber ?? 'n/a',
        },
        {
          title: 'Address ',
          content: getFormattedHomeAddress(home),
        },
        {
          title: 'Phone number ',
          content: home.user.private.phoneNumber,
        },
        {
          title: 'Home ID ',
          content: home?.id,
        },
        {
          title: 'User UID ',
          content: uid,
        },
        {
          title: 'Published homes ',
          content: home?.homeReviewData.publishedHomesCount,
        },
        {
          title: 'Archived homes ',
          content: home?.homeReviewData.archivedHomesCount,
        },
        ...(isShortcut
          ? [
              {
                title: 'Recommended rent ',
                content: home.recommendedRentNew,
              },
              {
                title: 'Move in ',
                content: outputDurationStart(home),
              },
            ]
          : []),
      ]
    : []

  return (
    <PaneWrapper direction="column" isCollapsed={isNavbarCollapsed}>
      <CollapseButtonWrapper>
        <CollapseButton
          label=""
          icon={isNavbarCollapsed ? ArrowLeftIcon : ArrowRightIcon}
          variant="ghost"
          size="xs"
          onClick={handleToggleNavbarCollapsed}
          isDisabled={homes.length === 0}
        />
      </CollapseButtonWrapper>
      {isHomesListPopulated && (
        <ListContainer>
          <HeadingContainer direction="row" gap="4x" alignItems="center" justifyContent="space-between">
            <Heading>{`${home?.location?.route} ${home?.location?.streetNumber}`}</Heading>
            <IconBtnContainer direction="row" gap="3x">
              <IconButton
                size="xs"
                variant="tertiary"
                label="Previous home"
                icon={ChevronLeftIcon}
                onClick={() => setCurrentHomeIndex((state) => state - 1)}
                disabled={currentHomeIndex === 0}
              />
              <IconButton
                size="xs"
                variant="tertiary"
                label="Next home"
                icon={ChevronRightIcon}
                onClick={() => setCurrentHomeIndex((state) => state + 1)}
                disabled={currentHomeIndex === homes.length - 1}
              />
            </IconBtnContainer>
          </HeadingContainer>
          <ContentContainer direction="column" gap="4x" divider={<Divider />}>
            {selectedHomeData.map(({ title, content }, index) => (
              <HomePreviewListItem
                key={index}
                wrap="wrap"
                direction="row"
                alignItems="center"
                gap="2x"
                justifyContent="space-between"
              >
                {title && <Label size="sm">{title}</Label>}
                {content && (
                  <Paragraph size="sm" as="span">
                    {content}
                  </Paragraph>
                )}
              </HomePreviewListItem>
            ))}
            {hasRiskExplanation && (
              <HomePreviewListItem gap="2x">
                <Label size="sm">{'Risk explanation: '}</Label>
                <RiskExplanation items={explanationArray} />
              </HomePreviewListItem>
            )}
          </ContentContainer>
          <LoginAsUserButton mainPlatform={mainPlatform} uid={uid} />
        </ListContainer>
      )}
    </PaneWrapper>
  )
}

import styled from '@emotion/styled'
import { Label } from '@qasa/qds-ui'

const StyledLabel = styled(Label)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['3x'],
  alignItems: 'center',
  marginTop: theme.spacing['3x'],
  padding: theme.spacing['3x'],
}))

type CheckboxTypes = {
  label: string
  value: string
  currentValues: string[]
  onClick: (value: string) => void
  className?: string
}

export function Checkbox({ label, value, currentValues, onClick, className }: CheckboxTypes) {
  return (
    <StyledLabel className={className}>
      <input
        type="checkbox"
        onChange={() => onClick(value)}
        checked={currentValues.length > 0 && currentValues.includes(value)}
      />
      {label}
    </StyledLabel>
  )
}

import { Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { AdminNoteRecordTypeEnum } from '../../../graphql/__generated__/graphql'
import { getBaseInfo, getOverviewInfo } from '../user-contract-meta.utils'
import { UserContractCTAsFrance } from '../user-contract-CTAs-france'
import { Notes } from '../../../components/notes/notes'
import { useAuthContext } from '../../../context/auth-context'

import { ContractMetaSection } from './components/contract-meta-section'
import type { KanyeUserContractsType } from './contracts.gql'

const LeftColumn = Stack
const RightColumn = styled(Stack)({ flex: 1 })

type ExpandedContractFranceProps = {
  contract: KanyeUserContractsType[number]
}

export function ExpandedContractFrance({ contract }: ExpandedContractFranceProps) {
  const { currentEmployee } = useAuthContext()

  if (!contract.id) {
    return null
  }
  const overviewInfo = getOverviewInfo({ contract })
  const baseInfo = getBaseInfo({ contract, isEmployee: Boolean(currentEmployee) })

  return (
    <Stack gap="8x">
      <Stack gap="8x" direction="row" wrap="wrap">
        <LeftColumn gap="4x">
          <ContractMetaSection header="Overview" sectionData={overviewInfo} />
          <ContractMetaSection header="Base info" sectionData={baseInfo} />
        </LeftColumn>
        <RightColumn gap="8x">
          <Notes
            notes={contract.adminNotes}
            type={AdminNoteRecordTypeEnum.Contract}
            contractId={contract.id}
          />
          <Stack gap="8x" direction="row">
            <UserContractCTAsFrance contract={contract} />
          </Stack>
        </RightColumn>
      </Stack>
    </Stack>
  )
}

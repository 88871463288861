import { Link as RouterLink } from 'react-router-dom'
import { Stack, Button, Paragraph, LoadingDots, Link, Heading, theme } from '@qasa/qds-ui'

import { QasaLink } from '../../../components/qasa-link'
import { SearchBar } from '../../../components/search-bar'
import { confirmAction, getFormattedHomeAddress } from '../utils'
import { useHomePublishedOnBlocket, useArchiveHome } from '../../../queries'
import { EmployeePermissionActionEnum } from '../../../graphql/__generated__/graphql'
import { useAuthContext } from '../../../context/auth-context'
import { VerifiedData, type HomesInReviewTabProps, type KanyeHome } from '../homes-in-review'
import { formatNumber } from '../../../utils/helpers'
import { NewTable } from '../../../components/table/new-table'

export function LatestPublished({
  fetchBtnClicked,
  homesData,
  isLoadingHomes,
  refetchHomes,
  resetSelections,
  searchForUserHomes,
  selectedHomes,
  selectedRows,
  setUserUid,
  userUid,
  toggleRowSelection,
  clearSelection,
}: HomesInReviewTabProps) {
  const { permissions } = useAuthContext()

  const marketAccess = permissions.filter((permission) =>
    permission.actions.includes(EmployeePermissionActionEnum.MarketAccess),
  )

  const hasSwedishMarketAccess = Boolean(marketAccess.find((market) => market.objectType === 'sweden'))

  const { mutate: archiveHome, isLoading: isArchiveHomeLoading } = useArchiveHome()
  const { mutate: publishHomeOnBlocket, isLoading: isPublishHomeOnBlocketLoading } =
    useHomePublishedOnBlocket()

  const onArchiveHome = () => {
    if (selectedHomes.length < 2 || confirmAction({ numberOfHomes: selectedHomes.length })) {
      selectedHomes.forEach((home: KanyeHome) => {
        archiveHome(
          { id: Number(home.id), archiveReason: 'review_denied' },
          {
            onSuccess: () => {
              refetchHomes()
              resetSelections()
            },
          },
        )
      })
    }
  }

  const onBlocketPublish = () => {
    if (selectedHomes.length < 2 || confirmAction({ numberOfHomes: selectedHomes.length })) {
      selectedHomes.forEach((home: KanyeHome) => {
        publishHomeOnBlocket(
          { id: String(home.id) },
          {
            onSuccess: () => {
              refetchHomes()
              resetSelections()
            },
          },
        )
      })
    }
  }

  const actions = [
    {
      label: 'Archive',
      action: onArchiveHome,
      singleItem: false,
      isLoading: isArchiveHomeLoading,
    },
    {
      label: 'Publish on Blocket',
      action: onBlocketPublish,
      singleItem: false,
      isLoading: isPublishHomeOnBlocketLoading,
    },
  ]

  const isNotPaginating = fetchBtnClicked === ''

  const columns = [
    {
      accessorKey: 'fullName',
      render: () => 'Name',
      size: 200,
    },
    {
      accessorKey: 'email',
      render: () => 'Email',
    },
    {
      accessorKey: 'bankId',
      render: () => 'Bank ID',
      size: 130,
    },
    {
      accessorKey: 'address',
      render: () => 'Address',
    },
    {
      accessorKey: 'home',
      render: () => 'rent | m² | rooms',
      size: 160,
    },
    ...(hasSwedishMarketAccess
      ? [
          {
            accessorKey: 'blocketAd',
            render: () => 'Blocket Ad',
            size: 140,
          },
        ]
      : []),
  ]

  const tableData = homesData?.map((home: KanyeHome) => {
    const externalAdId = home.externalAdId
    const user = home.user
    const companyName = user.companyName
    const email = user.private.email

    return {
      id: home.id,
      fullName: (
        <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(email)}`}>
          {user.professional && companyName
            ? '🏢 ' + companyName
            : `${user.firstName} ${user.private.familyName}`}
        </Link>
      ),
      email: (
        <VerifiedData size="sm" isVerified={Boolean(user.emailConfirmed)}>
          {email}
        </VerifiedData>
      ),
      bankId: (
        <VerifiedData size="sm" isVerified={Boolean(user.identityConfirmedAt)}>
          {user.idNumberPretty || 'n/a'}
        </VerifiedData>
      ),
      address: <QasaLink to={`/home/${home.id}`}>{getFormattedHomeAddress(home)}</QasaLink>,
      home: `${formatNumber({ amount: home.rent || 0, currency: home.currency })} | ${home.squareMeters} | ${home.roomCount}`,
      ...(hasSwedishMarketAccess
        ? {
            blocketAd: externalAdId ? (
              <Link href={'http://blocket.se/vi/' + externalAdId}>{externalAdId}</Link>
            ) : (
              <Paragraph size="sm">{'n/a'}</Paragraph>
            ),
          }
        : {}),
    }
  })

  return (
    <Stack gap="4x">
      {isLoadingHomes && isNotPaginating ? (
        <LoadingDots />
      ) : (
        <>
          <Stack direction="column" gap="4x">
            <Stack direction="row" gap="3x" alignItems="center">
              <SearchBar
                submit={searchForUserHomes}
                onChange={(uid) => setUserUid(uid)}
                placeholder="User UID"
                value={userUid}
              />
              <Button
                size="xs"
                onClick={clearSelection}
                variant="tertiary"
                isDisabled={selectedRows.length < 1}
                style={{
                  // note: compensates for the input gap between label and input, when there is no label the gap remains
                  marginBottom: -theme.spacing['2x'],
                }}
              >
                {'Clear selection'}
              </Button>
            </Stack>
            <Stack direction="row" gap="2x">
              {actions.map(({ label, action, singleItem, isLoading }) => (
                <Button
                  style={{ alignSelf: 'center' }}
                  variant="tertiary"
                  key={label}
                  disabled={selectedHomes.length === 0 || (singleItem && selectedHomes.length > 1)}
                  onClick={action}
                  isLoading={isLoading}
                >
                  {label}
                </Button>
              ))}
            </Stack>
          </Stack>
          {homesData.length === 0 ? (
            <Heading size="xs">No latest published homes right now 🤷‍♀️</Heading>
          ) : (
            <NewTable
              rowsData={tableData}
              columns={columns}
              selectedRows={selectedRows}
              toggleRowSelection={toggleRowSelection}
            />
          )}
        </>
      )}
    </Stack>
  )
}

import { useQuery } from '@apollo/client'
import { useEffect, useMemo, useState, type ReactNode } from 'react'
import { createContext, useContext } from 'react'
import debounce from 'lodash/debounce'

import { EMPLOYEE_ROLES, EMPLOYEES, GROUPS } from '../screens/authorisation/authorisation.gql'
import type {
  EmployeeGroupConnection,
  EmployeesQuery,
  EmployeeGroupsQuery,
  EmployeeRolesQuery,
} from '../graphql/__generated__/graphql'

export type Employee = EmployeesQuery['employees']['nodes'][number]
export type Group = Extract<
  EmployeeGroupsQuery['employeeGroups'],
  { __typename: 'EmployeeGroupConnection' }
>['nodes'][number]
export type Role = Extract<
  EmployeeRolesQuery['employeeRoles'],
  { __typename: 'EmployeeRoleConnection' }
>['nodes'][number]

export type Groupies = EmployeeGroupConnection
export type Test = Omit<EmployeeGroupConnection, 'edges' | 'pageInfo'>

export const enum TabsEnum {
  Employees = 'employees',
  Groups = 'groups',
}

export enum SearchOptions {
  EmployeeName = 'employee_name',
  EmployeeEmail = 'employee_email',
  GroupName = 'group_name',
  Role = 'role',
}

type DialogMethods = {
  status: boolean
  open: () => void
  close: () => void
}

type SearchOptionState = {
  type: SearchOptions
  value: string
}

export type AuthorisationContext = {
  debouncedSearch: any
  dialogMethods: Record<string, DialogMethods>
  employee: Employee | undefined
  employees: Employee[]
  group: Group | undefined
  groups: Group[]
  isLoadingEmployeeRoles: boolean
  isLoadingEmployees: boolean
  isLoadingGroups: boolean
  onChangeTab: ({ tab }: { tab: TabsEnum }) => void
  roles: Role[]
  searchOption: SearchOptionState
  selectedTab: TabsEnum
  selectEmployee: (employee: Employee) => void
  selectGroup: (group: Group) => void
  setSearchOption: React.Dispatch<React.SetStateAction<SearchOptionState>>
  selectedGroup: Group | null
}
const AuthorisationContext = createContext<AuthorisationContext>(undefined!)

function AuthorisationProvider({ children }: { children: ReactNode }) {
  const { data: employeesData, loading: isLoadingEmployees } = useQuery(EMPLOYEES)
  const { data: groupsData, loading: isLoadingGroups } = useQuery(GROUPS)
  const { data: rolesData, loading: isLoadingEmployeeRoles } = useQuery(EMPLOYEE_ROLES)
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<AuthorisationContext['selectedGroup']>(null)
  const [selectedTab, setSelectedTab] = useState<TabsEnum>(TabsEnum.Employees)
  const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false)
  const [isCreateGroupDialogOpen, setIsCreateGroupDialogOpen] = useState(false)
  const [assignEmployeeToGroupDialog, setAssignEmployeeToGroupDialog] = useState(false)
  const [assignGroupToEmployeeDialog, setAssignGroupToEmployeeDialog] = useState(false)
  const [searchOption, setSearchOption] = useState<SearchOptionState>({
    type: SearchOptions.EmployeeName,
    value: '',
  })
  const [debouncedSearchString, setDebouncedSearchString] = useState(searchOption.value)

  const dialogMethods = {
    rolesDialog: {
      status: isRolesDialogOpen,
      open: () => setIsRolesDialogOpen(true),
      close: () => setIsRolesDialogOpen(false),
    },
    createGroupDialog: {
      status: isCreateGroupDialogOpen,
      open: () => setIsCreateGroupDialogOpen(true),
      close: () => setIsCreateGroupDialogOpen(false),
    },
    assignEmployeeToGroupDialog: {
      status: assignEmployeeToGroupDialog,
      open: () => setAssignEmployeeToGroupDialog(true),
      close: () => setAssignEmployeeToGroupDialog(false),
    },
    assignGroupToEmployeeDialog: {
      status: assignGroupToEmployeeDialog,
      open: () => setAssignGroupToEmployeeDialog(true),
      close: () => setAssignGroupToEmployeeDialog(false),
    },
  }

  const debouncedSearch = useMemo(
    () => debounce(({ searchString }) => setDebouncedSearchString(searchString), 200),
    [],
  )

  useEffect(() => {
    debouncedSearch({ searchString: searchOption.value })
  }, [searchOption.value])

  const onChangeTab = ({ tab }: { tab: TabsEnum }) => {
    if (selectedTab === TabsEnum.Employees) {
      setSelectedEmployee(null)
    } else {
      setSelectedGroup(null)
    }

    setSelectedTab(tab)
  }

  const employeesList = employeesData?.employees.nodes || []
  const groupsList =
    groupsData?.employeeGroups.__typename === 'EmployeeGroupConnection'
      ? groupsData?.employeeGroups.nodes
      : []
  const rolesList =
    rolesData?.employeeRoles.__typename === 'EmployeeRoleConnection' ? rolesData?.employeeRoles.nodes : []

  const selectEmployee = (employee: Employee) => {
    if (employee.id === selectedEmployee?.id) {
      setSelectedEmployee(null)
      return
    } else {
      setSelectedEmployee(employee)
    }
  }

  const selectGroup = (group: Group) => {
    if (group.id === selectedGroup?.id) {
      setSelectedGroup(null)
      return
    } else {
      setSelectedGroup(group)
    }
  }

  let employees = employeesList
  let groups = groupsList

  const isGroupTabSelected = selectedTab === TabsEnum.Groups

  // note: don't filter anything until a value has been set
  if (searchOption.value) {
    if (searchOption.type === SearchOptions.EmployeeName) {
      if (isGroupTabSelected) {
        groups = groups.filter((group) =>
          group.employees.nodes.some((employee) => employee.name.toLowerCase().includes(searchOption.value)),
        )
      } else {
        employees = employeesList.filter((el) =>
          el.name.toLowerCase().includes(debouncedSearchString.toLowerCase()),
        )
      }
    }

    if (searchOption.type === SearchOptions.GroupName) {
      if (isGroupTabSelected) {
        groups = groups.filter((group: Group) => group.id === searchOption.value)
      } else {
        employees = groups.find((group: Group) => group.id === searchOption.value)?.employees.nodes || []
      }
    }

    if (searchOption.type === SearchOptions.Role) {
      if (isGroupTabSelected) {
        groups = groups.filter((group) => group.roles.nodes.some((role) => role.id === searchOption.value))
      } else {
        employees = employeesList.filter((employee) =>
          employee.roles.nodes.some((role) => role.id === searchOption.value),
        )
      }
    }
  }

  const value = {
    debouncedSearch,
    dialogMethods,
    employee: employees.find((employee) => employee.id === selectedEmployee?.id),
    employees,
    group: groups.find((group) => group.id === selectedGroup?.id),
    groups,
    isLoadingEmployeeRoles,
    isLoadingEmployees,
    isLoadingGroups,
    onChangeTab,
    roles: rolesList,
    searchOption,
    selectedTab,
    selectedGroup,
    selectEmployee,
    selectGroup,
    setSearchOption,
  }

  return <AuthorisationContext.Provider value={value}>{children}</AuthorisationContext.Provider>
}

const useAuthorisationContext = () => {
  const context = useContext<AuthorisationContext>(AuthorisationContext)

  if (context === undefined) {
    throw new Error('useAuthorisationContext must be used within a provider')
  }
  return context
}

export { useAuthorisationContext, AuthorisationProvider }

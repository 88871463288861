import { graphql } from '../../graphql/__generated__'

export const EMPLOYEES = graphql(/* GraphQL */ `
  query Employees($last: Int, $first: Int, $before: String, $after: String) {
    employees(last: $last, first: $first, before: $before, after: $after) {
      nodes {
        id
        email
        name
        profilePictureUrl
        roles {
          nodes {
            description
            id
            name
            permissions {
              nodes {
                actions
                objectType
              }
            }
          }
        }
        compiledPermissions {
          actions
          objectType
        }
        groups {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
`)

export const GROUPS = graphql(/* GraphQL */ `
  query EmployeeGroups {
    employeeGroups {
      ... on EmployeeGroupConnection {
        nodes {
          id
          name
          employees {
            nodes {
              id
              email
              name
              profilePictureUrl
              roles {
                nodes {
                  description
                  id
                  name
                  permissions {
                    nodes {
                      actions
                      objectType
                    }
                  }
                }
              }
              compiledPermissions {
                actions
                objectType
              }
              groups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
          roles {
            nodes {
              name
              id
              description
              permissions {
                nodes {
                  actions
                  objectType
                }
              }
            }
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`)

export const UPDATE_EMPLOYEE = graphql(/* GraphQL */ `
  mutation UpdateEmployee($id: ID!, $groupIds: [ID!]!, $roleIds: [ID!]!) {
    updateEmployee(id: $id, groupIds: $groupIds, roleIds: $roleIds) {
      ... on Employee {
        compiledPermissions {
          actions
          objectType
        }
        email
        name
        profilePictureUrl
      }
      ... on Error {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
        message
      }
    }
  }
`)

export const EMPLOYEE_ROLES = graphql(/* GraphQL */ `
  query EmployeeRoles {
    employeeRoles {
      ... on EmployeeRoleConnection {
        nodes {
          description
          id
          name
          permissions {
            nodes {
              actions
              objectType
            }
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`)

export const UPSERT_EMPLOYEE_GROUP = graphql(/* GraphQL */ `
  mutation UpsertEmployeeGroup(
    $employeeIds: [ID!]!
    $name: String!
    $roleIds: [ID!]!
    $upsertEmployeeGroupId: ID
  ) {
    upsertEmployeeGroup(
      employeeIds: $employeeIds
      name: $name
      roleIds: $roleIds
      id: $upsertEmployeeGroupId
    ) {
      ... on EmployeeGroup {
        id
        name
        roles {
          nodes {
            description
            id
            name
            permissions {
              nodes {
                actions
                objectType
              }
            }
          }
        }
      }
      ... on Error {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
        message
      }
    }
  }
`)

export const DELETE_EMPLOYEE_GROUP = graphql(/* GraphQL */ `
  mutation DestroyEmployeeGroup($destroyEmployeeGroupId: ID!) {
    destroyEmployeeGroup(id: $destroyEmployeeGroupId) {
      ... on Success {
        success
      }
      ... on Error {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
        message
      }
    }
  }
`)

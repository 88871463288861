import { createLucideIcon } from '@qasa/qds-ui'
import {
  File,
  Copy,
  Pin,
  PinOff,
  CircleUserRound,
  Eye,
  MapPinned,
  OctagonAlert,
  Route,
  CalendarClock,
  FileUp,
  UserCheck,
} from 'lucide-react'

export const FileInvoiceIcon = createLucideIcon(File)

export const CopyIcon = createLucideIcon(Copy)

export const PinIcon = createLucideIcon(Pin)

export const UnpinIcon = createLucideIcon(PinOff)

export const navIcons = {
  user: createLucideIcon(CircleUserRound),
  homesInReview: createLucideIcon(Eye),
  searchAreas: createLucideIcon(MapPinned),
  suspiciousUsers: createLucideIcon(OctagonAlert),
  shortcut: createLucideIcon(Route),
  overduePayments: createLucideIcon(CalendarClock),
  publicDocumentUpload: createLucideIcon(FileUp),
  authorisation: createLucideIcon(UserCheck),
}

import styled from '@emotion/styled'
import { pxToRem } from '@qasa/qds-ui'

/**
 * See `react-day-picker/src/style.css` for a good example of how to style the calendar.
 */
export const Wrapper = styled.div(({ theme }) => ({
  '.rdp-root': {
    position: 'relative',
    // Ensure this matches the size of the prev/next month buttons
    '--grid-size': pxToRem(40),
  },
  '.rdp-day': {
    width: 'var(--grid-size)',
    height: 'var(--grid-size)',
    ...theme.typography.label.sm,
    textAlign: 'center',
    padding: 0,
  },
  '.rdp-day_button': {
    background: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    ...theme.typography.label.sm,
    paddingTop: 2,
    fontVariantNumeric: 'tabular-nums',
    width: 'var(--grid-size)',
    height: 'var(--grid-size)',
    borderRadius: theme.radii.full,
    '&:hover': {
      background: theme.colors.core.gray20,
    },
    '&:disabled': {
      cursor: 'revert',
      background: 'none',
    },
  },
  '.rdp-caption_label': {
    zIndex: 1,
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    border: 0,
    gap: theme.spacing['1x'],
  },
  '.rdp-month_caption': {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: 'var(--grid-size)',
    ...theme.typography.title['2xs'],
  },
  '.rdp-months': {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing['8x'],
    maxWidth: 'fit-content',
  },
  '.rdp-nav': {
    position: 'absolute',
    insetBlockStart: 0,
    insetInlineStart: 0,
    insetInlineEnd: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'var(--grid-size)',
  },
  '.rdp-weekday': {
    padding: `${theme.spacing['2x']} 0`,
    ...theme.typography.caption.md,
    color: theme.colors.text.subtle,
    textAlign: 'center',
  },
  '.rdp-selected .rdp-day_button': {
    background: theme.colors.core.brown,
    color: theme.colors.core.white,
  },
  '.rdp-outside': {
    opacity: 0.4,
  },
  '.rdp-hidden': {
    visibility: 'hidden',
  },
  '.rdp-disabled': {
    opacity: 0.4,
  },
}))

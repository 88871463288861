import styled from '@emotion/styled'
import { Label } from '@qasa/qds-ui'

const RadioButton = styled(Label)(({ theme }) => ({
  marginTop: theme.spacing['3x'],
  display: 'flex',
  minHeight: theme.sizes['12x'],
  cursor: 'pointer',
  border: '1px solid',
  borderRadius: theme.radii.md,
  borderColor: theme.colors.border.defaultSelected,
  alignItems: 'center',
  gap: theme.spacing['4x'],
}))
const RadioCircle = styled.div<{ isChecked: boolean }>(({ theme, isChecked }) => ({
  marginLeft: theme.spacing['4x'],
  border: '1px solid',
  borderColor: theme.colors.border.defaultSelected,
  borderRadius: theme.radii.full,
  height: theme.sizes['4x'],
  width: theme.sizes['4x'],
  ...(isChecked && {
    border: '5px solid',
    borderColor: theme.colors.border.default,
  }),
}))
const LabelContent = 'div'

type RadioInputProps = {
  label: string
  value: string
  currentValue: string
  onClick: (input: string) => void
  className?: string
}
export function RadioInput({ label, value, currentValue, onClick }: RadioInputProps) {
  return (
    <RadioButton>
      <input type="radio" hidden onClick={() => onClick(value)} />
      <RadioCircle isChecked={currentValue === value} />
      <LabelContent>{label}</LabelContent>
    </RadioButton>
  )
}

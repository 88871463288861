import styled from '@emotion/styled'
import { Route, Switch } from 'react-router'
import { Stack } from '@qasa/qds-ui'

import {
  HomesInReview,
  LandingPage,
  SearchAreas,
  SearchForUser,
  SuspiciousUsers,
  Shortcut,
  OverduePayments,
  Authorisation,
  PublicDocumentUpload,
} from '../screens'
import { Navbar } from '../components/navbar'
import { ToastContainer } from '../utils/notifications'
import { QasaPatches } from '../pages/qasa-patches'
import { useAuthContext } from '../context/auth-context'
import { EmployeePermissionActionEnum } from '../graphql/__generated__/graphql'

import { NoMatch } from './no-match'

const Wrapper = styled(Stack)<{ isJackGillberg: boolean }>(({ isJackGillberg }) => ({
  height: '100vh',
  overflow: 'hidden',
  ...(isJackGillberg && {
    cursor: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='60' viewport='0 0 100 100' style='fill:black;font-size:30px;'><text y='50%'>🌝</text></svg>")
      16 0,
    auto !important;`,
  }),
}))

const PageContent = styled.div(({ theme }) => ({
  flexGrow: 1,
  padding: `${theme.spacing['3x']} ${theme.spacing['6x']}`,
  overflowX: 'scroll',
  [theme.mediaQueries.mdUp]: {
    padding: theme.spacing['6x'],
    paddingBottom: 0,
  },
}))

const userPaths = [
  '/user',
  '/user/contracts',
  '/user/bookings',
  '/user/risk_classification',
  '/user/sub_users',
  '/user/import_homes',
]

const homesInReviewPaths = [
  '/homes-in-review',
  '/homes-in-review/denied-homes',
  '/homes-in-review/needs-verification',
  '/homes-in-review/latest-published',
]

export function Routes() {
  const { authBody, permissions } = useAuthContext()
  const isJackGillberg = authBody && 'uid' in authBody ? authBody?.uid === '0af20c58' : false

  const hasAuthorisationAccess = permissions?.find(
    (el) => el.objectType === 'Employee' && el.actions.includes(EmployeePermissionActionEnum.Update),
  )

  return (
    <Wrapper direction="row" isJackGillberg={isJackGillberg}>
      <ToastContainer />
      <Navbar hasAuthorisationAccess={Boolean(hasAuthorisationAccess)} />
      <PageContent>
        <Switch>
          <Route exact={true} path="/" component={LandingPage} />
          <Route exact={true} path={userPaths} component={SearchForUser} />
          <Route exact={true} path={homesInReviewPaths} component={HomesInReview} />
          <Route exact={true} path="/search-areas" component={SearchAreas} />
          <Route exact={true} path="/suspicious-users" component={SuspiciousUsers} />
          <Route exact={true} path="/shortcut" component={Shortcut} />
          <Route exact={true} path="/overdue-payments" component={OverduePayments} />
          <Route exact={true} path="/document-upload" component={PublicDocumentUpload} />
          <Route exact={true} path="/qasa-patches" component={QasaPatches} />
          <Route exact={true} path="/authorisation" component={Authorisation} />
          <NoMatch />
        </Switch>
      </PageContent>
    </Wrapper>
  )
}

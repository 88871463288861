import { Heading, Paragraph, Avatar, Stack } from '@qasa/qds-ui'

import { ToggleableIdNumber } from '../../components/toggled-numbers'

import type { UserContextValue } from './user-context'

const Content = Stack
const FullName = Heading
const UserInfo = Paragraph

export function UserHeader({ user }: UserContextValue) {
  const pictureUrl = user.profilePicture?.url ?? ''
  let fullname = user.firstName
  const surname = user.private.familyName

  if (surname) {
    fullname += ` ${surname}`
  }

  return (
    <Stack gap="4x" direction="row" alignItems="center">
      <Avatar size="xl" src={pictureUrl} />
      <Content gap="2x" justifyContent="center">
        <FullName size="sm">{fullname}</FullName>
        <UserInfo>{user.private.email}</UserInfo>
        <UserInfo>{user.private.phoneNumber}</UserInfo>
        {user.idNumberPretty && (
          <ToggleableIdNumber
            isIdConfirmed={Boolean(user.currentIdentification?.identityConfirmed)}
            idNumber={user.idNumberPretty}
            mainPlatform={user.mainPlatform}
          />
        )}
      </Content>
    </Stack>
  )
}

import { useState } from 'react'
import { type LazyQueryExecFunction, useLazyQuery, useQuery } from '@apollo/client'
import uniq from 'lodash/uniq'

import {
  type Exact,
  type InputMaybe,
  type Scalars,
  type SearchKanyeUsersHomesQuery,
  HomeArchiveReasonEnum,
  HomeSortingEnum,
  HomeStatusEnum,
  type MarketNameTypeEnum,
  type PageInfo,
} from '../../graphql/__generated__/graphql'
import { EmployeePermissionActionEnum } from '../../graphql/__generated__/graphql'
import { useAuthContext } from '../../context/auth-context'

import { type HomesInReviewTabs, platformEnumToMarketNameTypeEnumMap, POSTS_PER_PAGE } from './tabs/constants'
import { KANYE_HOMES } from './kanye-homes.gql'
import { SEARCH_KANYE_USERS_HOMES } from './search-kanye-users-homes.gql'

export type SearchForUserHomesLazyQuery = LazyQueryExecFunction<
  SearchKanyeUsersHomesQuery,
  Exact<{
    uid: Scalars['ID']['input']
    archiveReason?: InputMaybe<HomeArchiveReasonEnum[] | HomeArchiveReasonEnum>
    market?: InputMaybe<MarketNameTypeEnum[] | MarketNameTypeEnum>
    sorting?: InputMaybe<HomeSortingEnum>
    status?: InputMaybe<HomeStatusEnum[] | HomeStatusEnum>
  }>
>

const tabSpecificVariables = {
  inReview: {
    sorting: HomeSortingEnum.UpdatedDesc,
    status: [HomeStatusEnum.InReview],
  },
  deniedHomes: {
    sorting: HomeSortingEnum.ArchivedDesc,
    status: [HomeStatusEnum.Archived],
    archiveReason: HomeArchiveReasonEnum.ReviewDenied,
  },
  needsVerificaiton: {
    sorting: HomeSortingEnum.UpdatedDesc,
    status: [HomeStatusEnum.Archived],
    archiveReason: HomeArchiveReasonEnum.NeedsVerification,
  },
  latestPublished: {
    sorting: HomeSortingEnum.PublishedDesc,
    status: [HomeStatusEnum.Normal],
  },
  shortcut: {
    sorting: HomeSortingEnum.UpdatedAsc,
    status: [HomeStatusEnum.PendingShortcut],
  },
}

type UseHomesInReviewProps = {
  tab: `${HomesInReviewTabs}`
  userUid: string
}
export const useHomesInReview = ({ tab, userUid }: UseHomesInReviewProps) => {
  const { authBody, permissions } = useAuthContext()
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [fetchBtnClicked, setFetchBtnClicked] = useState('')
  const [pageInfo, setPageInfo] = useState<PageInfo | null>(null)

  const adminPlatformsArray = authBody?.private?.adminPlatforms || []

  const adminPlatformsToMarketNamesArray = uniq(
    adminPlatformsArray.flatMap((platform) => platformEnumToMarketNameTypeEnumMap[platform]),
  ) as MarketNameTypeEnum[]

  const marketAccessArray = permissions
    .map((permission) => {
      if (permission.actions.includes(EmployeePermissionActionEnum.MarketAccess)) {
        return permission.objectType
      }
    })
    .filter(Boolean) as MarketNameTypeEnum[]

  const marketsArray = authBody ? adminPlatformsToMarketNamesArray : marketAccessArray

  const clearPageInfo = () => setPageInfo(null)

  const {
    data: homesData,
    refetch: refetchHomes,
    fetchMore: fetchMoreHomes,
    loading: isLoadingHomes,
  } = useQuery(KANYE_HOMES, {
    variables: {
      first: POSTS_PER_PAGE,
      market: marketsArray,
      ...tabSpecificVariables[tab],
    },
    onCompleted: (data) => {
      const inReviewData = data?.kanyeHomes
      if (inReviewData?.__typename === 'KanyeHomeConnection') {
        const { totalCount, pageInfo } = inReviewData
        setCount(totalCount)
        setPageInfo(pageInfo)
      }
      // note: setting an empty string gets rids any current loading animation on the fetch more/less buttons
      setFetchBtnClicked('')
    },
    onError: () => setFetchBtnClicked(''),

    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const canFetchMore = pageInfo?.endCursor && pageInfo?.hasNextPage && !isLoadingHomes

  const fetchMore = () => {
    setFetchBtnClicked('more')
    setCurrentPage((page) => page + 1)
    const endCursor = pageInfo?.endCursor
    if (canFetchMore && endCursor) {
      fetchMoreHomes({
        variables: {
          after: endCursor,
        },
        updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
      })
    }
  }

  const canFetchLess = pageInfo?.startCursor && pageInfo?.hasPreviousPage && !isLoadingHomes

  const fetchLess = () => {
    setFetchBtnClicked('less')
    setCurrentPage((page) => page - 1)
    const startCursor = pageInfo?.startCursor
    if (canFetchLess && startCursor) {
      fetchMoreHomes({
        variables: {
          before: startCursor,
          first: undefined,
          last: POSTS_PER_PAGE,
        },
        updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
      })
    }
  }

  const [
    searchForUserHomes,
    { data: searchedUserHomes, loading: isSearchingForUserHomes, error: searchForUserHomesError },
  ] = useLazyQuery(SEARCH_KANYE_USERS_HOMES, {
    variables: {
      uid: userUid,
      market: marketsArray,
      ...tabSpecificVariables[tab],
    },
  })

  return {
    canFetchLess: Boolean(canFetchLess),
    canFetchMore: Boolean(canFetchMore),
    clearPageInfo,
    count,
    currentPage,
    fetchBtnClicked,
    fetchLess,
    fetchMore,
    homesData: homesData?.kanyeHomes?.__typename === 'KanyeHomeConnection' ? homesData?.kanyeHomes.nodes : [],
    isLoadingHomes,
    pageInfo,
    refetchHomes,
    searchForUserHomes,
    searchedUserHomes: searchedUserHomes?.kanyeUser?.kanyeHomes?.nodes,
    isSearchingForUserHomes,
    searchForUserHomesError,
  }
}

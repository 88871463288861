import styled from '@emotion/styled'
import { Divider, Stack, Button, Label } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'

import { date, isUserFinnish, notifyDefault, notifyFailure, notifySuccess } from '../../../utils'
import { ContractStatusEnum } from '../../../graphql/__generated__/graphql'
import { ContractsApi } from '../../../api/contracts-api'
import { CompensationClaim } from '../user-contract/compensation-claim/compensation-claim'
import {
  CancelContractAfterSigningButton,
  EditContractEndDate,
  ChangeSignedPdf,
  CreateInvoice,
  ScheduleInvoices,
  SendTerminationPDF,
  TerminationOfRentalAgreement,
  UpdateContractWaterFeeSection,
  UpdateNumberOfTenants,
  UpdateRent,
} from '../user-contract'
import type { KanyeUserContractsType } from '../contracts/contracts.gql'

import { DENY_DEPOSIT_PAYOUT, APPROVE_DEPOSIT_PAYOUT, SET_DEPOSIT_FREE } from './deposit-payout.gql'

const Column = styled(Stack)({ flex: 1 })

type UserContractCTAsComponentProps = {
  contract: KanyeUserContractsType[number]
}
export function UserContractCTAs({ contract }: UserContractCTAsComponentProps) {
  const contractId = contract.id

  const [approveDepositPayout, { loading: isApprovePayoutLoading }] = useMutation(APPROVE_DEPOSIT_PAYOUT, {
    variables: { id: contractId },
    onError: () => notifyFailure('An error occurred while approving the deposit payout'),
    onCompleted: (data) => {
      if (data.approveDepositPayout?.contract?.depositPayoutApprovedAt) {
        notifySuccess('Deposit payout approved')
      }
    },
  })
  const [denyDepositPayout, { loading: isDenyPayoutLoading }] = useMutation(DENY_DEPOSIT_PAYOUT, {
    variables: { id: contractId },
    onError: () => notifyFailure('An error occurred while denying the deposit payout'),
    onCompleted: (data) => {
      if (data.denyDepositPayout?.contract?.depositPayoutDeniedAt) {
        notifySuccess('Deposity payout denied')
      }
    },
  })
  const [setDepositFree, { loading: isSettingDepositFree }] = useMutation(SET_DEPOSIT_FREE, {
    variables: { id: contractId },
    onError: () => notifyFailure('An error occurred while setting deposit free'),
    onCompleted: (data) => {
      if (data.setDepositFree?.contract?.depositFree) {
        notifySuccess('Contract set as deposit free')
      }
    },
  })

  if (!contract) return null

  const handleApproveDepositPayout = () => approveDepositPayout()

  const handleDenyDepositPayout = () => denyDepositPayout()

  const possibleTerminationDate = () => {
    const invoicedThrough = contract.invoicedThrough
    const endUfn = contract.duration?.endUfn
    const endOptimal = contract.duration?.endOptimal

    const endDateString = endOptimal && endUfn ? `until ${date(endOptimal)}` : 'and later'
    const invoicedThroughDate = invoicedThrough ? date(invoicedThrough) : 'today'

    return `Possible to terminate between ${invoicedThroughDate} ${endDateString}`
  }

  const depositStatus = () => {
    const depositPayoutApprovedAt = contract.depositPayoutApprovedAt
    const depositPayoutDeniedAt = contract.depositPayoutDeniedAt

    if (depositPayoutApprovedAt) {
      return `Deposit approved at ${date(depositPayoutApprovedAt)}`
    }

    if (depositPayoutDeniedAt) {
      return `Deposit denied at ${date(depositPayoutDeniedAt)}`
    }

    return 'Deposit status: unhandled'
  }

  const { mainPlatform } = contract.tenant || {}

  const handleRestartSigning = () => {
    ContractsApi.contractsRestartSigning(contract.id)
      .then((res: any) => {
        const scriveId = res.data?.scriveId
        window.open(`${process.env.REACT_APP_SCRIVE_PARTIES_LINK}${scriveId}`, '_blank')
      })
      .catch(() => notifyDefault('An error occurred while restarting the signing process.'))
  }

  return (
    <Stack gap="8x" direction="row">
      <Column gap="4x" divider={<Divider />}>
        <TerminationOfRentalAgreement contractId={contractId} />
        <SendTerminationPDF possibleTerminationDate={possibleTerminationDate} contractId={contractId} />
        {/* // TODO: disable this section if contract is ongoing */}
        <Stack gap="2x">
          <Label>Deposit payout</Label>
          <Button variant="tertiary" onClick={handleApproveDepositPayout} isLoading={isApprovePayoutLoading}>
            Approve
          </Button>
          <Button variant="tertiary" onClick={handleDenyDepositPayout} isLoading={isDenyPayoutLoading}>
            Deny
          </Button>
          <span> {depositStatus()}</span>
        </Stack>
        {Boolean(contract.invoices.length) && <CompensationClaim contract={contract} />}
        <ChangeSignedPdf contractId={contractId} />
      </Column>
      <Column gap="4x" divider={<Divider />}>
        <UpdateRent contract={contract} />
        <EditContractEndDate contract={contract} />
        {isUserFinnish({ mainPlatform }) && (
          <Stack gap="4x">
            <UpdateContractWaterFeeSection contract={contract} />
            <UpdateNumberOfTenants contract={contract} />
          </Stack>
        )}
        <Stack gap="3x">
          <CreateInvoice contractId={contractId} />
        </Stack>
        <Stack gap="2x">
          <ScheduleInvoices contractId={contractId} />
          <CancelContractAfterSigningButton id={contractId}>
            Cancel after signing
          </CancelContractAfterSigningButton>
          {contract.status === ContractStatusEnum.Pending && (
            <Button variant="tertiary" onClick={handleRestartSigning}>
              {'Reset signing process'}
            </Button>
          )}
          <Button
            variant="tertiary"
            onClick={() => setDepositFree()}
            isDisabled={Boolean(contract.depositFree)}
            isLoading={isSettingDepositFree}
          >
            {'Set as deposit free'}
          </Button>
        </Stack>
      </Column>
    </Stack>
  )
}

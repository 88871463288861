import { useMutation } from '@tanstack/react-query'

import { adReviewDecision, publishHomeOnBlocket, archiveHome } from '../api/api-service'
import { notifySuccess } from '../utils/notifications'
import { displayApiErrors } from '../utils/error'

export const useAdReviewDecision = () => {
  return useMutation(adReviewDecision, {
    onError: displayApiErrors,
  })
}

export const useHomePublishedOnBlocket = () => {
  return useMutation(publishHomeOnBlocket, {
    onSuccess: () => notifySuccess('Home published on blocket!'),
    onError: displayApiErrors,
  })
}

export const useArchiveHome = () => {
  return useMutation(archiveHome, {
    onSuccess: (response) => {
      if (response.data.success) {
        notifySuccess(response.data.messages.join(' | '))
      }
    },
    onError: displayApiErrors,
  })
}

import { Button } from '@qasa/qds-ui'
import type { ReactNode } from 'react'
import React from 'react'

import { useCancelContractAfterSigning } from '../../../queries'

type CancelContractAfterSigningButtonProps = {
  id: string
  children?: ReactNode
}

export function CancelContractAfterSigningButton({ id, children }: CancelContractAfterSigningButtonProps) {
  const { mutate: cancelContractAfterSigning, isLoading, isSuccess } = useCancelContractAfterSigning({ id })

  return (
    <Button
      variant="tertiary"
      onClick={() => cancelContractAfterSigning()}
      isDisabled={isSuccess}
      isLoading={isLoading}
    >
      {children || 'Cancel contract'}
    </Button>
  )
}

import { graphql } from '../../../../graphql/__generated__'

export const TRANSITION_INSURANCE_REQUEST_MUTATION = graphql(/* GraphQL */ `
  mutation FranceTransitionInsuranceRequest(
    $franceTransitionInsuranceRequestId: ID!
    $toState: FranceInsuranceRequestStateEnum!
  ) {
    franceTransitionInsuranceRequest(id: $franceTransitionInsuranceRequestId, toState: $toState) {
      insuranceRequest {
        id
        currentState
      }
      errors {
        ...BaseError
      }
    }
  }
`)

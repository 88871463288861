import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

import { Suspicion } from './suspicion'

const SuspicionsWrapper = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing['4x'],
}))

type SuspiciousUserPreviewProps = {
  activeSuspicions: any[]
  refetch: () => void
}
export function SuspiciousUserPreview({ activeSuspicions, refetch }: SuspiciousUserPreviewProps) {
  return (
    <SuspicionsWrapper gap="4x">
      {activeSuspicions.map((suspicion: any, index) => (
        <Suspicion
          key={index}
          suspicion={suspicion}
          // activeSuspicionsCount={activeSuspicions.length}
          refetch={refetch}
        />
      ))}
    </SuspicionsWrapper>
  )
}

import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Stack, Button, LoadingDots, Link, Heading, theme } from '@qasa/qds-ui'

import { config } from '../../../../config'
import { confirmAction, getFormattedHomeAddress } from '../../utils'
import { QasaLink } from '../../../../components/qasa-link'
import { NewTable } from '../../../../components/table/new-table'
import { SearchBar } from '../../../../components/search-bar'
import { type KanyeHome, type HomesInReviewTabProps, VerifiedData } from '../../homes-in-review'
import { formatNumber } from '../../../../utils/helpers'
import { ReviewDecisionEnum } from '../../../../graphql/__generated__/graphql'
import { useAuthContext } from '../../../../context/auth-context'
import { useCreateReview } from '../use-create-review'

import { AD_REVIEW_DECISION_OPTIONS } from './in-review.data'
import { AdReviewDecisionDialog } from './ad-review-decision-dialog'

type SelectedOptions = { selectedOptions: string[] | undefined }

export function InReview({
  fetchBtnClicked,
  homesData,
  isLoadingHomes,
  refetchHomes,
  resetSelections,
  searchForUserHomes,
  selectedHomes,
  selectedRows,
  setUserUid,
  userUid,
  clearSelection,
  toggleRowSelection,
}: HomesInReviewTabProps) {
  const { currentEmployee } = useAuthContext()
  const [adReviewDecisionState, setAdReviewDecisionState] = useState<any>({})
  const [adReviewDecisionSelectedOptions, setAdReviewDecisionSelectedOptions] = useState([])
  const [isReviewDecisionDialogOpen, setIsReviewDecisionDialogOpen] = useState(false)

  const { actionTaken, createReview, isLoading, setActionTaken } = useCreateReview({
    refetchHomes,
    resetSelections,
  })

  const onOpenDialog = (state: any) => {
    setAdReviewDecisionState(state)
    setIsReviewDecisionDialogOpen(true)
  }

  const onEditHome = ({ selectedHome }: { selectedHome: KanyeHome }) => {
    const homeId = selectedHome.id
    const hostUrl = config[selectedHome.user.mainPlatform]

    const params = `?uid=${encodeURIComponent(selectedHome.user.uid)}${currentEmployee ? `&isEmployee=${encodeURIComponent(true)}` : ''}&pathname=${encodeURIComponent(`/edit-listing/${homeId}/`)}`

    const url = `${hostUrl}/admin-login-as-user${params}`
    const win = window.open(url, '_blank')
    win?.focus()
  }

  const actionOptions = [
    {
      label: 'Edit home',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.editHome,
          nextAction: ({ selectedOptions }: SelectedOptions) => {
            setActionTaken('Edit')

            // can only edit when 1 home is selected
            const [selectedHome] = selectedHomes

            createReview({
              variables: {
                createReviewId: selectedHome.id,
                action: ReviewDecisionEnum.Edit,
                reasons: selectedOptions,
              },
            })

            if (selectedHomes.length < 2 || confirmAction({ numberOfHomes: selectedHomes.length })) {
              onEditHome({ selectedHome })
            }
          },
        }),
      singleItem: true,
    },
    {
      label: 'Publish',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.publish,
          nextAction: ({ selectedOptions }: SelectedOptions) => {
            if (selectedHomes.length < 2 || confirmAction({ numberOfHomes: selectedHomes.length })) {
              setActionTaken('Publish')
              selectedHomes.forEach(({ id }) =>
                createReview({
                  variables: {
                    createReviewId: id,
                    action: ReviewDecisionEnum.Approved,
                    reasons: selectedOptions,
                  },
                }),
              )
            }
          },
          isSingleChoice: true,
        }),
    },
    {
      label: 'Deny',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.deny,
          nextAction: ({ selectedOptions }: SelectedOptions) => {
            if (confirmAction({ numberOfHomes: selectedHomes.length })) {
              setActionTaken('Deny')
              selectedHomes.forEach(({ id }) =>
                createReview({
                  variables: {
                    createReviewId: id,
                    action: ReviewDecisionEnum.Rejected,
                    reasons: selectedOptions,
                  },
                }),
              )
            }
          },
        }),
    },
    {
      label: 'Deny & Verify',
      action: () => {
        setActionTaken('Deny & Verify')
        selectedHomes.forEach(({ id }) =>
          createReview({
            variables: {
              createReviewId: id,
              action: ReviewDecisionEnum.RejectedNeedsVerification,
              reasons: ['needs verification'],
            },
          }),
        )
      },
    },
    {
      label: 'Deny & Remove user',
      action: () =>
        onOpenDialog({
          ...AD_REVIEW_DECISION_OPTIONS.denyAndRemoveUser,
          nextAction: ({ selectedOptions }: SelectedOptions) => {
            setActionTaken('Deny & Remove user')
            selectedHomes.forEach(({ id }) =>
              createReview({
                variables: {
                  createReviewId: id,
                  action: ReviewDecisionEnum.RejectedSuspectedScam,
                  reasons: selectedOptions,
                },
              }),
            )
          },
        }),
      singleItem: true,
    },
  ]

  const columns = [
    {
      accessorKey: 'rentalType',
      render: () => 'Type',
      size: 160,
    },
    {
      accessorKey: 'mainPlatform',
      render: () => 'Platform',
      size: 100,
    },
    {
      accessorKey: 'fullName',
      render: () => 'Name',
      size: 200,
    },
    {
      accessorKey: 'email',
      render: () => 'Email',
    },
    {
      accessorKey: 'bankId',
      render: () => 'Bank ID',
      size: 130,
    },
    {
      accessorKey: 'address',
      render: () => 'Address',
    },
    {
      accessorKey: 'home',
      render: () => 'rent | m² | rooms',
      size: 160,
    },
  ]

  const tableData = homesData?.map((home: KanyeHome) => {
    const user = home.user
    const companyName = user.companyName
    const email = user.private.email

    return {
      id: home.id,
      rentalType: home.rentalType,
      mainPlatform: home.user.mainPlatform,
      fullName: (
        <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(email)}`}>
          {user.professional && companyName
            ? '🏢 ' + companyName
            : `${user.firstName} ${user.private.familyName}`}
        </Link>
      ),
      email: (
        <VerifiedData size="sm" isVerified={Boolean(user.emailConfirmed)}>
          {email}
        </VerifiedData>
      ),
      bankId: (
        <VerifiedData size="sm" isVerified={Boolean(user.identityConfirmedAt)}>
          {user.idNumberPretty || 'n/a'}
        </VerifiedData>
      ),
      address: <QasaLink to={`/home/${home.id}`}>{getFormattedHomeAddress(home)}</QasaLink>,
      home: `${formatNumber({ amount: home.rent || 0, currency: home.currency })} | ${home.squareMeters} | ${home.roomCount}`,
    }
  })

  const isNotPaginating = fetchBtnClicked === ''

  const onCloseDialog = () => {
    setAdReviewDecisionState(null)
    setAdReviewDecisionSelectedOptions([])
    resetSelections()
    setIsReviewDecisionDialogOpen(false)
  }

  return (
    <Stack gap="4x">
      {isLoadingHomes && isNotPaginating ? (
        <LoadingDots />
      ) : (
        <>
          <Stack direction="row" gap="3x" alignItems="center">
            <SearchBar
              submit={searchForUserHomes}
              onChange={(uid) => setUserUid(uid)}
              placeholder="User UID"
              value={userUid}
            />
            <Button
              size="xs"
              onClick={clearSelection}
              variant="tertiary"
              isDisabled={selectedRows.length < 1}
              style={{
                // note: compensates for the input gap between label and input, when there is no label the gap remains
                marginBottom: -theme.spacing['2x'],
              }}
            >
              {'Clear selection'}
            </Button>
          </Stack>
          <Stack gap="2x" direction="row" alignItems="center">
            {actionOptions.map(({ action, label, singleItem }) => (
              <Button
                style={{ alignSelf: 'center' }}
                size="sm"
                variant="tertiary"
                isLoading={actionTaken === label}
                key={label}
                disabled={selectedHomes.length === 0 || (singleItem && selectedHomes.length > 1) || isLoading}
                onClick={action}
              >
                {label}
              </Button>
            ))}
          </Stack>
          <>
            {homesData.length === 0 ? (
              <Heading size="xs">No homes in review right now 🤷‍♀️</Heading>
            ) : (
              <NewTable
                rowsData={tableData}
                columns={columns}
                selectedRows={selectedRows}
                toggleRowSelection={toggleRowSelection}
              />
            )}
          </>
          <AdReviewDecisionDialog
            isOpen={isReviewDecisionDialogOpen}
            adReviewDecisionState={adReviewDecisionState}
            setSelectedOptions={setAdReviewDecisionSelectedOptions}
            selectedOptions={adReviewDecisionSelectedOptions}
            onClose={onCloseDialog}
            selectedHomes={selectedHomes}
          />
        </>
      )}
    </Stack>
  )
}

import { Button, Label } from '@qasa/qds-ui'

import { Checkbox } from '../../../../components/input-components/checkbox'
import { RadioInput } from '../../../../components/input-components/radio-input'
import { Dialog } from '../../../../components/_core/dialog'
import { useAdReviewDecision } from '../../../../queries'
import { useAuthContext } from '../../../../context/auth-context'
import type { KanyeHome } from '../../homes-in-review'

type AdReviewDecisionState = {
  title: string
  decision: string
  options: { label: string; value: string }[]
  nextAction: ({ selectedOptions }: { selectedOptions: any }) => void
  nextActionLabel: string
  isSingleChoice: boolean
}

type AdReviewDecisionDialogProps = {
  adReviewDecisionState: AdReviewDecisionState
  setSelectedOptions: any
  selectedOptions: string[]
  selectedHomes: KanyeHome[]
  onClose: () => void
  isOpen: boolean
}

export function AdReviewDecisionDialog({
  adReviewDecisionState,
  selectedOptions,
  onClose,
  setSelectedOptions,
  selectedHomes,
  isOpen,
}: AdReviewDecisionDialogProps) {
  // employee todo: remove after new auth system switch
  const { authBody, currentEmployee } = useAuthContext()

  const adminUid = authBody?.uid || currentEmployee?.id
  const { mutate: adReviewDecision, isLoading } = useAdReviewDecision()
  const handleCheckboxClick = (option: any) => {
    const isOptionChecked = selectedOptions.includes(option)

    if (isOptionChecked) {
      setSelectedOptions(selectedOptions.filter((selectedOption: any) => selectedOption !== option))
      return
    }

    setSelectedOptions([...selectedOptions, option])
  }

  const handleRadioClick = (option: any) => {
    const isSelected = selectedOptions[0] === option

    if (isSelected) return

    setSelectedOptions([option])
  }

  const handleSubmitDecision = () => {
    selectedHomes.forEach((home: KanyeHome) => {
      adReviewDecision({
        id: Number(home.id),
        decision: adReviewDecisionState.decision,
        reasons: selectedOptions,
        reviewerUid: adminUid,
      })
    })
    adReviewDecisionState.nextAction({ selectedOptions })
    onClose()
  }

  const isSingleChoice = Boolean(adReviewDecisionState?.isSingleChoice)
  return (
    <Dialog isOpen={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{adReviewDecisionState?.title}</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Label>{adReviewDecisionState?.title}</Label>
          {adReviewDecisionState?.options?.map((option: any) => {
            if (isSingleChoice) {
              return (
                <RadioInput
                  key={option.label}
                  label={option.label}
                  value={option.value}
                  currentValue={selectedOptions[0]}
                  onClick={handleRadioClick}
                />
              )
            }
            return (
              <Checkbox
                key={option.label}
                label={option.label}
                value={option.value}
                currentValues={selectedOptions}
                onClick={handleCheckboxClick}
              />
            )
          })}
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="primary"
            isFullWidth
            isLoading={isLoading}
            disabled={!selectedOptions.length}
            onClick={handleSubmitDecision}
          >
            {adReviewDecisionState?.nextActionLabel}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

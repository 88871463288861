import { Button } from '@qasa/qds-ui'

import { useScheduleInvoices } from '../../../queries'

type ScheduleInvoiceProps = {
  contractId: string
}
export function ScheduleInvoices({ contractId }: ScheduleInvoiceProps) {
  const { mutate: scheduleInvoices, isLoading: isScheduleInvoiceLoading } = useScheduleInvoices()

  const handleScheduleInvoices = () => scheduleInvoices({ id: contractId })
  return (
    <Button onClick={handleScheduleInvoices} variant="tertiary" isLoading={isScheduleInvoiceLoading}>
      Schedule invoices
    </Button>
  )
}

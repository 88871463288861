import { DayPicker } from 'react-day-picker'
import { ChevronLeftIcon, ChevronRightIcon, IconButton } from '@qasa/qds-ui'

import { Wrapper } from './calendar-styles'
import type { CalendarProps } from './calendar.types'

export function Calendar(props: CalendarProps) {
  return (
    <Wrapper>
      <DayPicker
        // SE, FI, FR, NO all start the week on Monday.
        weekStartsOn={1}
        components={{
          PreviousMonthButton: (props) => (
            <IconButton
              as="button"
              size="sm"
              icon={ChevronLeftIcon}
              label={props['aria-label'] || 'Previous month'}
              {...props}
            />
          ),
          NextMonthButton: (props) => (
            <IconButton
              // Not really sure why the as="button" is needed here
              // but it is needed to avoid a typescript error
              as="button"
              size="sm"
              icon={ChevronRightIcon}
              label={props['aria-label'] || 'Previous month'}
              {...props}
            />
          ),
        }}
        {...props}
      />
    </Wrapper>
  )
}

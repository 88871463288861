import { graphql } from '../../../graphql/__generated__'

export const CREATE_REVIEW = graphql(`
  mutation CreateReview($action: ReviewDecisionEnum!, $createReviewId: ID!, $reasons: [String!]) {
    createReview(action: $action, id: $createReviewId, reasons: $reasons) {
      ... on AuthorizationError {
        message
        permissionAction
        permissionObjectType
      }
      ... on NotFoundError {
        message
      }
      ... on Home {
        id
      }
      ... on ValidationError {
        message
        attributeErrors {
          attribute
          message
          type
        }
      }
    }
  }
`)

/* eslint-disable import/no-unused-modules */
/* eslint-disable @typescript-eslint/no-unused-vars */

import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import { notifySuccess, notifyFailure, displayApiErrors } from 'utils'

import { VISIBLE_CONTRACT_STATUSES } from '../screens/user/constants'
import { Api } from '../api'
import { ContractsApi } from '../api/contracts-api'

export const getErrorMessage = (e) => {
  return get(e, 'response.data.errors[0].message', 'Unknown error. No error message received.')
}

export const sendInvoice = (id) =>
  Api.contractSendInvoice(id)
    .then(() => {
      notifySuccess('Invoice sent')
    })
    .catch(displayApiErrors)
sendInvoice.apisUsed = ["post '/invoices/:id/send'"]

export const reconfirmDeniedBooking = (payload) =>
  Api.reconfirmDeniedBooking(payload)
    .then(() => notifySuccess('Booking confirmed'))
    .catch(displayApiErrors)

export const markAsCredited = (id) =>
  Api.contractMarkAsCredited(id)
    .then(() => {
      notifySuccess('Marked as credited')
    })
    .catch(displayApiErrors)
markAsCredited.apisUsed = ["put '/invoices/:id/mark_as_credited'"]

export const resetInvoice = (id) =>
  Api.contractResetInvoice(id)
    .then(() => {
      notifySuccess('Invoice reset!')
    })
    .catch(displayApiErrors)
resetInvoice.apisUsed = ["put '/invoices/:id/reset'"]

export const destroyInvoice = (id) =>
  Api.contractDestroyInvoice(id)
    .then(() => {
      notifySuccess('Invoice destroy!')
    })
    .catch(displayApiErrors)
destroyInvoice.apisUsed = ["delete '/invoices/:id'"]

export const updateInvoiceEntries = (id, payload) =>
  Api.contractUpdateInvoiceEntries(id, payload)
    .then(() => {
      notifySuccess('Invoice updated!')
    })
    .catch(displayApiErrors)
updateInvoiceEntries.apisUsed = ["put '/invoices/:id/update_entries'"]

export const removeCreditedAmount = (id) =>
  Api.invoiceRemoveCreditedAmount(id)
    .then(() => {
      notifySuccess('Credited amount removed!')
    })
    .catch(displayApiErrors)
removeCreditedAmount.apisUsed = ["put '/invoices/:id/remove_credited_amount'"]

export const onInvoiceEnableReminders = (id) =>
  Api.invoiceEnableReminders(id)
    .then(() => {
      notifySuccess('Reminders enabled')
    })
    .catch(displayApiErrors)
onInvoiceEnableReminders.apisUsed = ["put '/invoices/:id/enable_reminders'"]

export const onInvoiceDisableReminders = (id) =>
  Api.invoiceDisableReminders(id)
    .then(() => {
      notifySuccess('Reminders disabled')
    })
    .catch(displayApiErrors)

export const cancelSelfInvoice = (id) =>
  Api.cancelSelfInvoice(id)
    .then(() => {
      notifySuccess('Cancelled self invoice')
    })
    .catch(displayApiErrors)

export const checkInvoicePayment = (invoiceId) => {
  Api.checkInvoicePayment(invoiceId)
    .then((response) => {
      notifySuccess('Checked invoice payment')
    })
    .catch((e) => {
      displayApiErrors(e)
    })
}

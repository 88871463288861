import styled from '@emotion/styled'
import { Stack } from '@qasa/qds-ui'

export const Tab = styled.button<{ isActive: boolean }>(({ theme, isActive }) => ({
  padding: theme.spacing['4x'],
  paddingBottom: theme.spacing['6x'],
  borderTopLeftRadius: theme.sizes['4x'],
  borderTopRightRadius: theme.sizes['4x'],
  transition: 'transform 100ms linear',
  marginBottom: -10,
  zIndex: 0,

  ...(isActive
    ? {
        transform: 'translateY(-6px)',
        backgroundColor: theme.colors.bg.brandSecondary,
        pointerEvents: 'none',
        color: theme.colors.core.white,
      }
    : {
        backgroundColor: theme.colors.bg.brandTertiary,
        '&:hover': {
          backgroundColor: theme.colors.bg.brandTertiaryHover,
          transform: 'translateY(-6px)',
        },
      }),
}))

type Tab = {
  id: string
  label: string
  onClick: () => void
}
type TabProps = {
  activeTab: string
  tabs: Tab[]
}

export function Tabs({ activeTab, tabs }: TabProps) {
  return (
    <Stack gap="2x" direction="row">
      {tabs.map(({ id, label, onClick }, i) => (
        <Tab key={i} isActive={activeTab === id} onClick={onClick}>
          {label}
        </Tab>
      ))}
    </Stack>
  )
}

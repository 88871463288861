import { Button, Divider, Link, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'

import { ContractStatusEnum, FranceInsuranceRequestStateEnum } from '../../../graphql/__generated__/graphql'
import { UpdateScriveId } from '../contracts/components/update-scrive-id'
import { UpdateDepositMonth } from '../contracts/update-deposit-month'
import type { KanyeUserContractType } from '../contracts/contracts.gql'
import { TransitionMilaDocumentsState } from '../contracts/components/transition-mila-documents-state/transition-mila-documents-state'
import { useAuthContext } from '../../../context/auth-context'
import { clearSentryUser } from '../../../vendor/sentry-client'
import { amplitude } from '../../../vendor/amplitude'

import { TransitionMilaInsuranceRequest } from './transition-mila-insurance-request'
import { DocumentRequestSelector } from './document-requests/document-request-selector'

const Column = styled(Stack)({ flex: 1 })

export const approvedMilaRequestStates = [
  FranceInsuranceRequestStateEnum.Approved,
  FranceInsuranceRequestStateEnum.AwaitingContractSigning,
]

type UserContractCTAsComponentProps = {
  contract: KanyeUserContractType
}
export function UserContractCTAsFrance({ contract }: UserContractCTAsComponentProps) {
  const { authBody } = useAuthContext()
  const isDraft = contract.status === ContractStatusEnum.Draft

  const rentalInsuranceMilaRequest = contract.rentalInsuranceMilaRequest
  const milaRequestCurrentState = rentalInsuranceMilaRequest?.currentState
  const milaId = rentalInsuranceMilaRequest?.milaId
  const documentRequests = rentalInsuranceMilaRequest?.documentRequests

  const hasUploadedDocuments = documentRequests ? documentRequests.length > 0 : false

  const isAwaitingMilaDecision =
    milaId &&
    hasUploadedDocuments &&
    !approvedMilaRequestStates.includes(milaRequestCurrentState as LegitimateAny)

  const hasNoActiveActions = !isDraft && !rentalInsuranceMilaRequest && !hasUploadedDocuments

  if (hasNoActiveActions) {
    return null
  }

  const isDev = process.env.NODE_ENV === 'development'

  // TODO: remove after auth system switch
  const resetNonEmployee = () => {
    // note: if user is logged in using old auth system remove the old access token
    // finance dashboard can only be used with employee login now
    if (authBody) {
      Cookies.remove('Access-Token')
      clearSentryUser()
      amplitude.reset()
    }
  }

  return (
    <Stack gap="6x" direction="row" style={{ flex: 1 }}>
      <Column gap="4x" divider={<Divider />}>
        <Button
          as={Link}
          onClick={resetNonEmployee}
          target="_blank"
          variant="tertiary"
          href={`${process.env.REACT_APP_API_URL}/admin/finance/contracts/${contract.id}`}
          type="submit"
          size="md"
          style={{ width: '100%' }}
        >
          {'Finance dashboard'}
        </Button>
        {rentalInsuranceMilaRequest && (
          <TransitionMilaInsuranceRequest rentalInsuranceMilaRequest={rentalInsuranceMilaRequest} />
        )}
        {hasUploadedDocuments && <DocumentRequestSelector documentRequests={documentRequests} />}
      </Column>
      <Column gap="4x" divider={<Divider />}>
        {isDraft && (
          <>
            <UpdateScriveId contractId={contract.id} />
            {/* We need to assert that we have the correct marketDetails type */}
            {contract.marketDetails?.__typename === 'FranceMarketDetails' && (
              <UpdateDepositMonth
                currentDepositMonths={contract.marketDetails.depositMonths}
                contractId={contract.id}
              />
            )}
          </>
        )}
        {isAwaitingMilaDecision && isDev && <TransitionMilaDocumentsState milaId={milaId} />}
      </Column>
    </Stack>
  )
}

import { useApolloClient, useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import { type ReactNode, useEffect } from 'react'
import { createContext, useContext, useState } from 'react'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'
import { Heading, LoadingDots, Paragraph } from '@qasa/qds-ui'
import axios from 'axios'

import type {
  AuthBodyQuery,
  EmployeePermissionActionEnum,
  KanyeCurrentUserQuery,
} from '../graphql/__generated__/graphql'
import { Login } from '../screens'
import { identirySentryUser, clearSentryUser } from '../vendor/sentry-client'
import { KANYE_CURRENT_USER } from '../screens/login/login.gql'
import { config } from '../config'
import { notifyFailure } from '../utils'
import { identifyUser, amplitude } from '../vendor/amplitude'

import { AUTH_BODY } from './auth-context.gql'

const Wrapper = styled.div(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing['4x'],
}))

const ACCESS_TOKEN_COOKIE_NAME = 'Access-Token'

const getAccessToken = () => Cookies.get(ACCESS_TOKEN_COOKIE_NAME)

export type KanyeCurrentUser = KanyeCurrentUserQuery['kanyeCurrentUser'] | undefined

export type Employee = Extract<KanyeCurrentUser, { __typename: 'Employee' }>

export type AuthBodyType = AuthBodyQuery['me']

type Permission = {
  __typename: 'EmployeePermission'
  objectType: string
  actions: EmployeePermissionActionEnum[]
}

export type AuthContextType = {
  authBody: AuthBodyType
  currentEmployee: Employee
  logOut: () => void
  permissions: Permission[]
  updateAccessToken: (token: string) => void
}

const AuthContext = createContext<AuthContextType>(undefined!)

function AuthProvider({ children }: { children: ReactNode }) {
  const [accessToken, setAccessToken] = useState(getAccessToken())
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const client = useApolloClient()
  const { push } = useHistory()

  const {
    data: kanyeCurrentUserData,
    loading: isLoadingKanyeCurrentUser,
    error: gLoginError,
    refetch: fetchKanyeCurrentUser,
  } = useQuery(KANYE_CURRENT_USER, {
    skip: isLoggingOut,
  })

  const {
    data: formLoginData,
    loading: isLoadingFormLoginData,
    error: formLoginError,
  } = useQuery(AUTH_BODY, {
    skip: !Boolean(accessToken),
  })

  const kanyeCurrentUser = kanyeCurrentUserData?.kanyeCurrentUser
  const typename = kanyeCurrentUser?.__typename

  if (
    typename &&
    (typename === 'AuthorizationError' || typename === 'NotFoundError' || typename === 'ValidationError')
  ) {
    notifyFailure(kanyeCurrentUser.message)
    reportError(kanyeCurrentUser.message)
  }

  const currentEmployee = typename === 'Employee' ? kanyeCurrentUser : undefined
  const loginPath = typename === 'LoginUrl' && kanyeCurrentUser ? kanyeCurrentUser.path : ''

  const amplitudeId = currentEmployee?.amplitudeId

  if (amplitudeId) {
    identifyUser({ userUid: amplitudeId })
  }

  useEffect(() => {
    if (accessToken && formLoginData) {
      const me = formLoginData?.me
      identirySentryUser({
        email: me?.private?.email || 'Email not found',
        id: me?.uid || 'UID not found',
        firstName: me?.firstName || 'First name not found',
      })

      const amplitudeId = me?.private.amplitudeId

      if (amplitudeId) {
        identifyUser({ userUid: amplitudeId })
      }
    }
  }, [accessToken, formLoginData])

  const updateAccessToken = (token: string) => {
    setAccessToken(token)
    Cookies.set(ACCESS_TOKEN_COOKIE_NAME, token, { expires: 1 })
  }

  const logOut = async () => {
    if (currentEmployee) {
      setIsLoggingOut(true)
      const logoutUrl = `${config.api}/admin/sessions/logout`

      try {
        const res = await axios.get(logoutUrl, { withCredentials: true })
        if (res.status === 200) {
          push('/')
          fetchKanyeCurrentUser()
        }
      } finally {
        setIsLoggingOut(false)
      }
    }
    setAccessToken(undefined)
    client.clearStore()
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME)
    clearSentryUser()
    push('/')
    amplitude.reset()
  }

  if (isLoadingKanyeCurrentUser || isLoadingFormLoginData) {
    return (
      <Wrapper>
        <LoadingDots />
      </Wrapper>
    )
  }

  const error = gLoginError || formLoginError

  if (error) {
    return (
      <Wrapper>
        <Heading size="lg">{'Something went wrong'}</Heading>
        <Paragraph color="negative" size="xl">
          {error.message}
        </Paragraph>
      </Wrapper>
    )
  }

  const railsSessionToken = Cookies.get('_rails_session')

  const compiledPermissions = (currentEmployee && currentEmployee?.compiledPermissions) || []

  if (!formLoginData?.me && !accessToken && !currentEmployee && !railsSessionToken) {
    return (
      <Login updateAccessToken={updateAccessToken} loginPath={loginPath} currentEmployee={currentEmployee} />
    )
  }

  const value = {
    updateAccessToken,
    authBody: formLoginData?.me,
    currentEmployee,
    permissions: compiledPermissions,
    logOut,
  }

  // @ts-expect-error employee todo: remove once Employee auth system has been adopted
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuthContext = () => useContext<AuthContextType>(AuthContext)

export { useAuthContext, AuthProvider }

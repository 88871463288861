import { PlatformEnum, MarketNameTypeEnum } from '../../../graphql/__generated__/graphql'

export const POSTS_PER_PAGE = 15

// employee todo: remove after auth system switch
export const platformEnumToMarketNameTypeEnumMap: Record<
  PlatformEnum,
  MarketNameTypeEnum | MarketNameTypeEnum[]
> = {
  [PlatformEnum.Blocket]: MarketNameTypeEnum.Sweden,
  [PlatformEnum.Dotcom]: [MarketNameTypeEnum.Sweden, MarketNameTypeEnum.Norway, MarketNameTypeEnum.Finland],
  [PlatformEnum.Homeq]: MarketNameTypeEnum.Sweden,
  [PlatformEnum.Qasa]: MarketNameTypeEnum.Sweden,
  [PlatformEnum.Finn]: MarketNameTypeEnum.Norway,
  [PlatformEnum.QasaFinland]: MarketNameTypeEnum.Finland,
  [PlatformEnum.QasaFrance]: MarketNameTypeEnum.France,
}

export enum HomesInReviewTabs {
  InReview = 'inReview',
  DeniedHomes = 'deniedHomes',
  NeedsVerificaiton = 'needsVerificaiton',
  LatestPublished = 'latestPublished',
  Shortcut = 'shortcut',
}

import { useState } from 'react'
import { Button, Label, Paragraph, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'

const Wrapper = styled(Stack)(({ theme }) => ({
  width: theme.sizes[320],
  gap: theme.spacing['2x'],
}))
const BodyWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['1x'],
  border: `${theme.colors.core.uiPinkLight} 1px solid`,
  padding: theme.spacing['2x'],
  height: theme.sizes[192],
  overflow: 'scroll',
}))
const ButtonWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['1x'],
}))
const IgnoreErrorsWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing['1x'],
}))
const ErrorContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing['1x'],
}))
const ErrorIndicies = styled.span({
  wordBreak: 'break-all',
})

type InformationSectionProps = {
  shouldDisableSend: boolean
  onSend: () => void
  onUploadNewCsv: () => void
  amountOfHomes: number
  rowsWithAlerts: { errors: string[]; warnings: string[] }
}
export function InformationSection({
  shouldDisableSend,
  onSend,
  onUploadNewCsv,
  amountOfHomes,
  rowsWithAlerts: { errors, warnings },
}: InformationSectionProps) {
  const [shouldIgnoreErrors, setShouldIgnoreErrors] = useState(false)
  const hasErrors = Boolean(errors.length)
  const hasWarnings = Boolean(warnings.length)

  const displayRows = (rows: string[]) => rows.map((rowNumber) => String(rowNumber))

  return (
    <Wrapper>
      <BodyWrapper>
        <Paragraph as="span">{amountOfHomes} home templates formatted</Paragraph>
        {(hasErrors || hasWarnings) && (
          <ErrorContainer>
            <ErrorIndicies>{hasErrors && `Errors found on rows: ${displayRows(errors)}`}</ErrorIndicies>
            {warnings.length > 3 ? (
              <ErrorIndicies>{`Warnings found on ${warnings.length} rows`}</ErrorIndicies>
            ) : (
              <ErrorIndicies>
                {hasWarnings && `Warnings found on rows: ${displayRows(warnings)}`}
              </ErrorIndicies>
            )}

            <Paragraph as="span">
              You should format these rows in your CSV file and try again, or if you think these errors are
              not correct you can ignore them.
            </Paragraph>

            <IgnoreErrorsWrapper>
              <Label htmlFor="ignoreErrors">Ignore errors</Label>
              <input
                type={'checkbox'}
                name="ignoreErrors"
                onChange={() => setShouldIgnoreErrors((previousValue) => !previousValue)}
                checked={shouldIgnoreErrors}
              />
            </IgnoreErrorsWrapper>
          </ErrorContainer>
        )}
      </BodyWrapper>
      <ButtonWrapper>
        <Button variant="tertiary" onClick={() => onUploadNewCsv()}>
          Upload new CSV
        </Button>
        <Button
          variant="tertiary"
          isDisabled={(hasErrors && !shouldIgnoreErrors) || shouldDisableSend}
          onClick={onSend}
        >
          Send
        </Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

import { useState } from 'react'
import styled from '@emotion/styled'
import {
  Heading,
  Stack,
  Paragraph,
  Textarea,
  Button,
  // IconButton,
  // ChevronDownIcon,
  // ChevronUpIcon,
} from '@qasa/qds-ui'

import { useUpdateAdminCommentForSuspicion, useMarkSuspicionAsFalse } from '../../queries'

import { formatText } from './suspicious-users.utils'
// import { SuspiciousEvent } from './suspicious-event'

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['4x'],
  backgroundColor: theme.colors.bg.default,
}))
const Column = styled(Stack)({
  flex: 1,
})

type SuspicionProps = {
  suspicion: any
  // activeSuspicionsCount: number
  refetch: () => void
}
export function Suspicion({
  // activeSuspicionsCount,
  suspicion,
  refetch,
}: SuspicionProps) {
  const { type, id, adminComment, flaggedAsFalseAt } = suspicion

  // const [isOpen, setIsOpen] = useState(false)
  const [isFieldEditable, setIsFieldEditable] = useState(false)
  const [updatedComment, setUpdatedComment] = useState(adminComment)
  const { mutate: updateAdminCommentForSuspicion, isLoading: isUpdateAdminCommentLoading } =
    useUpdateAdminCommentForSuspicion()
  const { mutate: markSuspicionAsFalse, isLoading: isMarkSuspicionAsFalseLoading } = useMarkSuspicionAsFalse()

  const handleMarkSuspicionAsFalse = () => {
    markSuspicionAsFalse(
      { id },
      {
        onSuccess: () => refetch(),
      },
    )
  }

  const handleUpdateComment = () => {
    updateAdminCommentForSuspicion(
      { id, adminComment: updatedComment },
      {
        onSuccess: () => refetch(),
      },
    )
  }

  return (
    <Wrapper gap="2x">
      <Heading>{formatText(type)}</Heading>
      <Stack direction="row" gap="2x">
        <Column gap="3x" alignItems="flex-start">
          {/* <div>
            <Paragraph size="sm" color="subtle">
              Created at: {new Date(createdAt).toLocaleString()}
            </Paragraph>
            <Paragraph size="sm" color="subtle">
              Updated at: {new Date(updatedAt).toLocaleString()}
            </Paragraph>
          </div> */}
          {flaggedAsFalseAt ? (
            <Paragraph>{`suspicion flagged as false at: ${flaggedAsFalseAt}`}</Paragraph>
          ) : (
            <Button
              variant="tertiary"
              isLoading={isMarkSuspicionAsFalseLoading}
              onClick={handleMarkSuspicionAsFalse}
            >
              Flag suspicion as false
            </Button>
          )}
        </Column>
        <Column gap="2x" alignItems="flex-end">
          <Textarea
            disabled={!isFieldEditable}
            label="Admin comment"
            value={updatedComment}
            onChange={(e) => setUpdatedComment(e.target.value)}
            minRows={2}
          />
          {isFieldEditable ? (
            <Stack direction="row" gap="3x">
              <Button size="sm" onClick={() => setIsFieldEditable(false)}>
                Cancel
              </Button>
              <Button
                size="sm"
                isDisabled={!updatedComment}
                isLoading={isUpdateAdminCommentLoading}
                onClick={handleUpdateComment}
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Button size="sm" onClick={() => setIsFieldEditable(true)}>
              Edit
            </Button>
          )}
        </Column>
      </Stack>
      {/* <Stack direction="row" alignItems="center">
        <IconButton
          icon={isOpen ? ChevronUpIcon : ChevronDownIcon}
          label="Show more"
          onClick={() => setIsOpen(!isOpen)}
        />
        <Paragraph>{activeSuspicionsCount} event(s)</Paragraph>
      </Stack> */}
      {/* {isOpen && suspicion.map((event: any, index: number) => <SuspiciousEvent key={index} event={event} />)} */}
    </Wrapper>
  )
}

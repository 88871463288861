import { graphql } from '../../graphql/__generated__'

export const SUSPICIOUS_USERS = graphql(`
  query SuspiciousUsers($platform: [PlatformEnum!]) {
    suspiciousUsers(platform: $platform) {
      ... on AuthorizationError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
        message
      }
      ... on KanyeUserConnection {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
        nodes {
          uid
          professional
          companyName
          firstName
          private {
            familyName
            email
          }
          emailConfirmedAt
          mainPlatform
          tenant
          identityConfirmedAt
          suspicions {
            nodes {
              adminComment
              flaggedAsFalseAt
              id
              type
            }
          }
        }
      }
    }
  }
`)

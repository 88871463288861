import { graphql } from '../../graphql/__generated__'

export const KANYE_USER_QUERY = graphql(/* GraphQL */ `
  query KanyeUser($uid: ID!) {
    kanyeUser(uid: $uid) {
      uid
      idNumber
      idNumberPretty
      mainPlatform
      professional
      removedAt
      reportCount
      adminNotes {
        author {
          ... on User {
            firstName
          }
        }
        createdAt
        id
        note
        updatedAt
      }
      private {
        id
        familyName
        email
        idNumber
        orgNumber
        externalAuths {
          externalId
          provider
        }
        phoneNumber
        invoiceAdsFrom
        stripeAccount {
          currentState
        }
        activeBankAccount {
          id
          verified
          verifiedAt
        }
      }
      creditStatusSummary {
        canSignFinland
        canSignOther
        canSignSweden
        certificate {
          status
          id
          certifiedAt
          expiresAt
          evaluatedAt
          createdAt
          updatedAt
        }
      }
      safeRental
      tenant
      landlord
      locale
      currentIdentification {
        identificationStatus
        identityConfirmedAt
        identityConfirmed
      }
      seenAt
      createdAt
      suspended
      companyName
      firstName
      profilePicture {
        id
        url
      }
    }
  }
`)

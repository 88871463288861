import { Button, Stack, TextField } from '@qasa/qds-ui'
import type { ChangeEvent } from 'react'
import { useState } from 'react'

import { useTerminateContract } from '../../../queries'

type TerminationOfRentalAgreementProps = {
  contractId: string
}

export function TerminationOfRentalAgreement({ contractId }: TerminationOfRentalAgreementProps) {
  const [terminationDate, setTerminationDate] = useState('')
  const { mutate: terminateContract, isLoading: isTerminateContractLoading } = useTerminateContract()

  const handleTerminationDateChange = (e: ChangeEvent<HTMLInputElement>) => setTerminationDate(e.target.value)

  const handleTerminateContract = () => {
    if (Boolean(terminationDate)) {
      terminateContract(
        { contractId, terminationDate },
        {
          onSuccess: () => {
            setTerminationDate('')
          },
        },
      )
    }
  }

  return (
    <Stack gap="3x">
      <TextField label="Termination of rental agreement" type="date" onChange={handleTerminationDateChange} />
      <Button
        variant="tertiary"
        disabled={!Boolean(terminationDate)}
        isLoading={isTerminateContractLoading}
        onClick={handleTerminateContract}
      >
        Terminate
      </Button>
    </Stack>
  )
}

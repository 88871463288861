import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Heading, LoadingDots, Stack, Link, Button, theme } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { QasaLink } from '../../components/qasa-link'
import { getFormattedHomeAddress } from '../homes-in-review/utils'
import { HomePreviewPane } from '../homes-in-review/home-preview-pane'
import { HomesInReviewTabs, POSTS_PER_PAGE } from '../homes-in-review/tabs/constants'
import { PaginationButtons } from '../homes-in-review/pagination-buttons'
import { useHomesInReview } from '../homes-in-review/use-homes-in-review'
import { ContentWrapper, VerifiedData, type KanyeHome } from '../homes-in-review/homes-in-review'
import { SearchBar } from '../../components/search-bar'
import { NewTable } from '../../components/table/new-table'
import { formatNumber } from '../../utils/helpers'

import { SettingsCard } from './settings-card'
import { ShortcutTransitionActions } from './shortcut-transition-actions'

const SettingsWrapper = styled(Stack)({
  maxWidth: 400,
})

export const updatedOutputTimeSinceShortcutCreation = (home: KanyeHome) => {
  const createdAt = home?.shortcut?.createdAt ?? ''
  return new Date(createdAt).toLocaleString()
}

export function Shortcut() {
  const [selectedHomes, setSelectedHomes] = useState<KanyeHome[]>([])
  const [userUid, setUserUid] = useState('')
  const [selectedRows, setSelectedRows] = useState<number[]>([])

  const {
    canFetchLess,
    canFetchMore,
    count,
    currentPage,
    fetchBtnClicked,
    fetchLess,
    fetchMore,
    homesData,
    isLoadingHomes,
    pageInfo,
    refetchHomes,
    searchForUserHomes,
    searchedUserHomes,
  } = useHomesInReview({ tab: HomesInReviewTabs.Shortcut, userUid })

  const homesArray = searchedUserHomes || homesData

  const onSelectionChanged = (selection: number[]) => {
    const data = searchedUserHomes || homesData
    // note: reverse the order so that the last selected home is always shown first in the home preview pane
    setSelectedHomes(selection.reverse().map((indexOfSelectedHome: number) => data[indexOfSelectedHome]))
  }

  const toggleRowSelection = (rowIndex: number) => {
    const selection = selectedRows.includes(rowIndex)
      ? selectedRows.filter((index) => index !== rowIndex)
      : [...selectedRows, rowIndex]
    setSelectedRows(selection)
    onSelectionChanged(selection)
  }

  const resetSelections = () => {
    setSelectedRows([])
    setSelectedHomes([])
  }

  const onComplete = () => {
    resetSelections()
    refetchHomes()
  }

  const onSubmit = () => {
    resetSelections()
    searchForUserHomes()
  }

  const clearSelection = () => {
    setSelectedRows([])
    onSelectionChanged([])
  }

  const isNotPaginating = fetchBtnClicked === ''
  const { hasNextPage, hasPreviousPage } = pageInfo || {}

  const columns = [
    {
      accessorKey: 'fullName',
      render: () => 'Name',
    },
    {
      accessorKey: 'email',
      render: () => 'Email',
    },
    {
      accessorKey: 'bankId',
      render: () => 'Bank ID',
      size: 130,
    },
    {
      accessorKey: 'address',
      render: () => 'Address',
    },
    {
      accessorKey: 'home',
      render: () => 'rent | m² | rooms',
      size: 160,
    },
    {
      accessorKey: 'shortcutStatus',
      render: () => 'Shorcut status',
      size: 140,
    },
    {
      accessorKey: 'createdAt',
      render: () => 'Created at',
      size: 160,
    },
  ]

  const tableData = homesData?.map((home: KanyeHome) => {
    const user = home.user
    const companyName = user.companyName
    const email = user.private.email

    return {
      id: home.id,
      fullName: (
        <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(email)}`}>
          {user.professional && companyName
            ? '🏢 ' + companyName
            : `${user.firstName} ${user.private.familyName}`}
        </Link>
      ),
      email: (
        <VerifiedData size="sm" isVerified={Boolean(user.emailConfirmed)}>
          {email}
        </VerifiedData>
      ),
      bankId: (
        <VerifiedData size="sm" isVerified={Boolean(user.identityConfirmedAt)}>
          {user.idNumberPretty || 'n/a'}
        </VerifiedData>
      ),
      address: <QasaLink to={`/home/${home.id}`}>{getFormattedHomeAddress(home)}</QasaLink>,
      home: `${formatNumber({ amount: home.rent || 0, currency: home.currency })} | ${home.squareMeters} | ${home.roomCount}`,
      shortcutStatus: home?.shortcut?.currentState ?? 'n/a',
      createdAt: updatedOutputTimeSinceShortcutCreation(home),
    }
  })

  return (
    <ContentWrapper gap="4x">
      <Heading>{'Shortcut'}</Heading>
      <SettingsWrapper>
        <SettingsCard />
      </SettingsWrapper>
      <Stack direction="row" gap="3x" alignItems="center">
        <SearchBar
          submit={onSubmit}
          onChange={(uid) => setUserUid(uid)}
          placeholder="User UID"
          value={userUid}
        />
        <Button
          size="xs"
          onClick={clearSelection}
          variant="tertiary"
          isDisabled={selectedRows.length < 1}
          style={{
            // note: compensates for the input gap between label and input, when there is no label the gap remains
            marginBottom: -theme.spacing['2x'],
          }}
        >
          {'Clear selection'}
        </Button>
      </Stack>
      <ShortcutTransitionActions selectedHomes={selectedHomes} onComplete={onComplete} />
      {isLoadingHomes && isNotPaginating ? (
        <LoadingDots />
      ) : (
        <>
          {homesArray.length === 0 ? (
            <Heading size="xs">{'No shortcut candidates right now 🤷‍♀️'}</Heading>
          ) : (
            <NewTable
              rowsData={tableData}
              columns={columns}
              selectedRows={selectedRows}
              toggleRowSelection={toggleRowSelection}
            />
          )}
        </>
      )}
      {Boolean(hasNextPage || hasPreviousPage) && (
        <PaginationButtons
          metadata={{
            totalHits: count,
            currentPage,
            totalPages: Math.ceil(count / POSTS_PER_PAGE),
          }}
          handleFetchLess={fetchLess}
          isLoadingLess={isLoadingHomes && fetchBtnClicked === 'less'}
          canFetchLess={Boolean(canFetchLess)}
          handleFetchMore={fetchMore}
          canFetchMore={Boolean(canFetchMore)}
          isLoadingMore={isLoadingHomes && fetchBtnClicked === 'more'}
        />
      )}
      <HomePreviewPane homes={selectedHomes} />
    </ContentWrapper>
  )
}

import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router'
import { Paragraph, Stack } from '@qasa/qds-ui'

import { Tabs } from '../../components/tabs/tabs'
import type { KanyeHomesQuery } from '../../graphql/__generated__/graphql'

import { InReview, DeniedHomes, NeedsVerification, LatestPublished } from './tabs'
import { HomePreviewPane } from './home-preview-pane'
import { HomesInReviewTabs, POSTS_PER_PAGE } from './tabs/constants'
import type { SearchForUserHomesLazyQuery } from './use-homes-in-review'
import { useHomesInReview } from './use-homes-in-review'
import { PaginationButtons } from './pagination-buttons'

export const ContentWrapper = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing['4x'],
  paddingTop: theme.spacing['4x'],
  backgroundColor: theme.colors.bg.default,
  position: 'relative',
  overflow: 'scroll',
  width: '100%',
  height: '100%',
}))
export const VerifiedData = styled(Paragraph)<{ isVerified: boolean }>(({ theme, isVerified }) => ({
  color: isVerified ? theme.colors.text.positive : theme.colors.text.negative,
}))

export type KanyeHome = Extract<
  KanyeHomesQuery['kanyeHomes'],
  { __typename: 'KanyeHomeConnection' }
>['nodes'][number]

export type KanyeHomeUser = Pick<KanyeHome, 'user'>

export type HomesInReviewTabProps = {
  fetchBtnClicked: string
  homesData: KanyeHome[]
  isLoadingHomes: boolean
  onSelectionChanged: (selection: number[]) => void
  refetchHomes: () => void
  resetSelections: () => void
  searchForUserHomes: SearchForUserHomesLazyQuery
  selectedHomes: KanyeHome[]
  selectedRows: number[]
  setSelectedRows: Dispatch<SetStateAction<number[]>>
  setUserUid: Dispatch<SetStateAction<string>>
  userUid: string
  clearSelection: () => void
  toggleRowSelection: (rowIndex: number) => void
}

const TAB_COMPONENT = {
  [HomesInReviewTabs.InReview]: InReview,
  [HomesInReviewTabs.DeniedHomes]: DeniedHomes,
  [HomesInReviewTabs.NeedsVerificaiton]: NeedsVerification,
  [HomesInReviewTabs.LatestPublished]: LatestPublished,
}

const PATH_TAB_MAP = {
  '/homes-in-review': HomesInReviewTabs.InReview,
  '/homes-in-review/denied-homes': HomesInReviewTabs.DeniedHomes,
  '/homes-in-review/needs-verification': HomesInReviewTabs.NeedsVerificaiton,
  '/homes-in-review/latest-published': HomesInReviewTabs.LatestPublished,
} as const

type TabMapKeys = keyof typeof PATH_TAB_MAP
type TabMapValues = (typeof PATH_TAB_MAP)[TabMapKeys]

export function HomesInReview() {
  const [userUid, setUserUid] = useState('')
  const [activeTab, setActiveTab] = useState(HomesInReviewTabs.InReview)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [selectedHomes, setSelectedHomes] = useState<KanyeHome[]>([])

  const {
    canFetchLess,
    canFetchMore,
    clearPageInfo,
    count,
    currentPage,
    fetchBtnClicked,
    fetchLess,
    fetchMore,
    homesData,
    isLoadingHomes,
    pageInfo,
    refetchHomes,
    searchedUserHomes,
    searchForUserHomes,
  } = useHomesInReview({ tab: activeTab, userUid })

  const homesArray = searchedUserHomes || homesData

  const resetSelections = () => {
    setSelectedRows([])
    setSelectedHomes([])
  }

  const onSelectionChanged = (selection: number[]) => {
    const data = searchedUserHomes || homesData
    // note: reverse the order so that the last selected home is always shown first in the home preview pane
    setSelectedHomes(selection.reverse().map((indexOfSelectedHome: number) => data[indexOfSelectedHome]))
  }

  const { pathname, search } = location
  const { push } = useHistory()

  const changeTab = ({ path }: { path: string }) => {
    clearPageInfo()
    push(`/homes-in-review${path}${search}`)
  }

  const clearSelection = () => {
    setSelectedRows([])
    onSelectionChanged([])
  }

  const toggleRowSelection = (rowIndex: number) => {
    const selection = selectedRows.includes(rowIndex)
      ? selectedRows.filter((index) => index !== rowIndex)
      : [...selectedRows, rowIndex]
    setSelectedRows(selection)
    onSelectionChanged(selection)
  }

  useEffect(() => {
    setActiveTab(PATH_TAB_MAP[pathname as TabMapKeys])
  }, [pathname])

  if (!activeTab) return null

  const tabs = [
    {
      id: HomesInReviewTabs.InReview,
      label: 'In review',
      onClick: () => changeTab({ path: '' }),
    },
    {
      id: HomesInReviewTabs.DeniedHomes,
      label: 'Denied homes',
      onClick: () => changeTab({ path: '/denied-homes' }),
    },
    {
      id: HomesInReviewTabs.NeedsVerificaiton,
      label: 'Needs verification',
      onClick: () => changeTab({ path: '/needs-verification' }),
    },
    {
      id: HomesInReviewTabs.LatestPublished,
      label: 'Latest published',
      onClick: () => changeTab({ path: '/latest-published' }),
    },
  ]

  const ActiveTab = TAB_COMPONENT[activeTab as TabMapValues]

  const { hasNextPage, hasPreviousPage } = pageInfo || {}

  return (
    <>
      <Tabs tabs={tabs} activeTab={activeTab} />
      <Stack direction="row" alignItems="flex-end">
        <ContentWrapper gap="4x">
          <ActiveTab
            fetchBtnClicked={fetchBtnClicked}
            homesData={homesArray}
            isLoadingHomes={isLoadingHomes}
            onSelectionChanged={onSelectionChanged}
            refetchHomes={refetchHomes}
            resetSelections={resetSelections}
            searchForUserHomes={searchForUserHomes}
            selectedHomes={selectedHomes}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setUserUid={setUserUid}
            userUid={userUid}
            clearSelection={clearSelection}
            toggleRowSelection={toggleRowSelection}
          />
          {/* // note: hide pagination when searching for a users homes, pagination has not been added yet for searchKanyeUsersHomes query */}
          {Boolean(hasNextPage || hasPreviousPage) && !searchedUserHomes && (
            <PaginationButtons
              metadata={{
                totalHits: count,
                currentPage,
                totalPages: Math.ceil(count / POSTS_PER_PAGE),
              }}
              handleFetchLess={() => {
                resetSelections()
                fetchLess()
              }}
              isLoadingLess={isLoadingHomes && fetchBtnClicked === 'less'}
              canFetchLess={Boolean(canFetchLess)}
              handleFetchMore={() => {
                resetSelections()
                fetchMore()
              }}
              canFetchMore={Boolean(canFetchMore)}
              isLoadingMore={isLoadingHomes && fetchBtnClicked === 'more'}
            />
          )}
        </ContentWrapper>
      </Stack>
      <HomePreviewPane homes={selectedHomes} />
    </>
  )
}

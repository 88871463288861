import { type EvictionProcess } from '../../../graphql/__generated__/graphql'
import { formatDate } from '../../../utils/date'
import { formatNumber } from '../../../utils/helpers'

export const formatSocPdfData = (evictionProcess: EvictionProcess) => {
  const landlord = evictionProcess?.landlord
  const tenant = evictionProcess?.user
  const contract = evictionProcess?.contract

  const landlordName = `${landlord?.firstName} ${landlord?.private.familyName}`
  const dueDate = new Date(evictionProcess.invoice.dueAt)
  const landlordLocation = landlord.private.activeBankAccount?.location
  const homeLocation = contract.home?.location
  const home = contract.home
  const companyInfo = evictionProcess.socInfo.qasaInfo

  return {
    qasa: {
      firma: 'Qasa AB',
      orgnummer: companyInfo.orgnummer,
      adress: companyInfo.adress,
      postnummer: companyInfo.postnummer,
      ort: companyInfo.ort,
      bankgiro: companyInfo.bankgiro,
    },
    hyresvard: {
      namn: landlordName,
      epost: landlord?.private.email ?? '',
      telefonnummer: landlord?.private.phoneNumber ?? '',
      personnummer: evictionProcess.landlord.idNumber ?? '',
      adress: `${landlordLocation?.route} ${landlordLocation?.streetNumber}`,
      postnrOrt: `${landlordLocation?.postalCode} ${landlordLocation?.locality}`,
    },
    hyresgast: {
      namn: `${tenant?.firstName} ${tenant?.private.familyName}`,
      epost: tenant?.private.email ?? '',
      telefonnummer: tenant?.private.phoneNumber ?? '',
      personnummer: tenant?.idNumber ?? '',
      adress: `${homeLocation?.route} ${homeLocation?.streetNumber}`,
      postnrOrt: `${homeLocation?.postalCode} ${homeLocation?.locality}`,
    },
    hyra: {
      manad: evictionProcess.socInfo.nextMonth ?? '',
      forfallodatum: formatDate(dueDate),
      obetaltBelopp: formatNumber({
        amount: evictionProcess.invoice.amount,
        currency: evictionProcess.contract.currency,
      }),
    },
    hem: {
      adress:
        homeLocation?.formattedAddress ||
        `${homeLocation?.route} ${homeLocation?.streetNumber}, ${homeLocation?.postalCode} ${homeLocation?.locality}`,
      antalRum: String(home?.roomCount),
      ytaKvm: String(home?.squareMeters),
    },
    underskrift: {
      ort: homeLocation?.locality ?? '',
      hyresvardNamn: landlordName,
      datum: formatDate(new Date()),
    },
  }
}

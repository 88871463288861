import { useState } from 'react'
import { Stack, Button, Heading, Label } from '@qasa/qds-ui'
import { useMutation } from '@apollo/client'

import { Dialog } from '../../components/_core/dialog'
import { useAuthorisationContext } from '../../context/authorisation-context'
import { notifyFailure, notifySuccess } from '../../utils'

import { ListCard } from './components/list-card'
import { GROUPS, UPSERT_EMPLOYEE_GROUP } from './authorisation.gql'

export function AddEmployeeToGroupDialog() {
  const {
    employees,
    selectedGroup,
    dialogMethods: {
      assignEmployeeToGroupDialog: { close: onClose, status: isOpen },
    },
  } = useAuthorisationContext()
  const [employeesForGroup, setEmployeesForGroup] = useState<string[]>([])

  if (!selectedGroup) return null

  const employeesMinusGroupMembers = employees
    .filter((md) => selectedGroup?.employees.nodes.every((fd) => fd.id !== md.id))
    .sort((a, b) => {
      if (a.name < b.name) return -1
      else if (a.name > b.name) return 1
      else return 0
    })

  const selectedGroupEmployeeIds = selectedGroup.employees.nodes.map((employee) => employee.id)
  const selectedGroupRoleIds = selectedGroup.roles.nodes.map((role) => role.id)

  const [updateGroup, { loading: isUpdatingGroup }] = useMutation(UPSERT_EMPLOYEE_GROUP, {
    variables: {
      employeeIds: [...selectedGroupEmployeeIds, ...employeesForGroup],
      upsertEmployeeGroupId: selectedGroup.id,
      name: selectedGroup.name,
      roleIds: selectedGroupRoleIds,
    },
    onCompleted: () => {
      // TODO : error handling
      notifySuccess(`${employeesForGroup.length} new employees added to ${selectedGroup.name}`)
      onClose()
    },
    refetchQueries: [GROUPS],
    onError: (error) => {
      notifyFailure(`Error updating Group: ${selectedGroup.name}`)
      notifyFailure(error.message)
    },
  })

  const handleEmployeeSelection = ({ employeeId }: { employeeId: string }) => {
    if (employeesForGroup.includes(employeeId)) {
      setEmployeesForGroup((prev) => prev.filter((id) => id !== employeeId))
    } else {
      setEmployeesForGroup((prev) => [employeeId, ...prev])
    }
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={onClose}>
      <Dialog.Content size="auto">
        <Dialog.Header>
          <Heading size="sm">{'Add employee to group'}</Heading>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="3x">
            <Stack gap="4x" direction="row" alignItems="center">
              <Label>{'Select employee(s)'}</Label>
              <Button
                size="xs"
                onClick={() => setEmployeesForGroup([])}
                variant="tertiary"
                isDisabled={employeesForGroup.length === 0}
              >
                {'Clear selection'}
              </Button>
            </Stack>
            <Stack
              gap="3x"
              wrap="wrap"
              direction="row"
              alignItems="center"
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto',
              }}
            >
              {employeesMinusGroupMembers.map((element) => {
                const { name, email, profilePictureUrl, id } = element
                return (
                  <ListCard
                    key={id}
                    name={name}
                    email={email}
                    profilePictureUrl={profilePictureUrl}
                    onSelectCard={() => handleEmployeeSelection({ employeeId: id })}
                    isSelected={employeesForGroup.includes(id)}
                  />
                )
              })}
            </Stack>
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="tertiary"
            isFullWidth
            isLoading={isUpdatingGroup}
            disabled={employeesForGroup.length === 0}
            onClick={() => updateGroup()}
          >
            {'Save'}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

import { graphql } from '../../graphql/__generated__'

export const CREATE_ADMIN_NOTE = graphql(`
  mutation CreateAdminNote($input: CreateAdminNoteInput!) {
    createAdminNote(input: $input) {
      adminNote {
        author {
          ... on User {
            uid
          }
        }
        createdAt
        id
        note
      }
      errors {
        ...BaseError
      }
    }
  }
`)

export const DESTROY_ADMIN_NOTE = graphql(`
  mutation DestroyAdminNote($destroyAdminNoteId: ID!) {
    destroyAdminNote(id: $destroyAdminNoteId) {
      id
    }
  }
`)

import { Link as RouterLink } from 'react-router-dom'
import { Button, Heading, Link, LoadingDots, Stack, theme } from '@qasa/qds-ui'

import { SearchBar } from '../../../components/search-bar'
import { QasaLink } from '../../../components/qasa-link'
import { NewTable } from '../../../components/table/new-table'
import { confirmAction, getFormattedHomeAddress } from '../utils'
import { VerifiedData, type HomesInReviewTabProps, type KanyeHome } from '../homes-in-review'
import { formatNumber } from '../../../utils/helpers'
import { ReviewDecisionEnum } from '../../../graphql/__generated__/graphql'

import { useCreateReview } from './use-create-review'

export function DeniedHomes({
  fetchBtnClicked,
  homesData,
  isLoadingHomes,
  onSelectionChanged,
  refetchHomes,
  resetSelections,
  searchForUserHomes,
  selectedHomes,
  selectedRows,
  setSelectedRows,
  setUserUid,
  userUid,
  toggleRowSelection,
}: HomesInReviewTabProps) {
  const { actionTaken, createReview, isLoading, setActionTaken } = useCreateReview({
    refetchHomes,
    resetSelections,
  })

  const handlePublishHome = () => {
    if (selectedHomes.length < 2 || confirmAction({ numberOfHomes: selectedHomes.length })) {
      const homeIds = selectedHomes.map((home: KanyeHome) => home.id)
      setActionTaken('Publish')
      homeIds.forEach((homeId) => {
        createReview({
          variables: {
            createReviewId: homeId,
            action: ReviewDecisionEnum.Approved,
            reasons: [],
          },
        })
      })
    }
  }

  const isNotPaginating = fetchBtnClicked === ''

  const columns = [
    {
      accessorKey: 'rentalType',
      render: () => 'Type',
      size: 160,
    },
    {
      accessorKey: 'fullName',
      render: () => 'Name',
      size: 200,
    },
    {
      accessorKey: 'email',
      render: () => 'Email',
    },
    {
      accessorKey: 'bankId',
      render: () => 'Bank ID',
      size: 130,
    },
    {
      accessorKey: 'address',
      render: () => 'Address',
    },
    {
      accessorKey: 'home',
      render: () => 'rent | m² | rooms',
      size: 160,
    },
    {
      accessorKey: 'denyReason',
      render: () => 'Deny reason',
      size: 140,
    },
  ]

  const tableData = homesData?.map((home: KanyeHome) => {
    const user = home.user
    const companyName = user.companyName
    const email = user.private.email

    return {
      id: home.id,
      rentalType: home.rentalType,
      fullName: (
        <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(email)}`}>
          {user.professional && companyName
            ? '🏢 ' + companyName
            : `${user.firstName} ${user.private.familyName}`}
        </Link>
      ),
      email: (
        <VerifiedData size="sm" isVerified={Boolean(user.emailConfirmed)}>
          {email}
        </VerifiedData>
      ),
      bankId: (
        <VerifiedData size="sm" isVerified={Boolean(user.identityConfirmedAt)}>
          {user.idNumberPretty || 'n/a'}
        </VerifiedData>
      ),
      address: <QasaLink to={`/home/${home.id}`}>{getFormattedHomeAddress(home)}</QasaLink>,
      home: `${formatNumber({ amount: home.rent || 0, currency: home.currency })} | ${home.squareMeters} | ${home.roomCount}`,
      denyReason: home.archiveReason,
    }
  })

  const clearSelection = () => {
    setSelectedRows([])
    onSelectionChanged([])
  }

  return (
    <Stack gap="4x">
      {isLoadingHomes && isNotPaginating ? (
        <LoadingDots />
      ) : (
        <>
          <Stack direction="column" gap="4x">
            <Stack direction="row" gap="3x" alignItems="center">
              <SearchBar
                submit={searchForUserHomes}
                onChange={(uid) => setUserUid(uid)}
                placeholder="User UID"
                value={userUid}
              />
              <Button
                size="xs"
                onClick={clearSelection}
                variant="tertiary"
                isDisabled={selectedRows.length < 1}
                style={{
                  // note: compensates for the input gap between label and input, when there is no label the gap remains
                  marginBottom: -theme.spacing['2x'],
                }}
              >
                {'Clear selection'}
              </Button>
            </Stack>
            <div>
              <Button
                variant="tertiary"
                disabled={selectedHomes.length === 0}
                onClick={handlePublishHome}
                isLoading={isLoading && actionTaken === 'Publish'}
              >
                Publish
              </Button>
            </div>
          </Stack>
          <>
            {homesData.length === 0 ? (
              <Heading size="xs">No denied homes right now 🤷‍♀️</Heading>
            ) : (
              <NewTable
                rowsData={tableData}
                columns={columns}
                selectedRows={selectedRows}
                toggleRowSelection={toggleRowSelection}
              />
            )}
          </>
        </>
      )}
    </Stack>
  )
}

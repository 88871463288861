import { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Label, Paragraph, Stack } from '@qasa/qds-ui'

import { Input } from '../../components/input-components/input-component'

import { EditableInput } from './editable-input'
import type { SearchArea } from './search-areas'

const Wrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.colors.bg.default,
  marginTop: theme.spacing['4x'],
  padding: theme.spacing['4x'],
}))
const InputWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing['3x'],
}))

type SearchAreaMetadataProps = {
  searchArea: SearchArea
  deleteKeyword: ({ id, identifier }: { id: string; identifier: string }) => void
  createKeyword: ({
    searchAreaIdentifier,
    keyword,
  }: {
    searchAreaIdentifier: string
    keyword: string
  }) => void
  updateKeyword: ({ id, keyword }: { id: string; keyword: string }) => void
}

export function SearchAreaMetadata({
  searchArea,
  deleteKeyword,
  createKeyword,
  updateKeyword,
}: SearchAreaMetadataProps) {
  const [isInputShowing, setIsDialogShowing] = useState(false)
  const [keyword, setKeyword] = useState('')

  const createdBy = searchArea.createdBy
  const createdAt = searchArea.createdAt
  const identifier = searchArea.identifier
  const fallback = searchArea.fallback
  const timesShown = searchArea.timesShown
  const origin = searchArea.origin
  const keywords = searchArea.searchAreaKeywords

  if (!identifier) {
    return null
  }

  const onKeywordChange = (value: string) => setKeyword(value)

  const handleCreate = () => {
    createKeyword({ searchAreaIdentifier: identifier, keyword: keyword.trim() })
  }

  const handleToggleInputField = () => {
    setIsDialogShowing(!isInputShowing)
  }

  const handleUpdate = ({ id, keyword }: { id: string; keyword: string }) => {
    updateKeyword({ id, keyword: keyword.trim() })
  }

  const shouldDisplayKeywords = origin === 'qasa'

  const metaDataArray = [
    { name: 'Identifier: ', value: identifier, check: identifier },
    { name: 'Fallback: ', value: String(fallback), check: fallback !== null },
    {
      name: 'Created by: ',
      value: `${createdBy.email} / ${createdBy.uid}`,
      check: createdBy && createdBy.email,
    },
    { name: 'Created at: ', value: new Date(createdAt).toDateString(), check: createdAt },
    { name: 'Times shown: ', value: timesShown, check: Boolean(timesShown) },
    { name: 'Keywords: ', value: '', check: origin === 'qasa' },
  ]

  return (
    <Wrapper gap="4x">
      {metaDataArray.map(({ name, value, check }) => {
        return (
          check && (
            <Label>
              {name}
              <Paragraph as="span">{value}</Paragraph>
            </Label>
          )
        )
      })}
      {shouldDisplayKeywords && (
        <>
          {keywords?.map((keyword: { keyword: string; id: number }) => {
            const name = keyword.keyword
            const id = keyword.id
            return (
              <InputWrapper key={id}>
                <EditableInput
                  inputText={name}
                  updateText={(keyword) => handleUpdate({ keyword, id: String(id) })}
                />
                <Button variant="tertiary" onClick={() => deleteKeyword({ identifier, id: String(id) })}>
                  {'Delete'}
                </Button>
              </InputWrapper>
            )
          })}
          <Stack direction="row">
            <Button variant="tertiary" size="xs" onClick={handleToggleInputField}>
              {'Create new keyword'}
            </Button>
          </Stack>
          {isInputShowing && (
            <InputWrapper>
              <Input placeholder="keyword" onChange={onKeywordChange} onEnter={handleCreate} />
              <Button variant="tertiary" onClick={handleCreate}>
                {'Create'}
              </Button>
            </InputWrapper>
          )}
        </>
      )}
    </Wrapper>
  )
}

import { useState, useEffect } from 'react'
import { LoadingDots, Stack, Textarea, Button } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import { Api } from '../../api'
import { displayApiErrors } from '../../utils'

import { RiskClassificationData } from './risk-classification-data'
import { PlianceData } from './pliance-data'
import { PlianceClassify } from './pliance-classify'
import { SupportFlag } from './support-flag'
import { AssignRisk } from './assign-risk'
import { UserHeader } from './user-header'
import { CompanyHeader } from './company-header'
import { useUserContext } from './user-context'

const TabPageWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing['2x'],
}))
const Row = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing['3x'],
}))
const Cell = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  paddingInline: theme.spacing['4x'],
}))
const SectionBackground = styled(Stack)<{ bgc: 'red30' | 'blue30' | 'green30' | 'yellow30' }>(
  ({ theme, bgc }) => ({
    height: '100%',
    borderRadius: theme.radii.lg,
    padding: theme.spacing['6x'],
    backgroundColor: theme.colors.core[bgc],
  }),
)

export function UserRiskClassification() {
  const { user } = useUserContext()
  const [index, setIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [riskClassifications, setRiskClassifications] = useState<any>([])
  const isUserProfessional = user.professional
  const uid = user.uid

  useEffect(() => {
    setIsLoading(true)
    Api.getRiskClassifications(uid)
      .then((res) => {
        setRiskClassifications(res.data.riskClassifications)
      })
      .catch(displayApiErrors)
      .finally(() => setIsLoading(false))
  }, [user])

  const showNextPage = () => setIndex((prevState) => prevState + 1)

  const showPrevPage = () => setIndex((prevState) => prevState - 1)

  const getRiskClassificationDiff = () => {
    const currentClassification = riskClassifications[index]

    if (!currentClassification) {
      return []
    }

    const currentArray: any[] = Array.from(currentClassification)

    if (index === riskClassifications.length - 1) {
      return currentArray.filter(([, value]) => value).map(([key]) => key)
    }

    const previous = riskClassifications[index] + 1
    const diffKeys = currentArray
      .filter(([key, value]) => {
        return value !== previous.get(key)
      })
      .map(([key]) => key)
    return diffKeys
  }

  if (isLoading) return <LoadingDots />

  if (!riskClassifications || riskClassifications?.length === 0) return <SupportFlag userId={uid} />

  return (
    <TabPageWrapper gap="4x" justifyContent="space-between">
      <Row direction="row" justifyContent="space-between">
        <Cell>
          <Stack gap="4x">
            <UserHeader user={user} />
            {isUserProfessional && <CompanyHeader user={user} />}
            <RiskClassificationData
              riskClassification={riskClassifications[index]}
              riskClassificationDiffKeys={getRiskClassificationDiff()}
            />
            <Textarea
              label="Risk assessment note:"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              readOnly
              value={riskClassifications[index]?.riskAssessmentNote || ''}
            />
            <Stack direction="row" gap="4x" justifyContent="space-between">
              <Button
                variant="tertiary"
                onClick={showNextPage}
                size="xs"
                isDisabled={index >= riskClassifications.size - 1}
              >
                Older
              </Button>
              <Button variant="tertiary" onClick={showPrevPage} size="xs" isDisabled={index <= 0}>
                Newer
              </Button>
            </Stack>
          </Stack>
        </Cell>
        <Cell>
          <Stack gap="8x">
            <SectionBackground bgc="red30">
              <AssignRisk />
            </SectionBackground>
            <SectionBackground bgc="blue30">
              <PlianceData userUid={uid} />
            </SectionBackground>
          </Stack>
        </Cell>
        <Cell>
          <Stack gap="8x">
            <SectionBackground bgc="yellow30">
              <SupportFlag userId={uid} />
            </SectionBackground>
            <SectionBackground bgc="green30">
              <PlianceClassify userUid={uid} />
            </SectionBackground>
          </Stack>
        </Cell>
      </Row>
    </TabPageWrapper>
  )
}

import { useMutation } from '@apollo/client'
import { Button, Stack, Select } from '@qasa/qds-ui'
import { useState } from 'react'

import { notifyFailure, notifySuccess } from '../../../../../utils'
import {
  FranceInsuranceRequestStateEnum,
  MilaTestCommand,
} from '../../../../../graphql/__generated__/graphql'

import { TRANSITION_MILA_DOCUMENTS_STATE } from './transition-mila-documents-state.gql'

const milaTestCommandMap = {
  [MilaTestCommand.Accept]: FranceInsuranceRequestStateEnum.AwaitingContractSigning,
  [MilaTestCommand.Refuse]: FranceInsuranceRequestStateEnum.PendingRejection,
  [MilaTestCommand.Incomplete]: FranceInsuranceRequestStateEnum.AwaitingDocuments,
}

export function TransitionMilaDocumentsState({ milaId }: { milaId: string }) {
  const [milaDocumentStateCommand, setMilaDocumentStateCommand] = useState(MilaTestCommand.Accept)
  const [approveMilaDocs, { loading: isApprovingMilaDocs }] = useMutation(TRANSITION_MILA_DOCUMENTS_STATE, {
    variables: {
      command: milaDocumentStateCommand,
      milaId,
    },
    onCompleted: (data) => {
      if (data?.milaTestCommand?.__typename === 'Success') {
        notifySuccess(
          `Operation successful: mila document state transitioned to ${milaTestCommandMap[milaDocumentStateCommand]}`,
        )
      } else {
        notifyFailure('Could not approve mila documents')
      }
    },
    onError: () => notifyFailure('Could not approve mila documents'),
    refetchQueries: ['Contracts'],
  })

  return (
    <Stack gap="3x">
      <Select
        value={milaDocumentStateCommand}
        label="Transition mila document state"
        onChange={(e) => setMilaDocumentStateCommand(e.target.value as MilaTestCommand)}
      >
        {Object.values(MilaTestCommand).map((command) => (
          <Select.Option value={command} key={command}>
            {command}
          </Select.Option>
        ))}
      </Select>
      <Button
        variant="tertiary"
        type="submit"
        size="md"
        onClick={() => approveMilaDocs()}
        isLoading={isApprovingMilaDocs}
      >
        {'Transition state'}
      </Button>
    </Stack>
  )
}

import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button, Heading, Label, Paragraph, Stack, useBreakpointValue } from '@qasa/qds-ui'
import { useLazyQuery } from '@apollo/client'

import { updateProcessLoading, updateProcessSuccess, updateProcessFailed } from '../overdue-payments-actions'
import { Api } from '../../../api'
import { displayApiErrors, notifyFailure } from '../../../utils'
import { EVICTION_PROCESS } from '../../user/contracts/eviction-process.gql'
import type { EvictionProcessState } from '../../../graphql/__generated__/graphql'
import { GenerateSocPdfButton } from '../../../components/pdf-generators/generate-Soc-pdf-button'
import { GenerateKfmPdfButton } from '../../../components/pdf-generators/generate-KfmPdf-button'

import { EvictionProcess } from './processes'
import { CommentsSection } from './comments-section'
import { StepHistory } from './step-history'
import { STATES } from './processes/eviction-process/eviction-process'

const Wrapper = Stack
const ProcessHeader = styled(Stack)(({ theme }) => ({
  padding: '0 0 4px',
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.colors.border.default,
}))
const Content = Stack
const ProcessContent = styled(Stack)({ width: 400 })
const StepHistoryContainer = styled(Paragraph)({ position: 'relative', cursor: 'pointer' })
const StepHistoryPopupContainer = styled.div({ position: 'absolute', top: '100%', left: '0' })

export const getProcess = (processName: string) => {
  switch (processName) {
    case 'Eviction':
    default:
      return EvictionProcess
  }
}

type ProcessProps = {
  userUid: string
  process: any
  invoice: any
  dispatch: any
  addComment: {
    isLoading: boolean
    error: any
  }
  updateProcess: {
    isLoading: boolean
    error: any
  }
}
export function Process({ process, dispatch, addComment, updateProcess }: ProcessProps) {
  const [isStepHistoryVisible, setIsStepHistoryVisible] = useState(false)
  const contentLayoutDirection = useBreakpointValue({ base: 'column', md: 'row' } as const)
  // note: boolean split here to allow the "Show manual pdf actions" button loading state work properly
  const [showManualPdfCreationButtons, setShowManualPdfCreationButtons] = useState({
    show: false,
    pressed: false,
  })
  const CurrentProcess = getProcess(process.name)

  const [fetchEvictionProcessData, { data: evictionProcessData, loading: isLoadingEvictionProcess }] =
    useLazyQuery(EVICTION_PROCESS, {
      variables: {
        processId: process.id,
      },
      onError: (error) => {
        notifyFailure(error.message)
      },
    })

  const { evictionProcess } = evictionProcessData || {}

  const isProcessReadyForPdfGeneration =
    !process.closed &&
    [STATES.report_to_socialtjansten, STATES.report_to_kronofogden].includes(process.currentState)

  const shouldFetchProcessData = showManualPdfCreationButtons.show || isProcessReadyForPdfGeneration

  useEffect(() => {
    if (shouldFetchProcessData) {
      fetchEvictionProcessData()
    }
  }, [showManualPdfCreationButtons, process.currentState])

  const setProcessOpen = () => {
    dispatch(updateProcessLoading())
    Api.processesSetOpen(process.id)
      .then((res) => {
        dispatch(updateProcessSuccess(res.data.users))
      })
      .catch((e) => {
        dispatch(updateProcessFailed(e))
        displayApiErrors(e)
      })
  }

  const setProcessClosed = () => {
    dispatch(updateProcessLoading())
    Api.processesSetClosed(process.id)
      .then((res) => {
        dispatch(updateProcessSuccess(res.data.users))
      })
      .catch((e) => {
        dispatch(updateProcessFailed(e))
        displayApiErrors(e)
      })
  }

  const buttonText = process.closed ? 'Reopen' : 'Close'
  const clickhandler = process.closed ? setProcessOpen : setProcessClosed

  return (
    <Wrapper gap="4x">
      <ProcessHeader direction="row" justifyContent="space-between" gap="1x">
        <Heading size="2xs">{CurrentProcess.name}</Heading>
        <button onClick={() => setIsStepHistoryVisible(!isStepHistoryVisible)}>
          <StepHistoryContainer size="sm">
            {process.closed ? (
              <>Closed</>
            ) : (
              <>
                Current step: {CurrentProcess.getStateName(process.currentState)}
                {process.stateHistory && <> ({process.stateHistory.size})</>}
              </>
            )}
            {isStepHistoryVisible && (
              <StepHistoryPopupContainer>
                <StepHistory stateHistory={process.stateHistory} CurrentProcess={CurrentProcess} />
              </StepHistoryPopupContainer>
            )}
          </StepHistoryContainer>
        </button>
      </ProcessHeader>
      <Content direction={contentLayoutDirection} gap="8x">
        <ProcessContent gap="2x">
          {process.closed ? (
            <Paragraph size={'sm'}>
              Last step: {CurrentProcess.getStateName(process.currentState)}
              {/* TODO: Add button here (variant ghost) to see comments */}
            </Paragraph>
          ) : (
            <CurrentProcess.Task process={process} evictionProcess={evictionProcess} />
          )}
          <Stack gap="6x" direction="row" wrap="wrap">
            <Stack direction="row" wrap="wrap" gap="2x">
              {!process.closed && (
                <>
                  {process.allowedTransitions.map((toState: EvictionProcessState) => (
                    <CurrentProcess.TransitionButton
                      key={toState}
                      toState={toState}
                      processId={process.id}
                      dispatch={dispatch}
                      isButtonDisabled={Boolean(updateProcess?.isLoading)}
                    />
                  ))}
                </>
              )}
              <Button onClick={() => clickhandler()} variant="tertiary" size="xs">
                {buttonText}
              </Button>
              <Button
                onClick={() =>
                  setShowManualPdfCreationButtons({
                    show: true,
                    pressed: true,
                  })
                }
                isLoading={isLoadingEvictionProcess && showManualPdfCreationButtons.pressed}
                variant="tertiary"
                size="xs"
              >
                {'Show manual pdf actions'}
              </Button>
            </Stack>
            <Stack>
              {showManualPdfCreationButtons.show && !isLoadingEvictionProcess && (
                <Stack gap="2x">
                  <Label size="sm">{"Generate PDF's manually"}</Label>
                  <GenerateSocPdfButton evictionProcess={evictionProcess} />
                  <GenerateKfmPdfButton evictionProcess={evictionProcess} />
                </Stack>
              )}
            </Stack>
          </Stack>
        </ProcessContent>
        <CommentsSection
          processComponent={CurrentProcess}
          processId={process.id}
          comments={process.comments}
          dispatch={dispatch}
          isAddCommentLoading={addComment.isLoading}
          addCommentError={addComment.error}
        />
      </Content>
    </Wrapper>
  )
}

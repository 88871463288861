import styled from '@emotion/styled'
import { useState } from 'react'
import { Stack } from '@qasa/qds-ui'

import { Process } from '../process'

import { Header } from './header'
import { Data } from './data'

const Wrapper = Stack
const Content = 'div'

const ProcessList = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing['4x'],
  marginBottom: theme.spacing['8x'],
  paddingLeft: theme.spacing['6x'],
  paddingRight: theme.spacing['6x'],
  gap: 128,
  rowGap: theme.spacing['16x'],
  flexWrap: 'wrap',
}))

// TODO: fix this type below for invoice
type InvoiceProps = {
  invoice: any
  user: any
  dispatch: any
  addComment: {
    isLoading: boolean
    error: any
  }
  updateProcess: {
    isLoading: boolean
    error: any
  }
}

export function Invoice({ invoice, user, dispatch, addComment, updateProcess }: InvoiceProps) {
  const [isExpanded, setIsExpanded] = useState(!invoice.archived)

  return (
    <Wrapper gap="1x">
      <Header invoice={invoice} user={user} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      {isExpanded && (
        <Content>
          <Data invoice={invoice} />
          <ProcessList direction="row" wrap="wrap">
            {invoice.processes.map((process: any) => (
              <Process
                key={process.id}
                process={process}
                invoice={invoice}
                dispatch={dispatch}
                addComment={addComment}
                updateProcess={updateProcess}
                userUid={user.uid}
              />
            ))}
          </ProcessList>
        </Content>
      )}
    </Wrapper>
  )
}

import { Button, Paragraph, Stack } from '@qasa/qds-ui'

type PaginationButtonsProps = {
  handleFetchLess: () => void
  handleFetchMore: () => void
  canFetchLess: boolean
  canFetchMore: boolean
  isLoadingLess: boolean
  isLoadingMore: boolean
  metadata: {
    currentPage: number
    totalPages: number
    totalHits: number
  }
}
export function PaginationButtons({
  handleFetchLess,
  handleFetchMore,
  canFetchLess,
  canFetchMore,
  isLoadingLess,
  isLoadingMore,
  metadata,
}: PaginationButtonsProps) {
  const { currentPage, totalPages, totalHits } = metadata
  return (
    <Stack alignItems="center" gap="4x" direction="row">
      <Stack direction="row" gap="2x">
        <Button size="sm" isLoading={isLoadingLess} isDisabled={!canFetchLess} onClick={handleFetchLess}>
          {'Previous'}
        </Button>
        <Button size="sm" isLoading={isLoadingMore} isDisabled={!canFetchMore} onClick={handleFetchMore}>
          {'Next'}
        </Button>
      </Stack>
      <Paragraph size="lg">{`${currentPage} / ${totalPages}`}</Paragraph>
      <Paragraph size="lg">{`Total homes: ${totalHits}`}</Paragraph>
    </Stack>
  )
}

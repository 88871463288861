import styled from '@emotion/styled'
import { InputBase, Select, Stack } from '@qasa/qds-ui'

import { SearchOptions, useAuthorisationContext } from '../../context/authorisation-context'

const InboxSearchWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing['3x'],
  paddingBottom: theme.spacing['1x'],
  backgroundColor: theme.colors.core.offWhite,
  width: '100%',
  zIndex: 1,
}))

const searchTypeOptions = [
  {
    value: SearchOptions.EmployeeName,
    label: 'employee name',
  },
  {
    value: SearchOptions.GroupName,
    label: 'group name',
  },
  {
    value: SearchOptions.Role,
    label: 'role',
  },
]

export function SearchBar() {
  const { groups, searchOption, setSearchOption, roles } = useAuthorisationContext()

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchOption((prev) => ({ ...prev, value: e.target.value }))

  const shouldShowSearchBar = [SearchOptions.EmployeeEmail, SearchOptions.EmployeeName].includes(
    searchOption.type,
  )

  const shouldShowRoleSelect = searchOption.type === SearchOptions.Role
  const shouldShowGroupSelect = searchOption.type === SearchOptions.GroupName

  const searchOptionLabel = searchTypeOptions.find((option) => option.value === searchOption.type)?.label

  return (
    <InboxSearchWrapper gap="2x">
      <Select
        label="Search options"
        value={searchOption.type}
        onChange={(e) =>
          setSearchOption((prev) => ({ ...prev, type: e.target.value as SearchOptions, value: '' }))
        }
      >
        {searchTypeOptions.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label.charAt(0).toUpperCase() + String(label).slice(1)}
          </Select.Option>
        ))}
      </Select>
      {shouldShowRoleSelect && (
        <Select
          // compensates for the label that still exists when set to ""
          style={{ marginTop: -8 }}
          label=""
          placeholder="Choose a role..."
          value={searchOption.value}
          onChange={(e) => setSearchOption((prev) => ({ ...prev, value: e.target.value }))}
        >
          {roles.map(({ name, id }) => (
            <Select.Option key={id} value={id}>
              {name.charAt(0).toUpperCase() + String(name).slice(1)}
            </Select.Option>
          ))}
        </Select>
      )}
      {shouldShowGroupSelect && (
        <Select
          // compensates for the label that still exists when set to ""
          style={{ marginTop: -8 }}
          label=""
          placeholder="Choose a group..."
          value={searchOption.value}
          onChange={(e) => setSearchOption((prev) => ({ ...prev, value: e.target.value }))}
        >
          {groups
            .sort((a, b) => (a.employees.nodes.length > b.employees.nodes.length ? -1 : 0))
            .map(({ name, id, employees }) => (
              <Select.Option key={id} value={id} style={{ wordBreak: 'break-word' }}>
                {`${name} - 👤 ${employees.nodes.length}`}
              </Select.Option>
            ))}
        </Select>
      )}
      {shouldShowSearchBar && (
        <InputBase
          type="search"
          value={searchOption.value}
          placeholder={`Search ${searchOptionLabel}`}
          onChange={onChangeText}
        />
      )}
    </InboxSearchWrapper>
  )
}

import { Button } from '@qasa/qds-ui'

import { useCreditInvoice } from '../../../queries/invoice-queries'

export function CreditInvoiceButton({ id }: { id: string }) {
  const { mutate: creditInvoice, isLoading, isSuccess } = useCreditInvoice()

  return (
    <Button
      variant="tertiary"
      size="sm"
      isLoading={isLoading}
      isDisabled={isSuccess}
      onClick={() => creditInvoice({ id })}
    >
      Credit invoice
    </Button>
  )
}

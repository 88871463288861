import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import styled from '@emotion/styled'
import { Checkbox, Stack } from '@qasa/qds-ui'

const REACT_TABLE_DEFAULT_COL_WIDTH = 150

const TableHeading = styled('th')<{ isFirst: boolean }>(({ theme, isFirst }) => {
  // margin left of checkbox + checkbox width + checkbox gap
  const checkboxWidth = `calc(${theme.spacing['4x']} + 20px + ${theme.spacing['3x']})`
  return {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    paddingBlock: theme.spacing['4x'],
    paddingInline: theme.spacing['4x'],
    paddingLeft: isFirst ? checkboxWidth : theme.spacing['2x'],
    backgroundColor: theme.colors.core.gray20,
  }
})
const TableRow = styled('tr')<{ isSelected?: boolean; isHoverEnabled?: boolean }>(
  ({ isSelected, isHoverEnabled = true, theme }) => ({
    borderBottom: `1px solid ${theme.colors.core.gray20}`,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: isSelected ? theme.colors.core.gray10 : theme.colors.core.white,
    ...(isHoverEnabled
      ? {
          '&:hover': {
            backgroundColor: theme.colors.core.gray10,
            cursor: 'pointer',
          },
        }
      : {}),
  }),
)
const TableDataWrapper = styled('div')<{ isAddress: boolean }>(({ theme, isAddress }) => ({
  ...(isAddress ? theme.typography.body.xs : theme.typography.body.sm),
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
}))
const TableData = styled('td')<{ isEmail: boolean }>(({ theme, isEmail }) => ({
  paddingBlock: theme.spacing['3x'],
  paddingLeft: theme.spacing['2x'],
  wordBreak: isEmail ? 'break-all' : 'break-word',
}))
const CheckboxWrapper = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing['4x'],
  // note: better to disable pointer events and let the click bubble through to the row instead, better UX
  pointerEvents: 'none',
}))

const columnHelper = createColumnHelper<any>()

type NewTableProps = {
  rowsData: any[]
  columns: { accessorKey: string; render: () => string; size?: number }[]
  selectedRows: number[]
  toggleRowSelection: (rowIndex: number) => void
}
export function NewTable({ rowsData, columns, selectedRows, toggleRowSelection }: NewTableProps) {
  const table = useReactTable({
    data: rowsData,

    columns: columns.map(({ accessorKey, render, size }) =>
      columnHelper.accessor(accessorKey, {
        id: accessorKey,
        header: render,
        cell: (info) => info.getValue(),
        size,
      }),
    ),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
  })

  const tableRows = table.getRowModel().rows

  return (
    <table>
      <TableRow isHoverEnabled={false}>
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map((header, index) => {
            const isFirst = index === 0
            const hasCustomWidth = header.column.getSize() !== REACT_TABLE_DEFAULT_COL_WIDTH
            return (
              <TableHeading
                key={header.id}
                isFirst={isFirst}
                style={{
                  maxWidth: hasCustomWidth ? header.column.getSize() : 'auto',
                  textWrap: 'nowrap',
                }}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableHeading>
            )
          }),
        )}
      </TableRow>
      <tbody>
        {tableRows.map((row, index) => {
          const isSelected = selectedRows.includes(index)
          return (
            <TableRow onClick={() => toggleRowSelection(index)} key={row.id} isSelected={isSelected}>
              {row.getVisibleCells().map((cell, index) => {
                const content = flexRender(cell.column.columnDef.cell, cell.getContext())

                const hasCustomWidth = cell.column.getSize() !== REACT_TABLE_DEFAULT_COL_WIDTH

                return (
                  <TableDataWrapper
                    key={cell.id}
                    isAddress={cell.column.id === 'address'}
                    style={{ maxWidth: hasCustomWidth ? cell.column.getSize() : 'auto' }}
                  >
                    {index === 0 ? (
                      <CheckboxWrapper>
                        <Checkbox label={content as string} isChecked={isSelected} />
                      </CheckboxWrapper>
                    ) : (
                      <TableData isEmail={typeof content === 'string' && content?.includes('@')}>
                        {content}
                      </TableData>
                    )}
                  </TableDataWrapper>
                )
              })}
            </TableRow>
          )
        })}
      </tbody>
    </table>
  )
}

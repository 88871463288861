import { ArrayBuffer } from 'spark-md5'
import { useMutation } from '@apollo/client'
import { useState, type ChangeEvent } from 'react'
import axios from 'axios'

import { notifyFailure } from '../../utils'
import { ActiveStorageServiceEnum } from '../../graphql/__generated__/graphql'

import { CREATE_BLOB, CREATE_PUBLIC_DOCUMENT } from './public-documents-upload.gql'

const generateChecksum = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer()
  const sparkBinaryDigest = ArrayBuffer.hash(arrayBuffer, true)
  const checksum = window.btoa(sparkBinaryDigest)
  return checksum
}

export const useDocumentUpload = () => {
  const [chosenFile, setChosenFile] = useState<null | File>(null)

  const removeChosenFile = () => setChosenFile(null)

  const [createBlob, { error: createBlobError, loading: isCreateBlobLoading }] = useMutation(CREATE_BLOB)
  const [createPublicDoc, { loading: isUploadingPublicDoc }] = useMutation(CREATE_PUBLIC_DOCUMENT, {
    onCompleted: removeChosenFile,
    refetchQueries: ['PublicDocuments'],
  })

  const browseFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = e?.target?.files
    const chosenFile = uploadedFiles?.length && uploadedFiles[0]

    if (chosenFile) {
      const { type } = chosenFile

      // TODO: check this with BE if this is still true
      if (!type.includes('pdf')) {
        notifyFailure('Only PDF files are valid for upload')
        return
      }

      setChosenFile(chosenFile)
    }
  }

  const uploadFile = async ({ alteredFileName }: { alteredFileName: string }) => {
    if (chosenFile) {
      const { size, type, name } = chosenFile

      if (!type.includes('pdf')) {
        notifyFailure('Only PDF files are valid for upload')
        return
      }

      const checksum = await generateChecksum(chosenFile)

      const filename = alteredFileName || name

      const keyDate = new Date()
        .toISOString()
        .replace(/\.\d+Z/g, '')
        .replace(/[:-]/g, '')
      const keyFilename = filename.replace('.pdf', '')
      const key = `${keyFilename}-${keyDate}.pdf`

      const { data } = await createBlob({
        variables: {
          input: {
            key,
            serviceName: ActiveStorageServiceEnum.Public,
            byteSize: size,
            checksum,
            contentType: type,
            filename,
          },
        },
      })

      const createdBlob = data?.createBlob

      if (createdBlob) {
        const { id, signedUrl, headers } = createdBlob

        await axios.put(signedUrl, chosenFile, {
          headers,
          withCredentials: false,
        })

        createPublicDoc({ variables: { input: { file: id, name: filename } } })

        return { id }
      } else if (createBlobError) {
        notifyFailure('An error occurred while uploading the file')
      }

      return { id: null }
    }
  }

  return {
    isUploadingPublicDoc,
    isCreateBlobLoading,
    uploadFile,
    browseFiles,
    chosenFile,
    removeChosenFile,
  }
}

import { useState } from 'react'
import styled from '@emotion/styled'
import { LoadingDots, Paragraph, Stack, Heading } from '@qasa/qds-ui'
import { StringParam, useQueryParams } from 'use-query-params'
import { useQuery } from '@apollo/client'

import { SearchBar } from '../../../components/search-bar'
import { UserSelectTable } from '../user-select-table'
import { UserProvider } from '../user-context'
import { LocalizedUserContent } from '../localized-user-content'

import { SEARCH_FOR_USER } from './search-for-user.gql'

const ListItem = 'li'
const StyledSpan = styled.span(({ theme }) => ({
  ...theme.typography.body.sm,
  paddingLeft: theme.spacing['1x'],
  color: theme.colors.text.subtle,
}))

//TODO: This should be done in BE
const formatSearchInput = (searchString?: string) => {
  switch (true) {
    case searchString?.includes('@'):
      return { email: searchString?.toLowerCase() }
    case searchString?.startsWith('+'):
      return { phoneNumber: searchString?.replace(/\s+/g, '') }
    case searchString?.startsWith('s-'):
      return { schibstedAccountId: searchString?.substring(2) }
    default:
      return { uid: searchString }
  }
}

export function SearchForUser() {
  const [{ user }, setQueryParams] = useQueryParams({ user: StringParam })
  const userQueryParam = user ?? ''
  const [searchValue, setSearchValue] = useState(userQueryParam)
  const [currentUid, setCurrentUid] = useState<string | null>(null)
  const {
    loading: isLoading,
    error,
    data: searchedData,
    called: hasSearchForUserBeenCalled,
  } = useQuery(SEARCH_FOR_USER, {
    skip: !userQueryParam,
    variables: { input: formatSearchInput(userQueryParam) },
    onCompleted: (data) => {
      if (data?.users.nodes.length === 1) {
        // Only one user found, show directly.
        setCurrentUid(data.users.nodes[0].uid)
      } else {
        // Multiple or no users found
        setCurrentUid(null)
      }
    },
  })

  const users = searchedData?.users.nodes ?? []
  const userHasSeveralAccounts = users.length > 1

  const onSubmit = () => {
    if (userHasSeveralAccounts) {
      setCurrentUid(null)
    }
    setQueryParams({ user: searchValue })
  }

  const getContent = () => {
    switch (true) {
      case isLoading:
        return <LoadingDots />
      case Boolean(error) || users.length === 0:
        return (
          <div>
            <Paragraph>
              {'User could not be found, ensure you entered one of the following correctly and try again:'}
            </Paragraph>
            <ListItem>{'UID'}</ListItem>
            <ListItem>{'Email'}</ListItem>
            <ListItem>
              {'Phone number'}
              <StyledSpan>{'must be entered with country code, e.g. +46/+365'}</StyledSpan>
            </ListItem>
            <ListItem>
              {'Schibsted Account Id'}
              <StyledSpan>{'must be entered with prefix s-'}</StyledSpan>
            </ListItem>
          </div>
        )
      case Boolean(currentUid):
        return (
          <UserProvider uid={currentUid!}>
            <LocalizedUserContent />
          </UserProvider>
        )
      case userHasSeveralAccounts:
        return (
          <UserSelectTable
            users={users}
            onUserSelect={(user) => {
              setCurrentUid(user.uid)
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <Stack gap="2x">
      <Heading>{'User search'}</Heading>
      <Stack gap="5x">
        <SearchBar
          value={searchValue}
          onChange={(e) => setSearchValue(e)}
          submit={onSubmit}
          placeholder="john@doe.com"
          errorMessage={error?.message}
        />
        {hasSearchForUserBeenCalled && getContent()}
      </Stack>
    </Stack>
  )
}

import { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Heading, Stack, ChevronDownIcon, ChevronUpIcon } from '@qasa/qds-ui'

import { useUserPermanentDelete, useSendResetLinkToEmail } from '../../../queries/user-queries'
import { OnfidoIdentificationDialog } from '../identification/onfido-identification-dialog'
import { LeaseTakeoverDialog } from '../lease-takeover/lease-takeover-dialog'
import { useUserContext } from '../user-context'
import { inactivateUser, reactivateUser } from '../user-utils'

const Wrapper = Stack
const GroupWrapper = styled(Stack)(({ theme }) => ({ padding: theme.spacing['4x'] }))
const GroupHeader = styled.button(({ theme }) => ({
  background: theme.colors.core.gray20,
  padding: theme.spacing['3x'],
  borderRadius: theme.radii.md,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

type GroupProps = {
  title: string
  children: React.ReactNode
}
function Group({ title, children }: GroupProps) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <GroupHeader onClick={() => setIsExpanded(!isExpanded)}>
        <Heading size="xs">{title}</Heading>
        {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </GroupHeader>
      {isExpanded && <GroupWrapper gap="4x">{children}</GroupWrapper>}
    </>
  )
}

type AccountActivationProps = boolean | null

export function UserCTAsFrance() {
  const { user } = useUserContext()
  const uid = user.uid
  const email = user.private.email
  const { mutate: permanentDelete, isLoading: isPermanentDeleteLoading } = useUserPermanentDelete(uid)
  const { mutate: sendResetLinkToUserEmail, isLoading: isSendResetPasswordLoading } =
    useSendResetLinkToEmail()

  const confirmAction = () => window.confirm('Are you sure?')

  const handleInactivate = (suspended: AccountActivationProps = null) => {
    if (confirmAction()) {
      inactivateUser(uid, suspended)
    }
  }

  const handleReactivate = (suspended: AccountActivationProps = null) => {
    reactivateUser(uid, suspended)
  }

  const handlePermanentDelete = () => {
    if (confirmAction()) {
      permanentDelete()
    }
  }

  const handleSendResetPasswordEmail = () =>
    sendResetLinkToUserEmail({
      email,
      platform: user.mainPlatform,
    })

  return (
    <Wrapper gap="4x">
      <Group title="User">
        <OnfidoIdentificationDialog uid={uid} />
        <LeaseTakeoverDialog user={user} />
      </Group>
      <Group title="Account access">
        <Button variant="tertiary" onClick={() => handleInactivate()}>
          Inactivate
        </Button>
        <Button variant="tertiary" onClick={() => handleInactivate(true)}>
          Inactivate & suspend
        </Button>
        <Button variant="tertiary" onClick={() => handleReactivate()}>
          Reactivate
        </Button>
        <Button variant="tertiary" onClick={() => handleReactivate(false)}>
          Reactivate & unsuspend
        </Button>
      </Group>
      <Group title="Account">
        <Button
          variant="tertiary"
          onClick={handleSendResetPasswordEmail}
          isLoading={isSendResetPasswordLoading}
        >
          Send reset password email
        </Button>
      </Group>
      <Group title="Other">
        <Button variant="tertiary" onClick={handlePermanentDelete} isLoading={isPermanentDeleteLoading}>
          Permanent delete account
        </Button>
      </Group>
    </Wrapper>
  )
}

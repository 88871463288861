import axios from 'axios'
import { config } from 'src/config'

axios.defaults.withCredentials = true

class ApiClass {
  init(accessToken) {
    this.authHeaders = { 'access-token': accessToken }
  }

  userReactivate = (uid, suspended) => {
    let data = {}
    if (suspended !== null) data = { suspended }
    return axios.post(`${config.api}/v1/admin/users/${uid}/reactivate`, data)
  }

  userDelete = (uid, suspended) => {
    let data = {}
    if (suspended !== null) data = { suspended }
    return axios.delete(`${config.api}/v1/users/${uid}`, {
      data,
    })
  }

  contractSendInvoice = (id) => axios.post(`${config.api}/v1/admin/invoices/${id}/send`)

  reconfirmDeniedBooking = (payload) =>
    axios.put(`${config.api}/v1/admin/contracts/${payload.id}/reconfirm_denied_booking`)

  contractMarkAsCredited = (id) => axios.put(`${config.api}/v1/admin/invoices/${id}/mark_as_credited`)

  contractResetInvoice = (id) => axios.put(`${config.api}/v1/admin/invoices/${id}/reset`)

  contractDestroyInvoice = (id) => axios.delete(`${config.api}/v1/admin/invoices/${id}`)

  contractUpdateInvoiceEntries = (id, payload) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/update_entries`, payload)

  getRiskClassifications = (uid) =>
    axios.get(`${config.api}/v1/admin/risk_classifications`, {
      params: {
        uid,
      },
    })

  getAmlHits = (uid, type) =>
    axios.get(`${config.api}/v1/admin/risk_classifications/aml_hits`, {
      params: {
        uid,
        type,
      },
    })

  createSearchArea = (data) => axios.post(`${config.api}/v1/admin/search/areas`, data)

  getSearchArea = (identifier) =>
    axios.get(`${config.api}/v1/admin/search/areas`, {
      params: {
        identifier,
      },
    })

  createSearchAreaKeyword = (data) => axios.post(`${config.api}/v1/admin/search/areas/keyword`, data)

  deleteSearchAreaKeyword = (id) =>
    axios.delete(`${config.api}/v1/admin/search/areas/keyword`, {
      params: {
        id,
      },
    })

  updateSearchAreaKeyword = (id, keyword) =>
    axios.put(`${config.api}/v1/admin/search/areas/keyword`, { id, keyword })

  updateSearchAreaGeojson = (identifier, geojson) => {
    const payload = { geojson }
    return axios.put(`${config.api}/v1/admin/search/areas`, payload, {
      params: {
        identifier,
      },
    })
  }

  getSubUsersByUser = (uid) =>
    axios.get(`${config.api}/v1/admin/sub_users/show_by_user`, {
      params: {
        uid,
      },
    })

  cancelSelfInvoice = (id) => axios.put(`${config.api}/v1/admin/invoices/${id}/cancel_selfinvoice`)

  checkInvoicePayment = (id) => axios.put(`${config.api}/v1/admin/invoices/${id}/check_payment`)

  invoiceRemoveCreditedAmount = (id) =>
    axios.put(`${config.api}/v1/admin/invoices/${id}/remove_credited_amount`)

  invoiceEnableReminders = (id) => axios.put(`${config.api}/v1/admin/invoices/${id}/enable_reminders`)

  invoiceDisableReminders = (id) => axios.put(`${config.api}/v1/admin/invoices/${id}/disable_reminders`)

  postHomeTemplates = (templateObject) =>
    axios.post(`${config.api}/v1/admin/home_templates_multiple`, { ...templateObject })

  processesGet = (states, includeClosed, page, processesPerPage) =>
    axios.get(`${config.api}/v1/admin/overdue_payments`, {
      params: { states, includeClosed, page, processesPerPage },
    })

  processesTransitionTo = (id, state) =>
    axios.post(`${config.api}/v1/admin/overdue_payments/transition_to`, { id, state })

  processesSetClosed = (id) => axios.post(`${config.api}/v1/admin/overdue_payments/close`, { id })

  processesSetOpen = (id) => axios.post(`${config.api}/v1/admin/overdue_payments/open`, { id })

  processesAddComment = (id, body) =>
    axios.post(`${config.api}/v1/admin/overdue_payments/add_comment`, { id, body })

  processesSearch = (searchString) =>
    axios.post(`${config.api}/v1/admin/overdue_payments/search`, { searchString })
}

export const Api = new ApiClass()

import { graphql } from '../../../graphql/__generated__'

export const EVICTION_PROCESS = graphql(/* GraphQL */ `
  query EvictionProcess($processId: ID!) {
    evictionProcess(processId: $processId) {
      ... on NotFoundError {
        message
      }
      ... on ValidationError {
        attributeErrors {
          attribute
          message
          type
        }
        message
      }
      ... on AuthorizationError {
        message
        permissionAction
        permissionObjectType
      }
      ... on EvictionProcess {
        id
        landlord {
          idNumber
          firstName
          private {
            familyName
            email
            phoneNumber
            activeBankAccount {
              location {
                route
                streetNumber
                postalCode
                locality
              }
            }
          }
        }
        user {
          idNumber
          firstName
          private {
            familyName
            email
            phoneNumber
          }
        }
        socInfo {
          nextMonth
          qasaInfo {
            adress
            bankgiro
            firma
            orgnummer
            ort
            postnummer
          }
        }
        messageToSocPdf {
          id
          filename
          url
        }
        currentState
        contract {
          id
          currency
          home {
            type
            location {
              formattedAddress
              postalCode
              route
              streetNumber
              locality
            }
            roomCount
            squareMeters
          }
        }
        invoice {
          dueAt
          amount
          id
        }
      }
    }
  }
`)

export const UPDATE_EVICTION_PROCESS = graphql(/* GraphQL */ `
  mutation UpdateEvictionProcess(
    $attributes: EvictionProcessAttributes!
    $processId: ID!
    $regeneratePdf: Boolean
  ) {
    updateEvictionProcess(attributes: $attributes, processId: $processId, regeneratePdf: $regeneratePdf) {
      ... on EvictionProcess {
        id
        messageToSocPdf {
          filename
        }
      }
      ...AuthorizationErrorFragment
      ...ValidationErrorFragment
      ...NotFoundErrorFragment
    }
  }
`)

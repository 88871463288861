import { useEffect, useState } from 'react'
import { Stack, Paragraph, Button, Heading, LoadingDots, Label } from '@qasa/qds-ui'
import { useMutation, useQuery } from '@apollo/client'

import { Dialog } from '../../components/_core/dialog'
import { notifyFailure, notifySuccess } from '../../utils'
import { useAuthorisationContext, type Group } from '../../context/authorisation-context'

import { GroupContainer } from './create-group-dialog'
import { EMPLOYEES, GROUPS, UPDATE_EMPLOYEE } from './authorisation.gql'

export function AddGroupToEmployeeDialog() {
  const {
    employee,
    dialogMethods: {
      assignGroupToEmployeeDialog: { close: onClose, status: isOpen },
    },
  } = useAuthorisationContext()
  const currentEmployeeGroups = employee?.groups?.edges.map((group) => group.node.id) || []
  const currentEmployeeRoles = employee?.roles?.nodes.map((role) => role.id) || []
  const { data, loading: isLoadingGroups } = useQuery(GROUPS)
  const [groupList, setGroupList] = useState<Group[]>([])
  const [groupIdArray, setGroupIdArray] = useState<string[]>(currentEmployeeGroups)

  const employeeGroups = data?.employeeGroups

  const groups = employeeGroups?.__typename === 'EmployeeGroupConnection' ? employeeGroups.nodes : []
  useEffect(() => {
    if (data) {
      setGroupList(groups)
    }
  }, [data])

  // TODO: need to change the mutation, this one can only create a group
  const [updateEmployee, { loading: isUpdatingEmployee }] = useMutation(UPDATE_EMPLOYEE, {
    variables: {
      id: employee?.id || '',
      groupIds: groupIdArray,
      roleIds: currentEmployeeRoles,
    },
    onCompleted: () => {
      // TODO : error handling
      notifySuccess(`Groups updated for employee: ${employee?.name}`)
      onClose()
    },
    refetchQueries: [EMPLOYEES],
    onError: () => notifyFailure(`Error updating Groups for ${employee?.email}`),
  })

  const handleGroupSelection = (groupId: string) => {
    if (groupIdArray.includes(groupId)) {
      setGroupIdArray((prev) => prev.filter((group) => group !== groupId))
    } else {
      setGroupIdArray((prev) => [...prev, groupId])
    }
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header>
          <Heading size="sm">{'Add group to employee'}</Heading>
        </Dialog.Header>
        <Dialog.Body>
          <Stack gap="3x">
            <Label>{'Select group(s)'}</Label>
            <Stack gap="3x" wrap="wrap" direction="row" alignItems="center">
              {isLoadingGroups && <LoadingDots />}
              {groupList.map((role: any) => {
                const hasRole = groupIdArray?.includes(role.id)
                return (
                  <GroupContainer
                    key={role.id}
                    gap="3x"
                    hasRole={hasRole}
                    onClick={() => handleGroupSelection(role.id)}
                  >
                    <Paragraph color={hasRole ? 'onBrandSecondary' : 'onBrandTertiary'}>
                      {role.name}
                    </Paragraph>
                  </GroupContainer>
                )
              })}
            </Stack>
          </Stack>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="tertiary"
            isFullWidth
            isLoading={isUpdatingEmployee}
            disabled={isUpdatingEmployee}
            // TODO: need to change the mutation, this one can only create a group
            onClick={() => updateEmployee()}
          >
            {'Save'}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

import * as amplitude from '@amplitude/analytics-browser'

const API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY

let hasInitialized = false

const initializeAmplitude = () => {
  if (hasInitialized) {
    return
  }

  if (!hasInitialized && API_KEY) {
    amplitude
      .init(API_KEY, {
        autocapture: {
          sessions: true,
          pageViews: true,
          formInteractions: true,
          elementInteractions: true,
        },
        serverZone: 'EU',
        trackingOptions: {
          language: true,
          platform: true,
        },
      })
      .promise.then(() => {
        hasInitialized = true
      })
  }
}

type AmplitudeEvent = {
  button_click: {
    id: string
    text: string
    label: string
    buttonType: string
    pageUrl: string
  }
  '[Amplitude] Page Viewed': {
    page_path: string
    page_url: string
  }
}

const track = <TName extends keyof AmplitudeEvent>(name: TName, payload: AmplitudeEvent[TName]) => {
  amplitude.track(name, payload)
}

const identifyUser = ({ userUid }: { userUid: string }) => amplitude.setUserId(userUid)

export { amplitude, identifyUser, track, initializeAmplitude }

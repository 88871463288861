import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link, LoadingDots, Button, Stack, Paragraph, Heading } from '@qasa/qds-ui'
import { useQuery } from '@apollo/client'

import { Api } from '../../api'
import { date } from '../../utils/helpers'
import { notifySuccess, displayApiErrors } from '../../utils'
import { useAuthContext } from '../../context/auth-context'
import { NewTable } from '../../components/table/new-table'
import { ContentWrapper, VerifiedData } from '../homes-in-review/homes-in-review'

import { SuspiciousUserPreview } from './suspicious-user-preview'
import { SUSPICIOUS_USERS } from './suspicious-users.gql'

export function SuspiciousUsers() {
  const { authBody } = useAuthContext()
  const [suspiciousUsers, setSuspiciousUsers] = useState<any>([])
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [suspicionData, setSuspicionData] = useState<any>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [count, setCount] = useState(0)
  const adminPlatformsArray = authBody?.private?.adminPlatforms || []

  const resetSelection = () => {
    setSuspicionData(null)
    setSelectedUsers([])
    setSuspiciousUsers([])
  }

  const { refetch: retchSuspiciousUsers, loading: isLoading } = useQuery(SUSPICIOUS_USERS, {
    variables: {
      // TODO: make this work for new auth system kanyeCurrentUser - you'll need a convert a list of MarketNameTypeEnum[] to PlatformEnum[] - check user-homes-in-review for example
      platform: adminPlatformsArray,
    },
    onCompleted: (data) => {
      if (data?.suspiciousUsers?.__typename === 'KanyeUserConnection') {
        setSuspiciousUsers(data.suspiciousUsers.nodes ?? [])
        setCount(data?.suspiciousUsers.totalCount)
      }
    },
  })

  const onSelectionChanged = (selection: number[]) => {
    // note: reverse the order so that the last selected home is always shown first in the home preview pane
    const selectedUsersArray = selection
      .reverse()
      .map((selectedIndex: number) => suspiciousUsers[selectedIndex])

    setSelectedUsers(selectedUsersArray)

    const selectedUser = selection.length === 1 ? suspiciousUsers[0] : null

    setSuspicionData(selectedUser?.suspicions.nodes ?? [])
  }

  const handleSuspendUser = () => {
    Api.userDelete(selectedUsers[0].uid, true)
      .then(() => {
        notifySuccess('User inactivated and suspended')
        resetSelection()
        retchSuspiciousUsers()
      })
      .catch(displayApiErrors)
  }

  const toggleRowSelection = (rowIndex: number) => {
    const selection = selectedRows.includes(rowIndex)
      ? selectedRows.filter((index) => index !== rowIndex)
      : [...selectedRows, rowIndex]
    setSelectedRows(selection)
    onSelectionChanged(selection)
  }

  const columns = [
    {
      accessorKey: 'fullName',
      render: () => 'Name',
    },
    {
      accessorKey: 'email',
      render: () => 'Email',
    },
    {
      accessorKey: 'mainPlatform',
      render: () => 'Main platform',
      size: 130,
    },
    {
      accessorKey: 'role',
      render: () => 'Role',
      size: 130,
    },
    {
      accessorKey: 'verifiedAt',
      render: () => 'Verified ID',
    },
  ]

  const tableData = suspiciousUsers?.map((user: any) => {
    const companyName = user.companyName
    const email = user.private.email

    return {
      fullName: (
        <Link isExternal as={RouterLink} to={`/user?user=${encodeURIComponent(email)}`}>
          {user.professional && companyName
            ? '🏢 ' + companyName
            : `${user.firstName} ${user.private.familyName}`}
        </Link>
      ),
      email: (
        <VerifiedData size="sm" isVerified={Boolean(user.emailConfirmed)}>
          {email}
        </VerifiedData>
      ),
      mainPlatform: user.mainPlatform,
      role: Boolean(user.tenant) ? 'Tenant' : 'Landlord',
      verifiedAt: (
        <VerifiedData size="sm" isVerified={Boolean(user.identityConfirmedAt)}>
          {user.identityConfirmedAt ? `Verified at: ${date(user.identityConfirmedAt)}` : 'Not verified'}
        </VerifiedData>
      ),
    }
  })

  return (
    <>
      <ContentWrapper gap="4x">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Paragraph>Suspicious Users ({!isLoading ? count : '...loading'})</Paragraph>
          <Button
            variant="primary"
            disabled={selectedUsers.length === 0 || selectedUsers.length > 1}
            onClick={() => handleSuspendUser()}
          >
            Suspend user
          </Button>
        </Stack>
        {isLoading ? (
          <LoadingDots />
        ) : (
          <>
            {suspiciousUsers.length === 0 ? (
              <Heading size="xs">There are no suspicious users right now 🤷‍♀️</Heading>
            ) : (
              <NewTable
                rowsData={tableData}
                columns={columns}
                selectedRows={selectedRows}
                toggleRowSelection={toggleRowSelection}
              />
            )}
          </>
        )}
      </ContentWrapper>
      {suspicionData && (
        <SuspiciousUserPreview activeSuspicions={suspicionData} refetch={retchSuspiciousUsers} />
      )}
    </>
  )
}

import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import qasaLogo from 'src/assets/qasa.svg'
import { Button, Stack, Avatar, IconButton, ArrowRightIcon, ArrowLeftIcon, Paragraph } from '@qasa/qds-ui'

import { useLocalStorage } from '../../hooks/use-local-storage'
import { useAuthContext } from '../../context/auth-context'
import { navIcons } from '../icons'

const CollapseButtonWrapper = styled.div({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(50%, -50%)',
})
const CollapseButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.colors.border.default}`,
}))
const NavWrapper = styled(Stack)<{ isCollapsed: boolean }>(({ theme, isCollapsed }) => ({
  padding: theme.spacing['6x'],
  height: '100%',
  zIndex: theme.zIndices.docked,
  boxShadow: 'inset -5px 0px 24px 0px rgba(22,16,26,0.1)',
  width: theme.sizes[256],
  minWidth: theme.sizes[256],
  marginLeft: isCollapsed ? -236 : 0,
  transition: 'margin 0.3s',
  position: 'relative',
  backgroundColor: theme.colors.core.gray10,
}))
const LogoWrapper = styled(NavLink)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})
const LogoImage = styled.img({
  width: 120,
})
const NavContent = styled(Stack)({
  flex: 1,
})
const NavLinkContainer = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing['3x'],
}))
const NavBottomContainer = Stack
const Container = Stack

const getNavLinks = ({ hasAuthorisationAccess }: { hasAuthorisationAccess: boolean }) => [
  {
    to: '/user',
    label: 'Users',
    Icon: navIcons.user,
  },
  {
    to: '/homes-in-review',
    label: 'Homes in review',
    Icon: navIcons.homesInReview,
  },
  {
    to: '/search-areas',
    label: 'Search areas',
    Icon: navIcons.searchAreas,
  },
  {
    to: '/suspicious-users',
    label: 'Suspicious Users',
    Icon: navIcons.suspiciousUsers,
  },
  {
    to: '/shortcut',
    label: 'Shortcut',
    Icon: navIcons.shortcut,
  },
  {
    to: '/overdue-payments',
    label: 'Overdue payments',
    Icon: navIcons.overduePayments,
  },
  {
    to: '/document-upload',
    label: 'Document upload',
    Icon: navIcons.publicDocumentUpload,
  },
  ...(hasAuthorisationAccess
    ? [
        {
          to: '/authorisation',
          label: 'Authorisation',
          Icon: navIcons.authorisation,
        },
      ]
    : []),
]

export function Navbar({ hasAuthorisationAccess }: { hasAuthorisationAccess: boolean }) {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useLocalStorage('navbarCollapsed', false)
  const { logOut, currentEmployee, authBody } = useAuthContext()
  const profilePictureUrl = currentEmployee?.profilePictureUrl || authBody?.profilePicture?.url

  const firstName = currentEmployee?.name || authBody?.firstName

  const handleToggleNavbarCollapsed = () => setIsNavbarCollapsed((prevCollapsed) => !prevCollapsed)

  const navCollapseIcon = isNavbarCollapsed ? ArrowRightIcon : ArrowLeftIcon

  return (
    <NavWrapper gap="8x" direction="column" isCollapsed={Boolean(isNavbarCollapsed)}>
      <CollapseButtonWrapper>
        <CollapseButton
          label=""
          icon={navCollapseIcon}
          variant="ghost"
          size="xs"
          onClick={handleToggleNavbarCollapsed}
        />
      </CollapseButtonWrapper>
      <LogoWrapper to="/">
        <LogoImage src={qasaLogo} alt="Logo" />
      </LogoWrapper>
      <NavContent justifyContent="space-between" direction="column">
        <NavLinkContainer gap="6x" direction="column">
          {getNavLinks({ hasAuthorisationAccess }).map(({ to, label, Icon }, index) => (
            <NavLink activeStyle={{ fontWeight: 'bold' }} key={label} to={to}>
              <Stack key={index} direction="row" alignItems="center" gap="4x">
                <Icon />
                {label}
              </Stack>
            </NavLink>
          ))}
        </NavLinkContainer>
        <NavBottomContainer gap="4x" direction="column">
          <Container gap="4x" direction="row" alignItems="center">
            <Avatar size="sm" src={profilePictureUrl ?? ''} />
            {firstName && <Paragraph style={{ fontWeight: 'bold' }}>{firstName}</Paragraph>}
          </Container>
          <Button variant="tertiary" onClick={logOut} isFullWidth>
            {'Logout'}
          </Button>
        </NavBottomContainer>
      </NavContent>
    </NavWrapper>
  )
}
